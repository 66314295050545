import { Component, Input, OnInit } from "@angular/core";
import { FRIDAY_ID, Message } from "@models/messenger";
import { formatTime } from "@utility";

@Component({
  selector: "app-message-text",
  templateUrl: "./message-text.component.html",
  styleUrls: ["./message-text.component.scss"],
})
export class MessageTextComponent implements OnInit {
  @Input() message: Message;
  @Input() sourceId: string;
  @Input() previousSenderName: string;

  FRIDAY_ID = FRIDAY_ID;

  constructor() {}

  ngOnInit() {}

  get timestamp() {
    return formatTime(this.message.timestamp);
  }
}
