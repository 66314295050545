import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Channel, WhatsappInstanceState } from "@models/messenger";
import {
  ChannelSettingFridayWhatsapp,
  Client,
  WhatsappInstance,
} from "@models/user";
import { TranslateService } from "@ngx-translate/core";
import { animations, ConfirmationDialogComponent } from "@shared";
import { Subscription } from "rxjs";
import { ApiService } from "../../../../../services/api.service";
import { AuthService } from "../../../../../services/auth.service";
import { ClientService } from "../../../../../services/client.service";

@Component({
  selector: "app-friday",
  templateUrl: "./friday.component.html",
  styleUrls: ["./friday.component.scss"],
  animations: animations,
})
export class FridayComponent implements OnInit, OnDestroy {
  client: Client;
  clientSub: Subscription;
  loading = false;
  instances: WhatsappInstance[] = [];
  quota: number;
  adminView = false;
  isManager = false;

  constructor(
    private authService: AuthService,
    private apiService: ApiService,
    private clientService: ClientService,
    public dialog: MatDialog,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.adminView = this.authService.isSuperAdmin;
    this.isManager = this.authService.isManager;
    this.clientSub = this.authService.clientChanges.subscribe(
      async (client) => {
        if (!client) return;
        this.client = client;
        await this.initialize();
        this.instances = this.client.channels.fridayWhatsapp.instances;
        // console.log(this.instances);
        this.loading = this.instances.some(
          (i) => i.id == WhatsappInstanceState.QUEUE
        );
        this.quota =
          this.client.channels.fridayWhatsapp.quota - this.instances.length;
      }
    );
  }

  ngOnDestroy() {
    if (this.clientSub) this.clientSub.unsubscribe();
  }

  async initialize() {
    if (!this.client.channels.fridayWhatsapp) {
      this.client.channels.fridayWhatsapp = new ChannelSettingFridayWhatsapp();

      await this.clientService.updateClient({
        "channels.fridayWhatsapp": this.client.channels.fridayWhatsapp,
      });
    }
  }

  async startInstance() {
    if (
      this.client.channels.fridayWhatsapp.quota <= 0 ||
      this.quota <= 0 ||
      this.loading
    )
      return false;
    this.loading = true;
    await this.apiService.createWhatsappInstance();
    this.loading = false;
  }

  async closeInstance(instance: WhatsappInstance) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        messageHTML: `<small class="warning"><i class="material-icons">warning</i>${this.translateService.instant(
          "dialog.warning.cannot-undone"
        )}</small> <div>${this.translateService.instant(
          "dialog.warning.confirm-delete"
        )}<span class="highlight">${
          instance.number || instance.id
        }</span>? </div> `,
        destructive: true,
      },
    });

    dialogRef.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        this.instances = this.instances.filter((i) => i.id != instance.id);
        await this.apiService.closeWhatsappInstance(instance.id);
        if (
          this.instances.length == 0 &&
          (!this.client.channels.twilio ||
            this.client.channels.twilio.whatsappNumbers.length == 0)
        )
          await this.clientService.removeChannel(Channel.WhatsApp);
      }
    });
  }

  statusClass(state: WhatsappInstanceState) {
    return {
      success: state == WhatsappInstanceState.CONNECTED,
      warn: state == WhatsappInstanceState.PENDING,
      error:
        state == WhatsappInstanceState.ERROR ||
        state == WhatsappInstanceState.CLOSED,
    };
  }
}
