<div class="empty-placeholder" [class.cover]="cover">
  <div class="wrapper">
    <i class="material-icons" *ngIf="icon">{{ icon }}</i>
    <p *ngIf="message" translate>{{ message }}</p>
    <button class="text-button" *ngIf="button" (click)="click()">
      <i class="material-icons" *ngIf="btnIcon">{{ btnIcon }}</i>
      <span translate>{{ button }}</span>
    </button>
  </div>
</div>
