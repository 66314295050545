<div class="message__header">
  <ng-container
    *ngIf="
      !message.ref &&
      message.senderName &&
      message.senderName != previousSenderName
    "
  >
    {{ message.senderName }}
  </ng-container>
  <a *ngIf="message.ref" [href]="message.ref" target="_blank">
    <span>Commented on</span>
    <img src="/assets/img/{{ message.channel }}-logo.svg" alt="" />
  </a>
</div>
