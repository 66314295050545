import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-fb-page-selector",
  templateUrl: "./fb-page-selector.component.html",
  styleUrls: ["./fb-page-selector.component.scss"],
})
export class FbPageSelectorComponent {
  selectedPage: string;
  pages: any;

  constructor(
    public dialogRef: MatDialogRef<FbPageSelectorComponent>,
    private sanitization: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // console.log(data);
    this.pages = data;
    this.selectedPage = data[0].id;
  }

  profilePicture(page) {
    // console.log(page.picture);
    if (page.picture)
      return this.sanitization.bypassSecurityTrustStyle(
        `url('${page.picture}')`
      );
    return "none";
  }

  close() {
    this.dialogRef.close();
  }
}
