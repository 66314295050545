<div class="chatroom__input" *ngIf="!adminView">
  <ng-container *ngIf="allowReply && !disconnected">
    <div class="samples" *ngIf="uploads.length > 0 && !sending">
      <div
        class="samples__item"
        *ngFor="let attachment of uploads; let i = index"
      >
        <a class="name">{{ attachment.name }}</a>
        <a class="error" (click)="deleteFile(i)">
          <i class="material-icons">close</i>
        </a>
      </div>
    </div>

    <div class="input">
      <textarea
        #input
        [(ngModel)]="messageText"
        placeholder="{{ 'placeholder.type-messages' | translate }}"
        [disabled]="
          (conversation.whatsappProvider == WhatsappProvider.Friday
            ? false
            : conversation.messages.length == 0) || sending
        "
        (focus)="typingOn()"
        (focusout)="typingOff()"
        (keyup)="saveInput()"
        matInput
        cdkTextareaAutosize
        [cdkAutosizeMinRows]="1"
        [cdkAutosizeMaxRows]="8"
      ></textarea>
      <!-- (keyup.shift.enter)="sendMessage()" -->

      <div class="actions">
        <app-emoji-picker
          [disabled]="sending || uploading"
          [primary]="true"
          [icon]="true"
          (emoji)="insertEmoji($event)"
        >
        </app-emoji-picker>

        <app-loader-button
          icon="attach_file"
          [disabled]="sending || uploading"
          (click)="uploadFile()"
          [loading]="uploading"
          [primary]="true"
        >
        </app-loader-button>

        <app-loader-button
          icon="send"
          [disabled]="
            sending || uploading || (!messageText && uploads.length == 0)
          "
          (click)="sendMessage()"
          [loading]="sending"
          [primary]="true"
        >
        </app-loader-button>
      </div>
    </div>

    <div class="typing success" *ngIf="typingIds.length > 0">
      {{ typingUsers() }}
      <span *ngIf="typingIds.length == 1" translate>inbox.is-typing</span>
      <span *ngIf="typingIds.length > 1" translate>inbox.are-typing</span>
    </div>
  </ng-container>

  <ng-container *ngIf="!allowReply">
    <p>{{ "inbox.expire-" + conversation.channel | translate }}</p>
  </ng-container>

  <ng-container *ngIf="disconnected">
    <p class="row">
      <span>{{ "channel." + conversation.channel | translate }}</span>
      &nbsp;
      <span translate>inbox.disconnected</span>
      <a
        class="success"
        [routerLink]="['/channel', conversation.channel]"
        translate
        >inbox.reconnect-now</a
      >
    </p>
  </ng-container>
</div>

<input #upload type="file" (change)="onFileChange($event)" multiple />
