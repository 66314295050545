<div class="dialog column">
  <app-loader [size]="4" *ngIf="!pagePosts && !selectedPost"></app-loader>
  <ng-container *ngIf="(selected && selectedPost) || (pagePosts && pageIds)">
    <ng-container *ngIf="pageIds.length > 0">
      <div class="dialog__title">
        <span *ngIf="!selected" translate>facebook.select-post</span>
        <span *ngIf="selected" translate>facebook.post-keywords</span>
      </div>

      <div class="pages">
        <ng-container *ngIf="!selected && !reloading">
          <div *ngFor="let page of pages; let i = index">
            <ng-container *ngIf="pagePosts[page.id] as allPosts">
              <div class="header-row">
                <div class="header">
                  <img [src]="page.profilePic" />
                  <p>{{ page.name }}</p>
                </div>

                <button
                  class="icon-button success"
                  *ngIf="i == 0"
                  (click)="getPosts()"
                >
                  <i class="material-icons">refresh</i>
                </button>
              </div>

              <ng-container *ngIf="allPosts.scheduled as posts">
                <h3>
                  <i class="material-icons">hourglass_top</i>
                  <span translate>facebook.scheduled-posts</span>
                </h3>
                <div class="posts">
                  <div
                    class="post__item"
                    *ngFor="let post of posts"
                    (click)="selectPost(post.id)"
                    [class.selected]="selectedPostId == post.id"
                  >
                    <div
                      class="selected-indicator"
                      *ngIf="selectedPostId == post.id"
                    >
                      <i class="material-icons">check_circle</i>
                    </div>
                    <label>
                      <i class="material-icons">hourglass_top</i>
                      {{ scheduledTime(post.scheduled_publish_time) }}
                    </label>
                    <p>{{ post.message }}</p>
                    <img [src]="post.full_picture" *ngIf="post.full_picture" />
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="allPosts.published as posts">
                <h3>
                  <i class="material-icons">cloud_done</i>
                  <span translate>facebook.published-posts</span>
                </h3>
                <div class="posts">
                  <div
                    class="post__item"
                    *ngFor="let post of posts"
                    (click)="selectPost(post.id)"
                    [class.selected]="selectedPostId == post.id"
                  >
                    <div
                      class="selected-indicator"
                      *ngIf="selectedPostId == post.id"
                    >
                      <i class="material-icons">check_circle</i>
                    </div>
                    <label>
                      {{ createdTime(post.created_time) }}
                    </label>
                    <p>{{ post.message }}</p>
                    <img [src]="post.full_picture" *ngIf="post.full_picture" />
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>

        <app-loader [size]="4" *ngIf="!selected && reloading"></app-loader>

        <ng-container *ngIf="selected && selectedPost">
          <div class="header" *ngIf="selectedPage">
            <img [src]="selectedPage.profilePic" />
            <p>{{ selectedPage.name }}</p>
          </div>

          <div class="posts single">
            <a
              class="post__item"
              href="https://www.facebook.com/permalink.php?story_fbid={{
                selectedPostStoryId
              }}&id={{ selectedPageId }}"
              target="_blank"
            >
              <label>
                <i
                  class="material-icons"
                  *ngIf="selectedPost.scheduled_publish_time"
                  >hourglass_top</i
                >
                {{
                  selectedPost.scheduled_publish_time
                    ? scheduledTime(selectedPost.scheduled_publish_time)
                    : createdTime(selectedPost.created_time)
                }}
              </label>
              <p>{{ selectedPost.message }}</p>
              <img
                [src]="selectedPost.full_picture"
                *ngIf="selectedPost.full_picture"
              />
            </a>
          </div>

          <div class="form">
            <div class="form__row">
              <div class="form__label">
                <i class="material-icons">language</i>
                <span translate>general.language</span>
              </div>
              <div class="form__field">
                <button
                  class="text-button bg-primary"
                  mat-button
                  [matMenuTriggerFor]="languageMenu"
                >
                  {{ "general." + language | translate }}
                </button>
              </div>
            </div>

            <div class="form__row">
              <div class="form__label">
                <i class="material-icons">message</i>
                <span translate>facebook.comment</span>
              </div>
              <div class="form__field">
                <input
                  type="text"
                  [(ngModel)]="comment"
                  [placeholder]="'facebook.comment-placeholder' | translate"
                />
              </div>
            </div>

            <div class="form__row">
              <div class="form__label">
                <i class="material-icons">vpn_key</i>
                <span translate>general.keywords</span>
              </div>
              <div class="form__field">
                <input
                  type="text"
                  [(ngModel)]="keywordInput"
                  (keydown.enter)="addKeyword()"
                />
              </div>
              <div class="form__action">
                <button
                  class="icon-button success"
                  (click)="addKeyword()"
                  [disabled]="!keywordInput"
                >
                  <i class="material-icons">add</i>
                </button>
              </div>
            </div>
          </div>

          <div class="samples">
            <div class="samples__item" *ngFor="let keyword of keywords">
              {{ keyword }}
              <a class="error" (click)="removeKeyword(keyword)">
                <i class="material-icons">close</i>
              </a>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <app-empty
      *ngIf="pageIds.length == 0"
      [icon]="'article'"
      [message]="'facebook.empty-post'"
    ></app-empty>

    <div class="dialog__action row">
      <button class="text-button flat" mat-button (click)="close()" translate>
        general.cancel
      </button>

      <ng-container *ngIf="pageIds.length > 0">
        <button
          class="text-button bg-success"
          *ngIf="!selected"
          (click)="selected = true"
          [disabled]="!selectedPostId"
          translate
        >
          general.continue
        </button>
        <button
          class="text-button bg-success"
          *ngIf="selected"
          [disabled]="!selectedPostId || keywords.length == 0"
          (click)="submit()"
          translate
        >
          general.confirm
        </button>
      </ng-container>
    </div>
  </ng-container>
</div>

<mat-menu #languageMenu="matMenu" xPosition="after" yPosition="below">
  <button
    class="center language"
    *ngFor="let lang of languages"
    mat-menu-item
    (click)="language = lang"
  >
    {{ "general." + lang | translate }}
  </button>
</mat-menu>
