import { Component, OnInit } from "@angular/core";
import { Auth, useDeviceLanguage } from "@angular/fire/auth";
import { TranslateService } from "@ngx-translate/core";
import { SettingService } from "@shared";
import * as enJson from "../../../../translation/en.json";
import * as zhJson from "../../../../translation/zh.json";
import { environment } from "../environments/environment";
declare var FB: any;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  initialized = false;
  mobile = !!environment.mobile;
  en: any = enJson;
  zh: any = zhJson;

  constructor(
    auth: Auth,
    private settingService: SettingService,
    private translateService: TranslateService
  ) {
    useDeviceLanguage(auth);
  }

  ngOnInit() {
    if (this.mobile) {
      this.translateService.setTranslation("en", this.en);
      this.translateService.setTranslation("zh", this.zh);
    } else {
      FB.init({
        appId: environment.facebook.APP_ID,
        version: environment.graphAPI.API_VERSION,
        autoLogAppEvents: true,
        xfbml: true,
        cookie: true, // enable cookies
      });
      // console.log("Using FB:", environment.facebook.APP_ID);
    }

    this.settingService.initialize();
    this.initialized = true;
  }
}
