export * from "./error";
export * from "./http";
export * from "./language";
export * from "./regex";
export * from "./timezone";
export * from "./translation";

export const FRIDAY_ID = "friday";

export interface Json {
  [key: string]: any;
}
