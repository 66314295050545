import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Conversation } from "@models/messenger";
import { environment } from "../../../../../../environments/environment";
import { ConversationService } from "../../../../../services/conversation.service";

@Component({
  selector: "app-chatroom-header",
  templateUrl: "./chatroom-header.component.html",
  styleUrls: ["./chatroom-header.component.scss"],
})
export class ChatroomHeaderComponent implements OnInit {
  @Input() conversation: Conversation;
  @Output() archived = new EventEmitter();
  @Output() unarchived = new EventEmitter();
  mobile = !!environment.mobile;

  constructor(private conversationService: ConversationService) {}

  ngOnInit() {}

  get profilePicture() {
    return this.conversationService.formatCustomerPicture(
      this.conversation.id,
      this.conversation.customer.profilePic
    );
  }

  showChatlist() {
    return this.conversationService.showChatlist();
  }

  hideChatlist() {
    return this.conversationService.hideChatlist();
  }

  async archiveConversation() {
    const done = await this.conversationService.archive(this.conversation.id);
    if (done) {
      this.showChatlist();
      this.archived.emit(true);
    }
  }

  async unarchiveConversation() {
    const done = await this.conversationService.unarchive(this.conversation.id);
    if (done) {
      this.showChatlist();
      this.unarchived.emit(true);
    }
  }

  markRead() {
    return this.conversationService.markRead(this.conversation.id);
  }

  markUnread() {
    return this.conversationService.markUnread(this.conversation.id);
  }

  delete() {
    return this.conversationService.delete(
      this.conversation.id,
      this.conversation.customer.displayName
    );
  }
}
