import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { hashShort } from "@utility";
import { Subscription } from "rxjs";
import { environment } from "../../../../environments/environment";
import { ApiService } from "../../../services/api.service";
import { AuthService } from "../../../services/auth.service";

@Component({
  selector: "app-google-oauth",
  templateUrl: "./google-oauth.component.html",
  styleUrls: ["./google-oauth.component.scss"],
})
export class GoogleOauthComponent implements OnInit {
  authSub: Subscription;
  code: string;
  state: string;
  scopes: string[];

  constructor(
    private authService: AuthService,
    private apiService: ApiService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.code = params.code;
      this.state = params.state;
      this.scopes = params.scope.trim().split(" ");
    });

    this.authSub = this.authService.authUserChanges.subscribe(
      async (authUser) => {
        if (authUser) {
          if (
            this.state == hashShort(authUser.uid) &&
            environment.gapi.scopes.every((s) => this.scopes.includes(s))
          ) {
            console.log("Authorized", this.code);
            const succeed = await this.apiService.getGoogleOauthToken(
              this.code
            );
            window.close();
          }
        }
      }
    );
  }
}
