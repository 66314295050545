import { AdminPermission } from "./permission";
import { User, UserParams } from "./user";

export interface AdminParams extends UserParams {}

export class Admin extends User {
  constructor(params: AdminParams) {
    super(params);
    this.permission = params.permission || AdminPermission.Operator;
  }
}
