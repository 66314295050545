import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { sourceIdFromConversationId } from "@models/messenger";
import {
  Attachment,
  toAttachmentType,
  UPLOAD_SIZE_LIMIT,
} from "@models/messenger/attachment";
import { Client } from "@models/user";
import { NotificationService } from "@shared";
import {
  cleanFilename,
  hashShort,
  md5Hash,
  splitFilename,
  to,
  unique,
} from "@utility";
import { isEmpty } from "lodash";
import { firstValueFrom } from "rxjs";
import { ApiService } from "./api.service";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class StorageService {
  private client: Client;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private apiService: ApiService,
    private notificationService: NotificationService
  ) {
    this.authService.clientChanges.subscribe((client) => {
      this.client = client;
    });
  }

  validateUploads(files: File[]) {
    if (!files || isEmpty(files))
      return this.notificationService.error("error.upload.empty", false);
    // if (!free && this.client.quotas.storage <= 0)
    //   return this.notificationService.error("error.upload.storage-full", false);
    for (let file of files) {
      if (file.size > UPLOAD_SIZE_LIMIT)
        return this.notificationService.error("error.upload.size-limit", false);
    }
    return true;
  }

  async upload(file: File, location: string, rename = "", version = false) {
    if (!this.validateUploads([file])) return false;
    location = location.replace(/^\//, "").replace(/\/$/, "");

    const _filename = splitFilename(file.name);
    if (!_filename)
      return this.notificationService.error("error.file.unknown-type", null);
    const { name: originalName, ext } = _filename;
    let filename = cleanFilename(rename || originalName || "untitled");
    if (version)
      filename = filename + `_${String(new Date().valueOf()).slice(-4)}`;

    const path = `${hashShort(this.client.id)}/${location}/${filename}.${ext}`;
    // console.log(path);
    const hash = await md5Hash(file);

    const [urlErr, form] = await to(this.apiService.getUploadForm(path));
    if (urlErr || !form)
      return this.notificationService.error("error.upload.failed", null);
    const { url, fields, download } = form;
    // console.log(url, fields, download);

    const formData = new FormData();
    for (let field in fields) {
      formData.append(field, fields[field]);
    }
    formData.append("file", file);

    const [err] = await to(firstValueFrom(this.http.post(url, formData)));
    if (err) this.notificationService.error("error.upload.failed", null);

    console.log(`Uploaded to ${download}`);

    const attachment: Attachment = {
      id: hash,
      name: `${filename}.${ext}`,
      url: download,
      type: toAttachmentType(file.type),
      location: path,
      size: file.size,
    };
    console.log(attachment);
    return attachment;
  }

  async batchUpload(
    files: File[],
    location: string,
    version = false
  ): Promise<Attachment[]> {
    if (!this.validateUploads(files)) return null;
    let promiseArr: Promise<Attachment>[] = [];
    for (let file of files) {
      promiseArr.push(this.upload(file, location, null, version));
    }
    const [err, attachments] = await to(Promise.all(promiseArr));
    if (err) return this.notificationService.error("error.upload.failed", null);
    return unique(attachments);
  }

  toMessageAttachemntLocation(conversationId: string) {
    return `/c/${hashShort(sourceIdFromConversationId(conversationId))}`;
  }

  async uploadMessageAttachments(conversationId: string, files: File[]) {
    return this.batchUpload(
      files,
      this.toMessageAttachemntLocation(conversationId),
      true
    );
  }

  toKnowledgeAttachemntLocation(knowledgeId: string) {
    return `/k/${hashShort(knowledgeId)}`;
  }

  async uploadKnowledgeAttachments(
    knowledge: any,
    files: File[]
  ): Promise<Attachment[]> {
    return [];
    // let existingFiles = [];
    // for (let language of Object.keys(knowledge.locales)) {
    //   if (knowledge.locales[language].response.attachments) {
    //     for (let f of files) {
    //       const exist = knowledge.locales[language].response.attachments.find(
    //         (a) => a.name == f.name
    //       );
    //       if (exist) existingFiles.push(exist.name);
    //     }
    //   }
    // }
    // if (existingFiles.length > 0)
    //   return this.notificationService.error("error.firestore.same-name", null);
    // return this.batchUpload(
    //   files,
    //   this.toKnowledgeAttachemntLocation(knowledge.knowledgeId)
    // );
  }

  toChatformQuestionAttachemntLocation(chatformId: string, questionId: string) {
    return `/f/${hashShort(chatformId)}/q/${hashShort(questionId)}`;
  }

  // async uploadChatformQuestionAttachments(
  //   chatformId: string,
  //   question: ChatformQuestion,
  //   files: File[]
  // ): Promise<Attachment[]> {
  //   if (question.attachments) {
  //     let existingFiles = [];
  //     for (let language of Object.keys(question.attachments)) {
  //       if (question.attachments[language]) {
  //         for (let f of files) {
  //           const exist = question.attachments[language].find(
  //             (a) => a.name == f.name
  //           );
  //           if (exist) existingFiles.push(exist.name);
  //         }
  //       }
  //     }
  //     if (existingFiles.length > 0)
  //       return this.notificationService.error(
  //         "error.firestore.same-name",
  //         null
  //       );
  //   }
  //   return this.batchUpload(
  //     files,
  //     this.toChatformQuestionAttachemntLocation(chatformId, question._id)
  //   );
  // }

  async uploadUserPhoto(file: File): Promise<string> {
    const [uploadErr, attachment] = await to(
      this.upload(file, "/u", this.authService.currentUser.hash)
    );
    if (uploadErr || !attachment) return null;
    return attachment.url;
  }

  async delete(attachment: Attachment) {
    return this.apiService.deleteObject(attachment.location);
  }

  async batchDelete(attachments: Attachment[]) {
    let promiseArr = [];
    for (let attachment of attachments) {
      promiseArr.push(this.delete(attachment));
    }
    const [err, res] = await to(Promise.all(promiseArr));
    if (err) console.error(err);
    return true;
  }

  async deleteChatformQuestionAttachments(
    chatformId: string,
    questionId: string
  ) {
    return this.apiService.deleteFolder(
      this.toChatformQuestionAttachemntLocation(chatformId, questionId)
    );
  }

  async deleteKnwoledgeAttachments(knowledgeId: string) {
    return this.apiService.deleteFolder(
      this.toKnowledgeAttachemntLocation(knowledgeId)
    );
  }
}
