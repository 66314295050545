export const FRIDAY_SUFFIX = "@";

export interface WhatsappMessageMedia {
  mimetype: string;
  data: string;
  filename?: string;
}

export interface WhatsappLocation {
  latitude: number;
  longitude: number;
  description?: string;
}

export enum WhatsappInstanceState {
  PENDING = "PENDING", // Waiting for scanning QR code
  CONNECTED = "CONNECTED",
  CLOSED = "CLOSED", // Instance Deleted
  ERROR = "ERROR",
  QUEUE = "QUEUE",
  LOW_BATTERY = "LOW_BATTERY",
}

export enum WhatsappMessageType {
  TEXT = "chat",
  AUDIO = "audio",
  VOICE = "ptt",
  IMAGE = "image",
  VIDEO = "video",
  DOCUMENT = "document",
  STICKER = "sticker",
  LOCATION = "location",
  CONTACT_CARD = "vcard",
  CONTACT_CARD_MULTI = "multi_vcard",
  REVOKED = "revoked",
  UNKNOWN = "unknown",
}

export interface WhatsappMessageMedia {
  data: string;
  filename?: string;
  mimetype: string;
}

export interface WhatsappUserId {
  server: string;
  user: string;
  _serialized: string;
}

export interface WhatsappMessage {
  author: string;
  body: string;
  broadcast: boolean;
  from: string;
  fromMe: boolean;
  hasMedia: boolean;
  hasQuotedMsg: boolean;
  id: any;
  isForwarded: boolean;
  location: WhatsappLocation;
  mentionedIds: string[];
  timestamp: number;
  to: string;
  type: WhatsappMessageType;
  media?: WhatsappMessageMedia;
  mediaUrl?: string;
  mentions?: WhatsappContact[];
  quotedMsg?: WhatsappMessage;
  mimetype?: string;
  senderContact?: WhatsappContact;
}

export interface WhatsappChat {
  archived: boolean;
  id: WhatsappUserId;
  isGroup: boolean;
  isMuted: boolean;
  isReadOnly: boolean;
  muteExpiration: number;
  name: string;
  pinned: boolean;
  timestamp: number;
  unreadCount: number;
  messages?: WhatsappMessage[];
  description?: string;
  groupMetadata?: {
    id: WhatsappUserId;
    creation: number;
    owner: any;
    restrict: boolean;
    announce: boolean;
    noFrequentlyForwarded: boolean;
    ephemeralDuration: number;
    support: boolean;
    participants: any[];
    pendingParticipants: any[];
    desc?: string;
    descId?: string;
    descTime?: number;
    descOwner?: any;
  };
}

export interface WhatsappContact {
  id: WhatsappUserId;
  isBusiness: boolean;
  isEnterprise: boolean;
  isGroup: boolean;
  isMe: boolean;
  isMyContact: boolean;
  isUser: boolean;
  isWAContact: boolean;
  name: string;
  number: string;
  pushname: string;
  shortName: string;
  verifiedName: string;
  isBlocked: boolean;
  labels: any[];
  statusMute: boolean;
  type: string;
}
