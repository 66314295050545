import { Injectable, NgZone } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../services/auth.service";

export enum Guard {
  Email = "email",
  // Profile = "profile",
}

const whitelists = ["tfbnw.net", "automagic.co"];
@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private ngZone: NgZone
  ) {}

  bypassEmail(email: string) {
    let bypass = false;
    for (let w of whitelists) {
      if (email.indexOf(`@${w}`) != -1) {
        bypass = true;
        break;
      }
    }
    return bypass;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    // console.log('[AuthGuard] Check Activatable');
    return new Observable((observer) => {
      this.authService.authUserChanges.subscribe((authUser) => {
        // console.log(authUser);
        if (!this.authService.initialized) return;
        if (this.authService.isSuperAdmin) return observer.next(true);
        if (authUser) {
          let allowed = true;
          const guards = route.data ? route.data.guards || [] : [];
          // console.log(guards);
          for (let guard of guards) {
            if (allowed) {
              switch (guard) {
                case Guard.Email:
                  if (
                    !authUser.emailVerified &&
                    !this.bypassEmail(authUser.email)
                  ) {
                    console.log(
                      "[EmailGuard] Email not verified. Redirecting to /verification"
                    );
                    allowed = false;
                    this.ngZone.run(() =>
                      this.router.navigate(["/verification"])
                    );
                  }
                  break;
                // case Guard.Profile:
                //   this.authService.clientChanges.subscribe((client) => {
                //     if (client) {
                //       if (!client.primaryLanguage) {
                //         console.log(
                //           "[ProfileGuard] User profile not initialized. Redirecting to /onboarding"
                //         );
                //         this.ngZone.run(() =>
                //           this.router.navigate(["/onboarding"])
                //         );
                //         allowed = false;
                //       }
                //     } else {
                //       allowed = false;
                //     }
                //   });
                //   break;
              }
            }
          }

          console.log("[AuthGuard] Authenticated", allowed);
          allowed ? observer.next(true) : observer.next(false);
        } else {
          console.log("[AuthGuard] Not authenticated. Redirecting to /auth");
          observer.next(false);
          this.ngZone.run(() => {
            if (
              state.url != "/" &&
              state.url != "" &&
              state.url != "/onboarding"
            ) {
              // window.location.href = 'auth?redirect=' + state.url;
              this.router.navigate(["/auth"], {
                queryParams: {
                  redirect: state.url,
                },
              });
            } else {
              // window.location.href = 'auth';
              this.router.navigate(["/auth"]);
            }
          });
        }
      });
    });
  }
}
