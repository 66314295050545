import { Language } from "../misc";
import { TaskforcePermission } from "./permission";
import { User, UserParams } from "./user";

export interface TaskforceParams extends UserParams {
  languages?: Language[];
}

export class Taskforce extends User {
  languages: Language[];

  constructor(params: TaskforceParams) {
    super(params);
    this.permission = params.permission || TaskforcePermission.Operator;
    this.languages = params.languages || [];
  }
}
