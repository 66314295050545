import { pullAt } from "lodash";
import { toJSON, unique } from "../../../helper";
import { WhatsappInstanceState } from "../../../messenger/channel/whatsapp";

export interface WhatsappInstance {
  id?: string;
  state?: WhatsappInstanceState;
  qr?: string;
  whatsappId?: string;
  number?: string;
  server?: string;
  device?: {
    wa_version?: string;
    os_version?: string;
    device_manufacturer?: string;
    device_model?: string;
    os_build_number?: string;
  };
  platform?: string;
  pushname?: string;
  profilePic?: string;
}

export class ChannelSettingFridayWhatsappParams {
  instances?: WhatsappInstance[];
  quota?: number;
}
export class ChannelSettingFridayWhatsapp {
  instanceIds: string[];
  instances: WhatsappInstance[];
  quota: number;

  constructor(params: ChannelSettingFridayWhatsappParams = {}) {
    this.instances = params.instances || [];
    this.instanceIds = unique(this.instances.map((i) => i.id || ""));
    this.quota = params.quota ?? 1;
  }

  get json() {
    return toJSON(this);
  }

  updateInstanceIds() {
    this.instanceIds = unique(
      this.instances
        .map((i) => i.id || "")
        .filter((id) => id != WhatsappInstanceState.QUEUE)
    );
    return this.instanceIds;
  }

  instance(instanceOrWhatsappId: string): WhatsappInstance | null {
    return (
      this.instances.find(
        (i) =>
          i.id == instanceOrWhatsappId || i.whatsappId == instanceOrWhatsappId
      ) || null
    );
  }

  addInstance(instance?: WhatsappInstance) {
    if (!instance || !instance.id) {
      instance = { id: WhatsappInstanceState.QUEUE };
      this.instances.push(instance);
    } else {
      const i = this.instances.findIndex(
        (_instance) => _instance.id == WhatsappInstanceState.QUEUE
      );
      i == -1 ? this.instances.push(instance) : (this.instances[i] = instance);
    }
    return this.updateInstanceIds();
  }

  removeInstance(instanceId: string) {
    const i = this.instances.findIndex(
      (_instance) => _instance.id == instanceId
    );
    if (i != -1) pullAt(this.instances, i);
    return this.updateInstanceIds();
  }

  popQueue() {
    return this.removeInstance(WhatsappInstanceState.QUEUE);
  }
}
