import { isEmpty } from "lodash";
import { v4 as uuid } from "uuid";
import { ChatflowPayload } from "../autopilot/chatflow/chatflow";
import { toJSON } from "../helper";
import {
  REGEX_BOLD,
  REGEX_ITALIC,
  REGEX_MONOSPACE,
  REGEX_PARAMETER,
  REGEX_STRIKETHROUGH,
  REGEX_URL,
} from "../misc/regex";
import { Attachment, AttachmentType } from "./attachment";
import { Channel } from "./channel";
import { Customer } from "./customer";

export const DELETED_MESSAGE_TEXT = "This message is deleted.";

export enum MessageFlag {
  Chatflow = "chatflow",
  ChatflowError = "chatflow:error",
  ChatflowStaff = "chatflow:staff",
  ChatflowEnd = "chatflow:end",

  FacebookComment = "facebook:comment",
  InstagramComment = "instagram:comment",

  // IntentNotFound = "intent_not_found",
  // TaskRequired = "task_required",

  // InactiveChatform = "inactive_chatform",
  // RepeatChatform = "repeat_chatform",
  // MissingResponse = "missing_response",
  // BuiltinMissingResponse = "builtin_missing_response",

  // ErrorCreatingTask = "error_creating_task",
  // UnknownError = "unknown_error",
  // MissingKnowledge = "missing_knowledge",
  // UnknownLanguage = "unknown_language",
  // InactiveKnowledge = "inactive_knowledge",
  // RespondManually = "respond_manually",
}

export const RESPONSE_FLAGS = [MessageFlag.Chatflow];

export const COMMENT_FLAGS = [
  MessageFlag.FacebookComment,
  MessageFlag.InstagramComment,
];

const SHOULD_HIDE_FLAGS: MessageFlag[] = [];

export enum FORMAT_STYLE {
  bold = "bold",
  italic = "italic",
  monospace = "monospace",
  strikethrough = "strikethrough",
}

const formatMessage = (style: FORMAT_STYLE, text: string): string => {
  if (!text) return "";
  let c = "";
  switch (style) {
    case FORMAT_STYLE.bold:
      c = "*";
      break;
    case FORMAT_STYLE.italic:
      c = "_";
      break;
    case FORMAT_STYLE.monospace:
      c = "```";
      break;
    case FORMAT_STYLE.strikethrough:
      c = "~";
      break;
  }
  return ` ${c}${text}${c} `;
};

export const formattedMessageToHtml = (message: string): string => {
  try {
  } catch (e) {}
  return message;
};

export enum QuickReplyType {
  Text = "text",
  Phone = "phone",
  Email = "email",
}
export interface QuickReply {
  type: QuickReplyType;
  title?: string;
  payload?: string | number;
  imageUrl?: string;
}

export interface MessagePayload {
  chatflow?: ChatflowPayload;
  task?: { id: string };
  ref?: string;
  [key: string]: any;
}

export interface MessageParams {
  clientId: string;
  senderId: string;
  channel: Channel;
  id?: string;
  senderName?: string;
  sendBy?: string;
  pending?: boolean;
  revoked?: boolean;
  edited?: boolean;
  createdAt?: number;
  deliveredAt?: number;
  timestamp?: number;
  text?: string;
  attachments?: Attachment[];
  flag?: MessageFlag;
  payload?: MessagePayload;
  replyTo?: string;
  quickReplies?: QuickReply[];
}

export class Message {
  clientId: string;
  id: string;
  senderId: string;
  senderName?: string;
  sendBy: string;
  channel: Channel;
  createdAt: number;
  deliveredAt?: number;
  timestamp: number;
  pending: boolean;
  revoked?: boolean;
  edited?: boolean;
  text?: string;
  attachments?: Attachment[];
  flag?: MessageFlag;
  payload?: MessagePayload;
  replyTo?: string;
  quickReplies?: QuickReply[];

  constructor(params: MessageParams) {
    this.clientId = params.clientId;
    this.id = params.id || uuid();
    this.senderId = params.senderId;
    this.sendBy = params.sendBy || this.senderId;
    this.channel = params.channel || Channel.Web;

    this.createdAt = params.createdAt || new Date().valueOf();
    if (params.deliveredAt) this.deliveredAt = params.deliveredAt;

    this.timestamp = this.deliveredAt
      ? Math.max(this.createdAt, this.deliveredAt)
      : this.createdAt;

    this.pending = !!params.pending;
    if (params.revoked) this.revoked = true;
    if (params.edited) this.edited = true;
    if (params.senderName) this.senderName = params.senderName;
    if (params.text) this.text = params.text.normalize("NFKC").trim();
    if (!isEmpty(params.attachments))
      this.attachments = params.attachments!.filter(
        (a) => !isEmpty(a) && a.type != AttachmentType.fallback
      );
    if (params.flag) this.flag = params.flag;
    if (params.payload) this.payload = params.payload;
    if (params.replyTo) this.replyTo = params.replyTo;
    if (!isEmpty(params.quickReplies)) this.quickReplies = params.quickReplies;
  }

  updateTimestamp(offset: number = 0) {
    this.createdAt = new Date().valueOf() + offset;
    this.timestamp = this.deliveredAt
      ? Math.max(this.createdAt, this.deliveredAt)
      : this.createdAt;
  }

  markDelivered() {
    this.deliveredAt = new Date().valueOf();
    this.updateTimestamp();
  }

  personalizeMessage = (customer?: Customer | null): string => {
    if (!this.text) return "";
    let text = this.text;
    if (customer) {
      text = text.replace(RegExp("{{name}}", "g"), customer.name || "");
      text = text.replace(
        RegExp("{{firstName}}", "g"),
        customer.firstName || customer.name || ""
      );
      text = text.replace(RegExp("{{lastName}}", "g"), customer.lastName || "");
      text = text.replace(RegExp("{{email}}", "g"), customer.email || "");
    }
    this.text = text.replace(REGEX_PARAMETER, "").trim();
    return this.text;
  };

  get preview(): string {
    return (
      this.text ||
      (!isEmpty(this.attachments)
        ? this.attachments![0].name ||
          this.attachments![0].url ||
          this.attachments![0].type ||
          ""
        : "")
    ).trim();
  }

  get ref(): string {
    if (this.flag && COMMENT_FLAGS.includes(this.flag) && this.payload?.ref)
      return this.payload.ref;
    return "";
  }

  get shouldHide(): boolean {
    if (!this.hasContent) return true;
    if (!this.flag) return false;
    if (COMMENT_FLAGS.includes(this.flag)) return false;
    if (RESPONSE_FLAGS.includes(this.flag)) return false;
    return SHOULD_HIDE_FLAGS.includes(this.flag);
  }

  get hasContent(): boolean {
    if (!this.text && !this.attachments) return false;
    if (this.attachments && isEmpty(this.attachments)) return false;
    return true;
  }

  get json() {
    return toJSON(this);
  }

  get isResponse(): boolean {
    return !this.pending && (!this.flag || RESPONSE_FLAGS.includes(this.flag));
  }

  get isComment(): boolean {
    return !this.flag || COMMENT_FLAGS.includes(this.flag);
  }

  get isInternal(): boolean {
    return this.channel == Channel.Web || this.pending;
  }

  get italic(): string {
    return formatMessage(FORMAT_STYLE.italic, this.text || "");
  }

  get bold(): string {
    return formatMessage(FORMAT_STYLE.bold, this.text || "");
  }

  get monospace(): string {
    return formatMessage(FORMAT_STYLE.monospace, this.text || "");
  }

  get strikethrough(): string {
    return formatMessage(FORMAT_STYLE.strikethrough, this.text || "");
  }

  get html(): string {
    if (!this.text) return "";

    if (
      !(
        this.text.match(REGEX_ITALIC) ||
        this.text.match(REGEX_BOLD) ||
        this.text.match(REGEX_MONOSPACE) ||
        this.text.match(REGEX_STRIKETHROUGH)
      ) ||
      this.text.match(REGEX_URL)
    )
      return this.text;

    let html = this.text;
    for (let style of [
      FORMAT_STYLE.bold,
      FORMAT_STYLE.italic,
      FORMAT_STYLE.monospace,
      FORMAT_STYLE.strikethrough,
    ]) {
      let startTag = "";
      let endTag = "";
      let indicator = "";
      let regex: RegExp;
      switch (style) {
        case FORMAT_STYLE.bold:
          regex = REGEX_BOLD;
          indicator = "*";
          startTag = "<b>";
          endTag = "</b>";
          break;
        case FORMAT_STYLE.italic:
          regex = REGEX_ITALIC;
          indicator = "_";
          startTag = "<i>";
          endTag = "</i>";
          break;
        case FORMAT_STYLE.monospace:
          regex = REGEX_MONOSPACE;
          indicator = "```";
          startTag = "<span class='monospace'>";
          endTag = "</span>";
          break;
        case FORMAT_STYLE.strikethrough:
          regex = REGEX_STRIKETHROUGH;
          indicator = "~";
          startTag = "<del>";
          endTag = "</del>";
          break;
      }

      if (!regex) continue;
      const matches = this.text.match(regex);
      if (!matches) continue;

      for (let match of matches) {
        const findStr = match.substring(match.indexOf(indicator))?.trim();
        if (!findStr) continue;
        const l = indicator.length;
        const replaceStr = `${startTag}${findStr.substring(
          l,
          findStr.length - l
        )}${endTag}`;
        html = html.replace(findStr, replaceStr);
      }
    }
    return html;
  }
}
