import { Component, NgZone, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { environment } from "../../../../environments/environment";
import { AuthService } from "../../../services/auth.service";

@Component({
  selector: "app-redirect",
  templateUrl: "./redirect.component.html",
  styleUrls: ["./redirect.component.scss"],
})
export class RedirectComponent implements OnInit {
  authSub: Subscription;
  redirect = "";
  mobile = !!environment.mobile;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private ngZone: NgZone
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(
      (params) =>
        (this.redirect = params.redirect || this.mobile ? "inbox" : "")
    );
    const timeout = setTimeout(
      (_) => this.ngZone.run(() => this.router.navigate(["/auth"])),
      15000
    );

    this.authSub = this.authService.authUserChanges.subscribe((authUser) => {
      if (authUser) {
        clearTimeout(timeout);
        console.log("[RedirectComponent] Redirecting to", this.redirect);
        this.ngZone.run(() => this.router.navigate([this.redirect]));
      }
    });
  }
}
