<div
  class="message__attachment"
  *ngIf="message.attachments && message.attachments.length > 0"
>
  <app-message-header
    [message]="message"
    [previousSenderName]="previousSenderName"
  ></app-message-header>
  <div
    class="message__attachment__item"
    *ngFor="
      let attachment of message.attachments;
      let i = index;
      trackBy: attachmentTracker
    "
  >
    <a
      *ngIf="
        attachment.type != AttachmentType.file &&
        (attachment.url || attachment.data)
      "
    >
      <img
        *ngIf="attachment.type == AttachmentType.image"
        [src]="toMediaSource(attachment)"
        alt="{{ 'error.resource.inaccessible' | translate }}"
      />

      <video controls muted *ngIf="attachment.type == AttachmentType.video">
        <source [src]="toMediaSource(attachment)" />
        {{ "error.resource.inaccessible" | translate }}
      </video>

      <audio controls *ngIf="attachment.type == AttachmentType.audio">
        <source [src]="toMediaSource(attachment)" />
        {{ "error.resource.inaccessible" | translate }}
      </audio>
    </a>

    <a
      *ngIf="
        attachment.type == AttachmentType.file ||
        (!attachment.url && attachment.data)
      "
      [href]="toMediaSource(attachment)"
      [download]="
        attachment.mimetype && attachment.data
          ? attachment.name || message.id + '_' + i
          : ''
      "
      target="_blank"
    >
      <div class="file">
        <i class="material-icons" *ngIf="attachment.type == AttachmentType.file"
          >insert_drive_file</i
        >
        <i
          class="material-icons"
          *ngIf="attachment.type == AttachmentType.video"
          >theaters</i
        >
        <i
          class="material-icons"
          *ngIf="attachment.type == AttachmentType.audio"
          >mic</i
        >
        <div class="filename" *ngIf="attachment.name || attachment.url">
          {{ attachment.name || fileName(attachment.url) }}
        </div>
      </div>
    </a>

    <div class="message__timestamp">
      <div class="info" *ngIf="attachment">
        {{ fileSize(attachment.size) }}
      </div>
      <div class="time">
        <ng-container *ngIf="timestamp">{{ timestamp }}</ng-container>
        <ng-container *ngIf="message.senderId != sourceId">
          <ng-container *ngIf="message.senderId != FRIDAY_ID">
            <i class="material-icons" *ngIf="!message.deliveredAt">done</i>
            <i class="material-icons" *ngIf="message.deliveredAt">done_all</i>
          </ng-container>

          <img
            *ngIf="message.senderId == FRIDAY_ID"
            src="assets/img/logo-white.png"
          />
        </ng-container>
      </div>
    </div>
  </div>
</div>
