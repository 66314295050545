import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { Language } from "@models/misc";
import { Client, FacebookPage } from "@models/user";
import { formatDate, stringToDate, unique } from "@utility";
import { ClientService } from "../../../services/client.service";
import { FacebookService } from "../../../services/facebook.service";

@Component({
  selector: "app-fb-post-selector",
  templateUrl: "./fb-post-selector.component.html",
  styleUrls: ["./fb-post-selector.component.scss"],
})
export class FbPostSelectorComponent implements OnInit {
  pagePosts: any;
  client: Client;
  pages: FacebookPage[];
  selectedPostId: string;
  selectedPostStoryId: string;
  selectedPageId: string;
  selectedPage: FacebookPage;
  selectedPost: any;
  selected = false;
  keywords: string[] = [];
  pageIds: string[] = [];
  keywordInput: string;
  languages: Language[];
  language: Language;
  comment: string;
  reloading = false;

  constructor(
    public dialogRef: MatDialogRef<FbPostSelectorComponent>,
    private sanitization: DomSanitizer,
    private facebookService: FacebookService,
    private clientService: ClientService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  async ngOnInit() {
    this.selected = this.data && this.data.triggerId && this.data.keywords;
    this.client = this.clientService.client;
    this.languages = [this.client.primaryLanguage];
    this.language = this.data
      ? this.data.language || this.client.primaryLanguage
      : this.client.primaryLanguage;
    this.pages = this.client.channels.facebook.pages;

    if (this.selected) {
      [this.selectedPageId, this.selectedPostStoryId] =
        this.data.triggerId.split("_");
      this.selectedPage = this.pages.find((p) => p.id == this.selectedPageId);
      this.selectedPost = await this.facebookService.getPostById(
        this.data.triggerId
      );
      console.log(this.selectedPostStoryId, this.selectedPost);
      this.pageIds = this.pages.map((p) => p.id);
    } else {
      this.pagePosts = await this.facebookService.getPosts();
      this.pageIds = Object.keys(this.pagePosts);
      console.log(this.pagePosts);
    }

    this.selectedPostId = this.data
      ? this.data.triggerId
      : this.pageIds.length > 0
      ? this.pagePosts[this.pageIds[0]].scheduled
        ? this.pagePosts[this.pageIds[0]].scheduled[0].id
        : this.pagePosts[this.pageIds[0]].published[0].id
      : null;
    if (!this.selectedPost && this.selectedPostId)
      this.selectPost(this.selectedPostId);
    this.keywords = this.data ? this.data.keywords : [];
    this.comment = this.data ? this.data.comment : "";
  }

  selectPost(postId: string) {
    console.log(postId);
    this.selectedPostId = postId;
    for (let pageId of this.pageIds) {
      if (!this.pagePosts[pageId]) continue;
      const publishedPost = this.pagePosts[pageId].published.find(
        (p) => p.id == postId
      );
      if (publishedPost) {
        this.selectedPost = publishedPost;
        break;
      }

      const scheduledPost = this.pagePosts[pageId].scheduled.find(
        (p) => p.id == postId
      );
      if (scheduledPost) {
        this.selectedPost = scheduledPost;
        break;
      }
    }
    console.log(this.selectedPost);
  }

  bg(post) {
    if (post.full_picture)
      return this.sanitization.bypassSecurityTrustStyle(
        `url('${post.full_picture}')`
      );
    return "none";
  }

  addKeyword() {
    if (this.keywordInput)
      this.keywords = unique([...this.keywords, ...[this.keywordInput]]);
    this.keywordInput = "";
  }

  removeKeyword(keyword: string) {
    this.keywords = this.keywords.filter((k) => k != keyword);
  }

  async getPosts() {
    this.reloading = true;
    this.pagePosts = await this.facebookService.getPosts(true);
    this.reloading = false;
  }

  createdTime(time: string) {
    return formatDate(stringToDate(time));
  }

  scheduledTime(timestamp: number) {
    return formatDate(timestamp * 1000);
  }

  close() {
    this.dialogRef.close();
  }

  submit() {
    if (this.selectedPostId)
      this.dialogRef.close({
        postId: this.selectedPostId,
        keywords: this.keywords,
        language: this.language,
        comment: this.comment,
      });
  }
}
