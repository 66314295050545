import { Component, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { REGEX_EMAIL } from "@models/misc";
import { ClientPermission } from "@models/user";
import { ApiService } from "../../../services/api.service";

@Component({
  selector: "app-add-user",
  templateUrl: "./add-user.component.html",
  styleUrls: ["./add-user.component.scss"],
})
export class AddUserComponent implements OnInit {
  email: string;
  displayName: string;
  password: string;
  confirmPassword: string;
  permission = ClientPermission.Operator;
  error = "";
  loading = false;
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddUserComponent>,
    private apiService: ApiService
  ) {}

  ngOnInit() {}

  close() {
    this.dialogRef.close();
  }

  async createSubAccount() {
    if (
      !this.email ||
      !this.displayName ||
      !this.password ||
      !this.confirmPassword
    ) {
      this.error = "error.auth.all-required";
      return false;
    }

    if (!this.email.match(REGEX_EMAIL)) {
      this.error = "error.auth.invalid-email";
      return false;
    }

    if (this.password.length < 6) {
      this.error = "error.fireabse.auth/weak-password";
      return false;
    }

    if (this.password != this.confirmPassword) {
      this.error = "error.auth.password-mismatch";
      return false;
    }

    this.loading = true;
    await this.apiService.createSubAccount(
      this.email,
      this.password,
      this.displayName,
      this.permission
    );

    this.dialogRef.close();
  }
}
