<div class="page" *ngIf="client">
  <div class="page__header">
    <div class="page__container">
      <div class="page__header__tab">
        <a
          [class.selected]="isActiveRoute('/dashboard')"
          [routerLink]="['/dashboard']"
        >
          <i class="material-icons">dashboard</i>
          <span translate>nav.dashboard</span>
        </a>
      </div>
    </div>
  </div>

  <div class="page__content">
    <div class="page__container">
      <div class="dashboard" [@smooth]>
        <div class="dashboard__card">
          <div class="dashboard__card__icon">
            <img src="assets/img/customer-blue.png" alt="customer served" />
          </div>
          <div class="dashboard__card__content">
            <label>{{ statistics.customer_count }}</label>
            <span translate>dashboard.statistic.customer-served</span>
          </div>
        </div>

        <div class="dashboard__card">
          <div class="dashboard__card__icon">
            <img src="assets/img/automation-purple.png" alt="automation" />
          </div>

          <div class="dashboard__card__content">
            <label>{{ statistics.automation_percentage }}%</label>
            <span translate>dashboard.statistic.automated</span>
          </div>
        </div>

        <div class="dashboard__card">
          <div class="dashboard__card__icon">
            <img src="assets/img/time-green.png" alt="time saved" />
          </div>
          <div class="dashboard__card__content">
            <label>
              {{ timeSaved.value }}
              <span>{{ timeSaved.unit | translate }} </span>
            </label>
            <span translate>dashboard.statistic.saved</span>
          </div>
        </div>

        <div class="dashboard__card">
          <div class="dashboard__card__icon">
            <img src="assets/img/flash-yellow.png" alt="time saved" />
          </div>
          <div class="dashboard__card__content">
            <label>
              {{ statistics.average_respond_time }}
              <span translate>general.second-unit</span>
            </label>
            <span translate>dashboard.statistic.response-time</span>
          </div>
        </div>

        <div class="dashboard__chart large">
          <ng-container *ngIf="messageChart">
            <app-empty
              *ngIf="messageChart.length == 0"
              [icon]="'insert_chart'"
              [message]="'dashboard.not-enough-data'"
            ></app-empty>
            <app-chart
              [data]="messageChart"
              [xLabel]="'Date'"
              [xFormat]="xFormat"
              [type]="ChartType.area"
              [yAxis]="false"
              [icon]="'textsms'"
              [title]="'dashboard.chart.messages-replied' | translate"
              [description]="
                'dashboard.chart.messages-replied-description' | translate
              "
            ></app-chart>
          </ng-container>
        </div>

        <div class="dashboard__chart">
          <app-empty
            *ngIf="automationChart.length == 0"
            [icon]="'insert_chart'"
            [message]="'dashboard.not-enough-data'"
          ></app-empty>
          <app-chart
            [data]="automationChart"
            [type]="ChartType.pie"
            [colors]="[colors.warn, colors.primary]"
            [icon]="'school'"
            [title]="'dashboard.chart.performance' | translate"
            [description]="
              'dashboard.chart.performance-description' | translate
            "
          >
          </app-chart>
        </div>

        <div class="dashboard__chart">
          <app-empty
            *ngIf="taskforceChart.length == 0"
            [icon]="'insert_chart'"
            [message]="'dashboard.not-enough-data'"
          ></app-empty>
          <app-chart
            [data]="taskforceChart"
            [type]="ChartType.pie"
            [colors]="[colors.black, colors.primary]"
            [icon]="'work'"
            [title]="'dashboard.chart.taskforce' | translate"
            [description]="'dashboard.chart.taskforce-description' | translate"
          >
          </app-chart>
        </div>
      </div>
    </div>
  </div>
</div>
