import { isEmpty } from "lodash";
import { v4 as uuid } from "uuid";
import { ChatflowStateParams } from ".";
import { toJSON, unique } from "../../helper";
import { Language, TextTranslation } from "../../misc";
import { ChatflowState } from "./state";
import { ChatflowStep, ChatflowStepParams } from "./step";

export interface ChatflowPayload {
  id: string;
  history: ChatflowState[] | ChatflowStateParams[];
  staff?: {
    fields?: string[];
    values?: { [key: string]: string };
    approved?: boolean;
    message?: string;
  };
}
export interface ChatflowParams {
  clientId: string;
  id?: string;
  steps?: ChatflowStepParams[];
  googleSheetId?: string;
  errMsg?: TextTranslation;
}

export class Chatflow {
  clientId: string;
  id: string;
  steps: ChatflowStep[];
  googleSheetId?: string;
  errMsg?: TextTranslation;

  constructor(params: ChatflowParams) {
    this.clientId = params.clientId;
    this.id = params.id || uuid();
    this.steps = params.steps?.map((step) => new ChatflowStep(step)) || [];
    if (params.googleSheetId) this.googleSheetId = params.googleSheetId;
    if (params.errMsg) this.errMsg = params.errMsg || {};
  }

  get json() {
    return toJSON(this);
  }

  get variableNames() {
    return unique(this.steps.map((_step) => _step.variableName || ""));
  }

  errorMessage(language: Language): string {
    if (isEmpty(this.errMsg)) return "";
    return (
      this.errMsg![language] ||
      this.errMsg![
        Object.keys(this.errMsg!).find((l) => l != language) || Language.en
      ] ||
      this.errMsg![Language.zh] ||
      ""
    );
  }
}
