import { v4 as uuid } from "uuid";
import { toJSON } from "../../../helper";
import { Channel } from "../../../messenger";

export class ConversationTag {
  tagId: string;
  color: string;
  text: string;

  constructor(params: { tagId?: string; color?: string; text?: string } = {}) {
    this.tagId = params.tagId || uuid();
    this.color = params.color || "#38509c";
    this.text = params.text || "New Tag";
  }

  get json() {
    return toJSON(this);
  }
}

export class ConversationSetting {
  tags: ConversationTag[];
  channels: Channel[];

  constructor(params?: { tags?: ConversationTag[]; channels?: Channel[] }) {
    this.tags = params?.tags
      ? params?.tags.map((t) => new ConversationTag(t))
      : [];
    this.channels = params?.channels || [];
  }

  get json() {
    return toJSON(this);
  }
}
