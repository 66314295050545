<div class="page" *ngIf="client" id="inbox">
  <div class="page__header">
    <div class="page__header__tab">
      <a
        [class.selected]="currentChannel == Channel.All"
        [routerLink]="['/inbox']"
      >
        <i class="material-icons">forum</i>
        <span translate>channel.all</span>
        <!-- <span class="unread-count" *ngIf="unreadCount > 0"
          >({{ unreadCount }})</span
        > -->
      </a>

      <a
        *ngFor="let channel of channels"
        [class.selected]="currentChannel == channel"
        [routerLink]="['/inbox/', channel]"
      >
        <img src="assets/img/{{ channel }}-logo.svg" />
        <!-- <img src="assets/img/{{ channel }}-logo-white.png" /> -->
        <span>{{ "channel." + channel | translate }}</span>
      </a>
      <a
        *ngIf="showArchive || currentChannel == Channel.Archived"
        [class.selected]="currentChannel == Channel.Archived"
        [routerLink]="['/inbox/', Channel.Archived]"
      >
        <i class="material-icons">archive</i>
        <span translate>channel.archived</span>
      </a>
    </div>
  </div>

  <div class="page__toolbar">
    <div class="channel-source" *ngIf="channels.includes(currentChannel)">
      <label><span translate>inbox.source</span>:</label>
      <button
        class="text-button"
        mat-button
        [matMenuTriggerFor]="channelInfoMenu"
        [matMenuTriggerData]="{ sources: channelSources }"
        [disabled]="channelSources.length < 2"
      >
        <ng-container
          *ngIf="
            currentSourceById(currentSourceId, channelSources) as channelSource
          "
        >
          <img
            [src]="
              channelSource.picture ||
              'assets/img/' + currentChannel + '-logo.svg'
            "
            class="round-corner"
          />
          <span>{{
            channelSource.name || (currentChannel + ".all-sources" | translate)
          }}</span>
          <i class="material-icons drop-down" *ngIf="channelSources.length > 1"
            >arrow_drop_down</i
          >
        </ng-container>
      </button>
    </div>

    <button
      class="outline-button border-primary"
      *ngIf="showAddConversation"
      (click)="startConversation()"
    >
      <i class="material-icons">add_circle</i>
      <span translate>inbox.new-conversation</span>
    </button>

    <div class="filter" *ngIf="conversations?.length > 0">
      <button
        class="text-button primary"
        mat-button
        [matMenuTriggerFor]="filterMenu"
      >
        <i class="material-icons">filter_alt</i>
        <span translate>general.filter</span>
      </button>

      <div class="tags">
        <ng-container *ngFor="let tagId of filterTagIds">
          <a
            class="tags__item"
            *ngIf="tagIdToTag(tagId) as tag"
            [style.backgroundColor]="tag.color"
            (click)="removeFilterTag(tagId)"
          >
            <span>{{ tag.text }}</span>
          </a>
        </ng-container>
      </div>

      <a class="clear" *ngIf="filterTagIds.length > 0" (click)="clearFilter()">
        <i class="material-icons">cancel</i>
      </a>
    </div>

    <div class="searchbox" *ngIf="conversations?.length > 0">
      <i class="material-icons">search</i>
      <input
        type="text"
        [(ngModel)]="searchText"
        [placeholder]="'placeholder.search-conversations' | translate"
      />
      <a class="clear" *ngIf="searchText" (click)="clearSearch()">
        <i class="material-icons">cancel</i>
      </a>
    </div>
  </div>

  <app-loader *ngIf="loading || !initialized || !conversations"></app-loader>

  <div class="page__content" *ngIf="!loading && initialized && conversations">
    <ng-container
      *ngIf="
        conversations
          | channel: currentChannel:currentSourceId
          | chatSearch: searchText
          | chatFilter: filterTagIds as currentConversations
      "
    >
      <div
        class="inbox"
        *ngIf="currentConversations.length > 0"
        (mousemove)="resizeChatlist($event)"
        (mouseup)="stopResizeChatlist()"
        #inbox
      >
        <!-- Contact List -->
        <div class="inbox__contacts" id="contacts" #contacts [@smooth]>
          <app-contact-item
            *ngFor="
              let conversation of currentConversations;
              let i = index;
              trackBy: conversationTracker
            "
            [conversation]="conversation"
            [active]="
              currentConversationId
                ? currentConversationId == conversation.id
                : i == 0
            "
            (click)="currentConversationId = conversation.id"
            [@smooth]
          ></app-contact-item>

          <div class="end-indicator" *ngIf="currentConversations.length > 0">
            <span translate>general.end</span>
          </div>
        </div>
        <!-- Contact List -->

        <!-- Resize Handle-->
        <div
          class="vertical-resize-handle"
          (mousedown)="startResizeChatlist()"
        ></div>
        <!-- Resize Handle-->

        <ng-container
          *ngIf="
            currentConversations
              | conversationId: currentConversationId as currentConversation
          "
        >
          <app-chatroom [conversation]="currentConversation" [@smooth]>
          </app-chatroom>

          <app-empty
            *ngIf="!currentConversation && !reloading"
            [icon]="'keyboard_arrow_left'"
            [message]="'inbox.select-conversation'"
          ></app-empty>
        </ng-container>
      </div>

      <!-- Empty Placeholders -->
      <ng-container *ngIf="currentConversations.length == 0">
        <app-empty
          *ngIf="channels.length > 0"
          [cover]="true"
          [icon]="'inbox'"
          [message]="'inbox.empty-conversation'"
        ></app-empty>

        <app-empty
          *ngIf="channels.length == 0"
          [cover]="true"
          [icon]="'inbox'"
          [message]="'inbox.get-started'"
          [button]="'inbox.set-up-channel'"
          (onaction)="addChannel()"
        ></app-empty>
      </ng-container>
      <!-- Empty Placeholders -->
    </ng-container>
  </div>

  <mat-menu #channelMenu="matMenu" xPosition="after" yPosition="below">
    <button
      class="text-button"
      mat-menu-item
      (click)="addChannel(Channel.Web)"
      *ngIf="!channels.includes(Channel.Web)"
    >
      <img src="assets/img/logo.png" />
      <span translate>inbox.website</span>
    </button>

    <button
      class="text-button"
      mat-menu-item
      (click)="addChannel(Channel.Facebook)"
      *ngIf="!channels.includes(Channel.Facebook)"
    >
      <img src="assets/img/facebook-logo.svg" />
      <span>facebook</span>
    </button>

    <button
      class="text-button"
      mat-menu-item
      (click)="addChannel(Channel.WhatsApp)"
      *ngIf="!channels.includes(Channel.WhatsApp)"
    >
      <img src="assets/img/whatsapp-logo.svg" />
      <span>WhatsApp</span>
    </button>
  </mat-menu>

  <mat-menu #channelInfoMenu="matMenu" xPosition="after" yPosition="below">
    <ng-template matMenuContent let-sources="sources">
      <button
        *ngIf="sources.length > 1"
        class="text-button"
        mat-menu-item
        (click)="currentSourceId = 'all'"
      >
        <img
          [src]="'assets/img/' + currentChannel + '-logo.svg'"
          class="round-corner"
        />
        <span>{{ currentChannel + ".all-sources" | translate }}</span>
      </button>

      <button
        class="text-button"
        mat-menu-item
        *ngFor="let source of sources"
        (click)="currentSourceId = source.id"
      >
        <img
          [src]="source.picture || 'assets/img/' + currentChannel + '-logo.svg'"
          class="round-corner"
        />
        <span>{{
          source.name || (currentChannel + ".all-sources" | translate)
        }}</span>
      </button>
    </ng-template>
  </mat-menu>

  <mat-menu #filterMenu="matMenu" xPosition="after" yPosition="below">
    <div class="tags">
      <ng-container *ngFor="let tag of client.settings.conversation.tags">
        <a
          class="tags__item"
          *ngIf="showTag(tag.tagId)"
          [style.backgroundColor]="tag.color"
          (click)="filterTag(tag.tagId)"
        >
          <span>{{ tag.text }}</span>
        </a>
      </ng-container>
    </div>
  </mat-menu>
</div>
