import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-autopilot',
  templateUrl: './autopilot.component.html',
  styleUrls: ['./autopilot.component.scss']
})
export class AutopilotComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
