import { flatMap } from "lodash";

export enum AutopilotService {
  Friday = "friday",
  Google = "google",
  Facebook = "facebook",
  WhatsApp = "whatsapp",
  Instagram = "instagram",
  Twilio = "twilio",
  Telegram = "telegram",
  WooCommerce = "woocommerce",
  Shopify = "shopify",
  Notion = "notion",
  Airtable = "airtable",
  Slack = "slack",
  Discord = "discord",
}

export const MESSAGE_EVENT = "message";

export const AUTOPILOT_TRIGGER_EVENTS = {
  All: {
    Message: `all_message`,
  },
  Friday: {
    Message: `${AutopilotService.Friday.toString()}_message`,
  },
  Facebook: {
    Message: `${AutopilotService.Facebook.toString()}_message`,
    PostComment: `${AutopilotService.Facebook.toString()}_post_comment`,
  },
  Instagram: {
    Message: `${AutopilotService.Instagram.toString()}_message`,
    PostComment: `${AutopilotService.Instagram.toString()}_post_comment`,
  },
  WhatsApp: {
    Message: `${AutopilotService.WhatsApp.toString()}_message`,
  },
  Telegram: {
    Message: `${AutopilotService.Telegram.toString()}_message`,
  },
};

export const AUTOPILOT_TRIGGER_EVENTS_LIST = flatMap(
  Object.values(AUTOPILOT_TRIGGER_EVENTS),
  (obj) => Object.values(obj)
);
