import { Component, Input, OnInit } from "@angular/core";
import {
  AUTOPILOT_TRIGGER_EVENTS,
  TriggerConditionType,
  TriggerEntrypoint,
} from "@models/autopilot";
import { range } from "lodash";

@Component({
  selector: "app-trigger-entrypoint",
  templateUrl: "./trigger-entrypoint.component.html",
  styleUrls: ["./trigger-entrypoint.component.scss"],
})
export class TriggerEntrypointComponent implements OnInit {
  @Input() entrypoint: TriggerEntrypoint;
  triggerEvents = AUTOPILOT_TRIGGER_EVENTS;
  TriggerConditionType: typeof TriggerConditionType = TriggerConditionType;
  dayOfWeek = range(7);
  hourStart = 18;
  hourEnd = 9;
  weekdays = [1, 2, 3, 4, 5];

  constructor() {}

  ngOnInit(): void {
    console.log(this.entrypoint);
  }

  toggleWeekday(i: number) {
    if (this.weekdays.includes(i)) {
      this.weekdays = this.weekdays.filter((d) => d != i);
    } else {
      this.weekdays.push(i);
    }
    console.log(this.weekdays, this.hourStart, this.hourEnd);
  }
}
