import { hashShort, toJSON, unique } from "../helper";
import { NotificationDevice } from "./notification";
import {
  AdminPermission,
  ClientPermission,
  TaskforcePermission,
} from "./permission";
export interface UserBaseParams {
  id: string;
  createdAt?: number;
  updatedAt?: number;
}

export class UserBase {
  id: string;
  createdAt: number;
  updatedAt: number;

  constructor(params: UserBaseParams) {
    this.id = params.id;
    this.createdAt = params.createdAt || new Date().valueOf();
    this.updatedAt = params.updatedAt || new Date().valueOf();
  }

  get json() {
    return toJSON(this);
  }

  update() {
    this.updatedAt = new Date().valueOf();
  }
}
export interface UserInfoParams {
  email?: string;
  firstName?: string;
  lastName?: string;
  displayName?: string;
  photoURL?: string;
  phoneNumber?: string;
}

export class UserInfo {
  email: string;
  firstName?: string;
  lastName?: string;
  displayName?: string;
  photoURL?: string;
  phoneNumber?: string;

  constructor(params: UserInfoParams = {}) {
    this.email = params.email || "";
    this.displayName = params.displayName || this.email.split("@")[0] || "";
    if (params.firstName) this.firstName = params.firstName;
    if (params.lastName) this.lastName = params.lastName;
    if (params.photoURL) this.photoURL = params.photoURL;
    if (params.phoneNumber) this.phoneNumber = params.phoneNumber;
  }
}

export interface UserParams extends UserBaseParams {
  info?: UserInfoParams;
  hash?: string;
  permission?:
    | ClientPermission
    | AdminPermission
    | TaskforcePermission
    | number;
  notification?: NotificationDevice[];
}

export class User extends UserBase {
  info: UserInfo;
  hash: string;
  permission: ClientPermission | AdminPermission | TaskforcePermission | number;
  notification: NotificationDevice[];

  constructor(params: UserParams) {
    super(params);
    this.hash = params.hash || hashShort(this.id);
    this.permission = params.permission || 0;
    this.info = new UserInfo(params.info);
    this.notification =
      params.notification?.map((d) => {
        d.active = d.active ?? true;
        d.deviceName = d.deviceName || "";
        return d;
      }) || [];
  }

  get notificationTokens(): string[] {
    return unique(
      this.notification.filter((d) => d.active).map((d) => d.token)
    );
  }

  get displayName(): string {
    return this.info.displayName || this.info.email || "";
  }
}
