import { Component, Input } from "@angular/core";

@Component({
  selector: "app-loader-button",
  templateUrl: "./loader-button.component.html",
  styleUrls: ["./loader-button.component.scss"],
})
export class LoaderButtonComponent {
  @Input() icon: string;
  @Input() img: string;
  @Input() translation: string;
  @Input() text: string;
  @Input() size: number;
  @Input() darkmode: boolean;
  @Input() disabled: boolean;
  @Input() loading: boolean = false;
  @Input() primary: boolean;
  @Input() success: boolean;
  @Input() warn: boolean;
  @Input() error: boolean;
  @Input() black: boolean;
  @Input() btnClass: string = "";

  constructor() {}
}
