export * from "./facebook";
export * from "./whatsapp";

export const FRIDAY_ID = "friday";

export enum Channel {
  All = "all",
  Archived = "archived",
  Facebook = "facebook",
  Web = "web",
  WhatsApp = "whatsapp",
  Instagram = "instagram",
  Telegram = "telegram",
}

export enum WhatsappProvider {
  Friday = "friday",
  Twilio = "twilio",
  Unknown = "unknown",
}

export const ACTIVE_CHANNELS = [
  Channel.Facebook,
  Channel.WhatsApp,
  Channel.Instagram,
  //   Channel.Web,
  //   Channel.Telegram,
];

export const IM_CHANNELS = [...ACTIVE_CHANNELS, ...[Channel.All]];

export const FORMATTABLE_CHANNELS = [Channel.Web, Channel.WhatsApp];

export const QUICKREPLY_CHANNELS = [Channel.Web, Channel.Facebook];
