import { toJSON } from "../../../helper";

export class TaskforceSetting {
  active: boolean;
  check: number;

  constructor(params?: { active?: boolean; check?: number }) {
    this.active = !!params?.active;
    this.check = params?.check ?? 2;
  }

  get json() {
    return toJSON(this);
  }
}
