<div class="channel-header">
  <div class="row">
    <div class="channel-description">
      <h1 translate>livechat.title</h1>
      <ul>
        <li translate>livechat.instruction.1</li>
        <li translate>livechat.instruction.2</li>
        <li translate>livechat.instruction.3</li>
      </ul>
    </div>

    <div class="channel-image">
      <img src="assets/img/logo.png" />
    </div>
  </div>

  <div class="channel-action">
    <app-loader-button
      *ngIf="config"
      (click)="save()"
      [loading]="loading"
      icon="publish"
      translation="general.save-publish"
      [success]="true"
    ></app-loader-button>
  </div>
</div>

<div class="livechat" *ngIf="config">
  <div class="form">
    <div class="form__section" translate>livechat.information</div>
    <mat-tab-group
      [selectedIndex]="selected.value"
      (selectedIndexChange)="selectTab($event)"
      dynamicHeight
      animationDuration="0ms"
    >
      <mat-tab
        *ngFor="let language of languages; let index = index"
        [label]="'general.' + language | translate"
      >
        <div class="form__row">
          <div class="form__label">
            <i class="material-icons">title</i>
            <span translate>livechat.heading</span>
          </div>
          <div class="form__field">
            <input type="text" [(ngModel)]="config.heading[language]" />
          </div>
        </div>
        <div class="form__row">
          <div class="form__label">
            <i class="material-icons">notes</i>
            <span translate>livechat.description</span>
          </div>
          <div class="form__field">
            <input type="text" [(ngModel)]="config.description[language]" />
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>

    <div class="form__section" translate>livechat.design</div>
    <div class="form__row">
      <div class="form__label">
        <i class="material-icons">color_lens</i>
        <span translate>livechat.theme-color</span>
      </div>
      <div class="form__field">
        <input
          class="colorpicker"
          [(colorPicker)]="config.themeColor"
          [style.background]="config.themeColor"
          [cpOutputFormat]="'hex'"
          readonly
        />
      </div>
    </div>

    <div class="form__section" translate>livechat.security</div>
    <div class="form__section__description" translate>
      livechat.url-instruction
    </div>
    <div class="form__row">
      <div class="form__label">
        <i class="material-icons">security</i>
        <span translate>livechat.allowed-url</span>
      </div>
      <div class="form__field">
        <div>http(s)://</div>
        <input
          type="text"
          [(ngModel)]="url"
          [placeholder]="'livechat.url-placeholder' | translate"
          (keydown.enter)="addUrl()"
        />
        <button class="icon-button" (click)="addUrl()" [disabled]="url == ''">
          <i class="material-icons success">add</i>
        </button>
      </div>
    </div>
    <div class="samples">
      <div class="samples__item" *ngFor="let url of config.urlWhitelist">
        {{ url }}
        <a class="error" (click)="removeUrl(url)">
          <i class="material-icons">close</i>
        </a>
      </div>
    </div>

    <div class="form__section" translate>livechat.code-snippet</div>
    <div class="form__section__description" translate>
      livechat.code-instruction
    </div>
    <div class="form__row">
      <button class="icon-button" id="btn-copy-code" (click)="copyCode()">
        <i class="material-icons">file_copy</i>
      </button>
      <textarea class="code" readonly id="friday-code-snippet">{{
        code
      }}</textarea>
    </div>
  </div>

  <div class="preview chatroom">
    <div
      class="chatroom-header column"
      [style.backgroundColor]="config.themeColor"
    >
      <div class="title row">
        {{ config.heading[client.primaryLanguage] }}
      </div>
      <div class="info row">
        {{ config.description[client.primaryLanguage] }}
      </div>

      <div class="icon-button close">
        <i class="material-icons">clear</i>
      </div>

      <div class="icon-button language">
        <i class="material-icons">language</i>
      </div>
    </div>

    <div class="chatroom__content">
      <div class="message right">
        <div class="message__wrapper">
          <div
            class="message__text"
            [style.width]="'120px'"
            [style.backgroundColor]="config.themeColor"
          ></div>
        </div>
      </div>
      <div class="message">
        <div class="message__wrapper">
          <div class="message__text" [style.width]="'200px'"></div>
        </div>
      </div>
      <div class="message right">
        <div class="message__wrapper">
          <div
            class="message__text"
            [style.width]="'60px'"
            [style.backgroundColor]="config.themeColor"
          ></div>
        </div>
      </div>
      <div class="message">
        <div class="message__wrapper">
          <div class="message__text" [style.width]="'120px'"></div>
        </div>
      </div>
      <div class="message right">
        <div class="message__wrapper">
          <div
            class="message__text"
            [style.width]="'180px'"
            [style.backgroundColor]="config.themeColor"
          ></div>
        </div>
      </div>
      <div class="message">
        <div class="message__wrapper">
          <div class="message__text" [style.width]="'260px'"></div>
        </div>
      </div>
      <div class="message right">
        <div class="message__wrapper">
          <div
            class="message__text"
            [style.width]="'80px'"
            [style.backgroundColor]="config.themeColor"
          ></div>
        </div>
      </div>
      <div class="message">
        <div class="message__wrapper">
          <div class="message__text" [style.width]="'100px'"></div>
        </div>
      </div>
    </div>
    <div class="chatroom__input row">
      <input
        type="text"
        placeholder="{{ 'placeholder.type-messages' | translate }}"
        readonly
      />
      <i class="material-icons" [style.color]="config.themeColor">send</i>
    </div>
  </div>
</div>
<app-loader [size]="4" *ngIf="!config"></app-loader>
