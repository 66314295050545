import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "../../../../environments/environment";

@Component({
  selector: "app-setting",
  templateUrl: "./setting.component.html",
  styleUrls: ["./setting.component.scss"],
})
export class SettingComponent implements OnInit {
  mobile = !!environment.mobile;

  constructor(private router: Router) {}

  ngOnInit() {}

  isActiveRoute(url: string) {
    return this.router.url.includes(url);
  }
}
