import { Injectable, NgZone } from "@angular/core";
import {
  Auth,
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  FacebookAuthProvider,
  getIdTokenResult,
  getRedirectResult,
  GoogleAuthProvider,
  linkWithCredential,
  linkWithRedirect,
  onAuthStateChanged,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithRedirect,
  signOut as firebaseSignOut,
  unlink,
  updatePassword,
  User as firebaseUser,
} from "@angular/fire/auth";
import {
  collection,
  CollectionReference,
  doc,
  Firestore,
  getDoc,
  onSnapshot,
  setDoc,
} from "@angular/fire/firestore";
import { Router } from "@angular/router";
import { Client, ClientParams, ClientPermission, User } from "@models/user";
import { NotificationService, SettingService } from "@shared";
import { to } from "@utility";
import { BehaviorSubject } from "rxjs";
import { environment } from "../../environments/environment";
declare var gapi: any;

export enum AuthProvider {
  Google = "google.com",
  Facebook = "facebook.com",
  Email = "password",
}

export const CLIENT_COLLECTION = "clients";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private _authUser$: BehaviorSubject<firebaseUser> = new BehaviorSubject(null);
  public authUserChanges = this._authUser$.asObservable();
  public authUser: firebaseUser;

  private _client$: BehaviorSubject<Client> = new BehaviorSubject(null);
  public clientChanges = this._client$.asObservable();
  public client: Client;

  public isSuperAdmin = false;
  public isSubAccount = false;
  public initialized = false;
  public authenticated = false;
  clientUnsub: any;
  private _collection: CollectionReference;

  public mobile = !!environment.mobile;

  constructor(
    private auth: Auth,
    private firestore: Firestore,
    private notificationService: NotificationService,
    private settingService: SettingService,
    private router: Router,
    private ngZone: NgZone
  ) {
    this._collection = collection(this.firestore, CLIENT_COLLECTION);

    onAuthStateChanged(
      this.auth,
      async (authUser) => {
        // console.log('[AuthService] Auth Changed', authUser);
        this.initialized = true;
        if (!authUser) {
          const [authErr, authRes] = await to(getRedirectResult(this.auth));
          if (authErr)
            this.notificationService.error(`error.firebase.${authErr.code}`);
          return this.reset();
        } else {
          console.log("[AuthService] Authenticated:", authUser.email);

          const [permissionErr, authData] = await to(
            getIdTokenResult(this.auth.currentUser)
          );
          if (permissionErr)
            return this.notificationService.error(
              "error.auth.check-permission",
              null
            );

          if (authData.claims.admin) {
            if (authData.claims.superadmin) {
              this.isSuperAdmin = true;
              this.authUser = authUser;
              this._authUser$.next(authUser);
              return this.ngZone.run(() => this.router.navigate(["admin"]));
            } else {
              this.notificationService.error("error.auth.is-admin");
              setTimeout((_) => this.signOut(), 2000);
              return null;
            }
          }

          if (authData.claims.subaccount) {
            if (!authData.claims.parentId) {
              this.notificationService.error("error.unknown");
              setTimeout((_) => this.signOut(), 2000);
              return null;
            }
            this.isSubAccount = true;
            this.initGAPI();
            this.updateOauthSetting();
            return this.signInAsParent(
              authUser,
              authData.claims.parentId as string
            );
          }

          this.initClient(authUser);
        }
      },
      (error) => this.reset()
    );
  }

  async refreshUser() {
    if (this.auth.currentUser) {
      console.log("[AuthService] Refresh user");
      await this.auth.currentUser.reload();
      this.authUser = this.auth.currentUser;
      this._authUser$.next(this.authUser);
    }
  }

  async signInAsClient(clientId: string) {
    this.clientUnsub = onSnapshot(
      doc(this._collection, clientId),
      (snapshot) => {
        if (snapshot.exists()) {
          const client = new Client(snapshot.data() as ClientParams);
          if (!this.mobile) console.log(client);
          this.client = client;
          this._client$.next(client);
          if (!this.authenticated && this.isSuperAdmin) {
            this.authenticated = true;
            this.ngZone.run(() => this.router.navigate(["dashboard"]));
          }
        } else {
          console.log("[AuthService] Client data removed");
          this.notificationService.error("error.user.removed");
          this.signOut();
        }
      },
      (error) => {
        console.error(error);
        this.notificationService.error("error.unknown");
        this.signOut();
      }
    );
  }

  async signInAsParent(authUser: firebaseUser, clientId: string) {
    this.clientUnsub = onSnapshot(
      doc(this._collection, clientId),
      (snapshot) => {
        if (snapshot.exists()) {
          const client = new Client(snapshot.data() as ClientParams);
          if (!this.mobile) console.log(client);
          this.client = client;
          this._client$.next(client);
          this.authUser = authUser;
          this._authUser$.next(authUser);
        } else {
          console.log("[AuthService] Client data removed");
          this.notificationService.error("error.user.removed");
          this.signOut();
        }
      },
      (error) => {
        console.error(error);
        this.notificationService.error("error.unknown");
        this.signOut();
      }
    );
  }

  async initClient(authUser: firebaseUser) {
    const [err, client] = await to(this.getClientData(authUser));
    if (!client) {
      console.log(err);
      this.notificationService.error("error.unknown");
      // return this.signOut();
      return false;
    }
    this.authUser = authUser;
    this._authUser$.next(authUser);
    this.initGAPI();
    this.updateOauthSetting();
  }

  async initGAPI() {
    if (this.mobile) return false;
    return new Promise((resolve, reject) => {
      gapi.load("client:auth2", async () => {
        const [gapiErr, gapiRes] = await to(
          gapi.client.init({
            apiKey: environment.firebase.apiKey,
            discoveryDocs: environment.gapi.discoveryDocs,
            clientId: environment.firebase.clientId,
            scope: environment.gapi.scopes.join(" "),
          })
        );

        if (gapiErr) {
          console.log(gapiErr);
          resolve(false);
        }
        // console.log("GAPI intialized");
        resolve(true);
      });
    });
  }

  async authorizeGAPI() {
    if (this.mobile) return false;
    const GoogleAuth = gapi.auth2.getAuthInstance();

    const authorize = () => {
      const scope = environment.gapi.scopes.join(" ");
      const GoogleUser = GoogleAuth.currentUser.get();
      const authorized = GoogleUser.hasGrantedScopes(scope);
      // console.log(GoogleUser);
      return new Promise((resolve) => {
        if (authorized) {
          console.log("authorized");
          resolve(true);
        } else {
          GoogleUser.grant({ scope });
          GoogleUser.listen((user) => {
            const authorized = user.hasGrantedScopes(scope);
            console.log("authorized");
            resolve(authorized);
          });
        }
      });
    };

    return new Promise(async (resolve) => {
      const signedIn = GoogleAuth.isSignedIn.get();
      if (signedIn) {
        const authorized = await authorize();
        resolve(authorized);
      } else {
        GoogleAuth.signIn();
        GoogleAuth.isSignedIn.listen(async (succeed) => {
          if (succeed) {
            const authorized = await authorize();
            resolve(authorized);
          } else {
            resolve(false);
          }
        });
      }
    });
  }

  reset() {
    if (this.clientUnsub) this.clientUnsub();
    this.client = null;
    this._client$.next(null);
    this.authUser = null;
    this._authUser$.next(null);
  }

  updateOauthSetting() {
    if (this.authUser) {
      const settings = this.settingService.updateValue(
        "oauth",
        this.authUser.providerData.length > 1
      );
    }
  }

  async signUp(
    email: string,
    password: string,
    confirmPassword: string
  ): Promise<boolean> {
    if (!email || !password || !confirmPassword)
      return this.notificationService.error("error.auth.all-required", false);
    if (password.length < 6)
      return this.notificationService.error(
        "error.firebase.auth/weak-password",
        false
      );
    if (password != confirmPassword)
      return this.notificationService.error(
        "error.auth.password-mismatch",
        false
      );
    const [signUpErr, signInRes] = await to(
      createUserWithEmailAndPassword(this.auth, email, password)
    );
    if (signUpErr)
      return this.notificationService.error(
        `error.firebase.${signUpErr.code}`,
        false
      );
    return true;
  }

  async signIn(
    provider: AuthProvider,
    redirect: string,
    email?: string,
    password?: string
  ): Promise<boolean> {
    try {
      if (provider == AuthProvider.Email) {
        // Sign in with email
        console.log("[AuthService] Sign in with Email");
        if (!email || !password)
          return this.notificationService.error(
            "error.auth.credentials-required",
            false
          );

        const [signInErr, signInRes] = await to(
          signInWithEmailAndPassword(this.auth, email, password)
        );
        if (signInErr) throw signInErr;
        // gaLog('sign_in', { provider, userId: signInRes.user.uid });
        console.log("[AuthService] Signed in", signInRes.user.email);
        return true;
      } else {
        console.log("[AuthService] Sign in with Oauth");
        if (redirect != "/") {
          this.ngZone.run(() =>
            this.router.navigate(["redirect"], { queryParams: { redirect } })
          );
        } else {
          this.ngZone.run(() => this.router.navigate(["redirect"]));
        }
        // Sign in with Oauth
        const oauthProvider = this.toOauthProvider(provider);
        if (!oauthProvider)
          return this.notificationService.error(
            "error.auth.unknown-provider",
            false
          );
        const [signInErr, signInRes] = await to(
          signInWithRedirect(this.auth, oauthProvider)
        );
        if (signInErr) throw signInErr;
        return true;
      }
    } catch (err) {
      this.notificationService.error(`error.firebase.${err.code}`);
      this.ngZone.run(() =>
        this.router.navigate(["auth"], { queryParams: { redirect } })
      );
    }
  }

  async signOut() {
    console.log("[AuthService] signOut");
    this.reset();
    await to(firebaseSignOut(this.auth));
    environment.mobile
      ? (window.location.href = "#/auth/login")
      : (window.location.href = "auth/login");
  }

  toOauthProvider(provider: AuthProvider) {
    let oauthProvider;
    switch (provider) {
      case AuthProvider.Google:
        console.log("[AuthService] Sign in with Google");
        oauthProvider = new GoogleAuthProvider();
        oauthProvider.addScope("profile");
        // const scopes = environment.firebase.scopes;
        // scopes.forEach((scope) => oauthProvider.addScope(scope));
        break;
      case AuthProvider.Facebook:
        console.log("[AuthService] Sign in with Facebook");
        oauthProvider = new FacebookAuthProvider();
        // const scopes = environment.facebook.APP_SCOPE.split(',');
        // scopes.forEach(scope => oauthProvider.addScope(scope));
        break;
    }
    return oauthProvider;
  }

  async linkProvider(provider: AuthProvider) {
    const oauthProvider = this.toOauthProvider(provider);
    const [linkErr, linkRes] = await to(
      linkWithRedirect(this.auth.currentUser, oauthProvider)
    );
    if (linkErr) {
      return this.notificationService.error(
        `error.firebase.${linkErr.code}`,
        false
      );
    }
    this.updateOauthSetting();
    return true;
  }

  async unlinkProvider(provider: AuthProvider) {
    let providerId;
    switch (provider) {
      case AuthProvider.Google:
        providerId = "google.com";
        break;
      case AuthProvider.Facebook:
        providerId = "facebook.com";
        break;
    }
    const [unlinkErr, unlinkRes] = await to(
      unlink(this.auth.currentUser, providerId)
    );
    if (unlinkErr)
      return this.notificationService.error(
        `error.firebase.${unlinkErr.code}`,
        false
      );
    this.updateOauthSetting();
    return true;
  }

  async setPassword(password: string, confirmPassword: string) {
    if (password != confirmPassword)
      return this.notificationService.error(
        "error.auth.password-mismatch",
        false
      );
    const credential = EmailAuthProvider.credential(
      this.authUser.email,
      password
    );
    const [linkErr, linkRes] = await to(
      linkWithCredential(this.auth.currentUser, credential)
    );
    if (linkErr)
      if (linkErr.code == "auth/requires-recent-login") {
        this.notificationService.error(`error.firebase.${linkErr.code}`);
        return setTimeout((_) => this.signOut(), 2000);
      } else {
        return this.notificationService.error(
          `error.firebase.${linkErr.code}`,
          false
        );
      }
    return this.notificationService.success("auth.password-updated", true);
  }

  async changePassword(password: string, confirmPassword: string) {
    if (password != confirmPassword)
      return this.notificationService.error(
        "error.auth.password-mismatch",
        false
      );
    const [updateErr, updateRes] = await to(
      updatePassword(this.auth.currentUser, password)
    );
    if (updateErr)
      return this.notificationService.error(
        `error.firebase.${updateErr.code}`,
        false
      );
    return this.notificationService.success("auth.password-updated", true);
  }

  async getClientData(authUser: firebaseUser): Promise<Client> {
    if (!authUser.uid) return null;
    const clientRef = doc(this._collection, authUser.uid);
    const [docErr, docData] = await to(getDoc(clientRef));
    if (docErr) return this.notificationService.error("error.unknown", null);

    if (!docData.exists()) {
      const client = new Client({
        id: authUser.uid,
        users: [
          {
            id: authUser.uid,
            permission: ClientPermission.Admin,
            info: {
              email: authUser.email,
              displayName:
                authUser.displayName || authUser.email.split("@")[0] || "",
              phoneNumber: authUser.phoneNumber,
              photoURL: authUser.photoURL,
            },
          },
        ],
      });
      console.log(client);
      const [createErr, createRes] = await to(setDoc(clientRef, client.json));
      if (createErr)
        return this.notificationService.error("error.user.create", null);
    } else {
      const now = new Date().valueOf();
      const client = new Client(docData.data() as ClientParams);
      await to(setDoc(clientRef, client.json));
    }

    if (this.clientUnsub) this.clientUnsub();
    return new Promise((resolve) => {
      this.clientUnsub = onSnapshot(
        clientRef,
        (snapshot) => {
          if (!snapshot.exists()) return this.signOut();
          const client = new Client(snapshot.data() as ClientParams);
          if (!this.mobile) console.log(client);
          this.client = client;
          this._client$.next(client);
          resolve(client);
        },
        (error) => {
          this.notificationService.error("error.unknown");
          resolve(null);
        }
      );
    });
  }

  async sendVerificationEmail(user: firebaseUser): Promise<boolean> {
    if (!user || user.emailVerified) return false;
    // console.log('[AuthService] Sending Verification Email');
    const [err, sendRes] = await to(
      sendEmailVerification(this.auth.currentUser)
    );
    if (err && err.code != "auth/too-many-requests")
      return this.notificationService.error("error.auth.send-email", false);
    return this.notificationService.success("email.already-sent", true);
  }

  async sendPasswordResetEmail(email: string): Promise<boolean> {
    if (!email || email == "") return false;
    const [err, sendRes] = await to(sendPasswordResetEmail(this.auth, email));
    if (err && err.code != "auth/too-many-requests")
      return this.notificationService.error(
        `error.firebase.${err.code}`,
        false
      );
    return this.notificationService.success("email.already-sent", true);
  }

  get currentUser(): User {
    const i = this.client.users.findIndex(
      (user) => user.id == this.authUser.uid
    );
    if (i == -1) return this.notificationService.error("error.unknown", null);
    return this.client.users[i];
  }

  get isManager(): boolean {
    return this.currentUser.permission >= ClientPermission.Manager;
  }

  get isAdmin(): boolean {
    return this.currentUser.permission >= ClientPermission.Admin;
  }
}
