<div class="form">
  <!-- About -->
  <div class="form__section" translate>inbox.info.about</div>
  <div class="form__row">
    <div class="form__label">
      <i class="material-icons">account_box</i>
    </div>
    <div class="form__field">
      <input
        type="text"
        [(ngModel)]="customer.name"
        (change)="onChange()"
        [placeholder]="'inbox.info.name' | translate"
      />
    </div>
  </div>

  <div class="form__row">
    <div class="form__label">
      <i class="material-icons">call</i>
    </div>
    <div class="form__field">
      <input
        type="text"
        [(ngModel)]="customer.phoneNumber"
        (change)="onChange()"
        [placeholder]="'inbox.info.phone' | translate"
      />
    </div>
  </div>

  <div class="form__row">
    <div class="form__label">
      <i class="material-icons">email</i>
    </div>
    <div class="form__field">
      <input
        type="text"
        [(ngModel)]="customer.email"
        (change)="onChange()"
        [placeholder]="'inbox.info.email' | translate"
      />
    </div>
  </div>

  <div class="form__row">
    <div class="form__label">
      <i class="material-icons">today</i>
    </div>
    <div class="form__field">
      <span class="capitalize" translate>inbox.info.created</span>
      <span>{{ createdAt }}</span>
    </div>
  </div>

  <div class="actions">
    <ng-container *ngIf="changed">
      <button class="text-button bg-warn" (click)="discard()" translate>
        general.cancel
      </button>
      <button class="text-button bg-success" (click)="save()" translate>
        general.save
      </button>
    </ng-container>
  </div>
  <!-- About -->

  <!-- Tags -->
  <div class="form__section no-top-margin">
    <span translate>inbox.info.tags</span>
    <button class="text-button" (click)="manageTags()" translate>
      inbox.info.manage-tags
    </button>
  </div>
  <div class="tags">
    <ng-container *ngFor="let tagId of customer.tags; let i = index">
      <div
        class="tags__item"
        *ngIf="tagIdToTag(tagId) as tag"
        [style.backgroundColor]="tag.color"
      >
        <span>{{ tag.text }}</span>
        <a (click)="deleteTag(i)"><i class="material-icons">close</i></a>
      </div>
    </ng-container>
  </div>

  <button
    class="text-button add-tag"
    *ngIf="availableTags.length > 0"
    mat-button
    [matMenuTriggerFor]="tagMenu"
  >
    <i class="material-icons">add_circle</i>
    <span translate>inbox.info.add-tag</span>
  </button>
  <!-- Tags -->

  <!-- Notes -->
  <div class="form__section" translate>inbox.info.notes</div>
  <div class="notes">
    <div class="notes__item" *ngFor="let note of customer.notes; let i = index">
      <span>{{ note }}</span>
      <a (click)="deleteNote(i)"><i class="material-icons">close</i></a>
    </div>
  </div>

  <textarea
    class="add-note"
    placeholder="{{ 'placeholder.add-notes' | translate }}"
    [(ngModel)]="newNote"
    (keyup.enter)="addNote()"
    matInput
    cdkTextareaAutosize
    [cdkAutosizeMinRows]="2"
    [cdkAutosizeMaxRows]="5"
  ></textarea>
  <!-- Notes -->

  <!-- Attachments -->
  <!-- <div class="form__section" translate>inbox.info.attachments</div> -->
  <!-- Attachments -->
</div>

<mat-menu #tagMenu="matMenu" xPosition="after" yPosition="below">
  <div class="tags tags-wrapper">
    <button
      class="tags__item"
      *ngFor="let tag of availableTags"
      (click)="addTag(tag.tagId)"
      [style.backgroundColor]="tag.color"
    >
      {{ tag.text }}
    </button>
  </div>
</mat-menu>
