<div class="dialog column">
  <div class="dialog__title" translate>whatsapp.select-api</div>
  <div class="whatsapp">
    <div
      class="whatsapp__option"
      [class.checked]="selectedMode == WhatsappApiProvider.friday"
    >
      <input
        type="radio"
        [(ngModel)]="selectedMode"
        [value]="WhatsappApiProvider.friday"
        [id]="WhatsappApiProvider.friday"
      />
      <label [for]="WhatsappApiProvider.friday">
        <div><img src="assets/img/logo.png" alt="Friday" /></div>
        <p translate>whatsapp.title</p>
        <ul>
          <li translate>whatsapp.instruction.1</li>
          <li translate>whatsapp.instruction.2</li>
          <li>
            <i class="material-icons warn">warning</i>&nbsp;<span translate
              >whatsapp.instruction.3</span
            >
          </li>
          <li>
            <i class="material-icons warn">warning</i>&nbsp;<span translate
              >whatsapp.instruction.4</span
            >
          </li>
        </ul>
      </label>
    </div>

    <div
      class="whatsapp__option"
      [class.checked]="selectedMode == WhatsappApiProvider.twilio"
    >
      <input
        type="radio"
        [(ngModel)]="selectedMode"
        [value]="WhatsappApiProvider.twilio"
        [id]="WhatsappApiProvider.twilio"
      />
      <label [for]="WhatsappApiProvider.twilio">
        <img src="assets/img/twilio-logo.svg" alt="Twilio" />
        <p translate>twilio.title</p>
        <ul>
          <li translate>twilio.instruction.1</li>
          <li>
            <i class="material-icons warn">warning</i>&nbsp;<span translate
              >twilio.instruction.2</span
            >
          </li>
          <li>
            <i class="material-icons warn">warning</i>&nbsp;<span translate
              >twilio.instruction.3</span
            >
          </li>
          <li translate>twilio.instruction.4</li>
        </ul>
      </label>
    </div>
  </div>

  <div class="dialog__action row">
    <button class="text-button flat" mat-button (click)="close()" translate>
      general.cancel
    </button>
    <button
      class="text-button bg-success"
      mat-button
      (click)="submit()"
      translate
    >
      general.confirm
    </button>
  </div>
</div>
