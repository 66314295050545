<div class="dialog">
  <div class="dialog__title" translate>user.add-user</div>

  <div class="form">
    <div class="form__row">
      <div class="form__label">
        <i class="material-icons">email</i>
        <span translate>user.email</span>
      </div>
      <div class="form__field">
        <input type="email" [(ngModel)]="email" name="email" id="email" />
      </div>
    </div>

    <div class="form__row">
      <div class="form__label">
        <i class="material-icons">account_circle</i>
        <span translate>user.displayName</span>
      </div>
      <div class="form__field">
        <input type="text" [(ngModel)]="displayName" name="name" id="name" />
      </div>
    </div>

    <div class="form__row">
      <div class="form__label">
        <i class="material-icons">lock</i>
        <span translate>auth.password</span>
      </div>
      <div class="form__field">
        <input
          type="password"
          [(ngModel)]="password"
          [placeholder]="'auth.password-requirement' | translate"
        />
      </div>
    </div>

    <div class="form__row">
      <div class="form__label">
        <i class="material-icons">enhanced_encryption</i>
        <span translate>auth.confirm-password</span>
      </div>
      <div class="form__field">
        <input
          type="password"
          [(ngModel)]="confirmPassword"
          [placeholder]="'auth.password-requirement' | translate"
        />
      </div>
    </div>
  </div>

  <small class="error-message" *ngIf="error" translate>{{ error }}</small>

  <div class="dialog__action row">
    <button
      class="text-button flat"
      mat-button
      (click)="close()"
      [disabled]="loading"
      translate
    >
      general.cancel
    </button>

    <app-loader-button
      mat-button
      [success]="true"
      [disabled]="loading"
      [loading]="loading"
      (click)="createSubAccount()"
      icon="send"
      [translation]="'general.submit'"
    >
    </app-loader-button>
  </div>
</div>
