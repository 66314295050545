export enum Language {
  unknown = "unknown",
  sc = "zh",
  tc = "zh",

  // Baidu Language Codes
  yue = "zh",
  cht = "zh",
  jp = "ja", // Japan
  kor = "ko", // Korean
  vie = "vi", // Vietnam
  auto = "auto",
  // Baidu Language Codes

  // Google Language Code
  "zh-TW" = "zh",
  "zh-CN" = "zh",
  // Google Language Code

  zh = "zh", // Chinese
  en = "en", // English

  af = "af", // Afrikaans
  sq = "sq", // Albanian
  am = "am", // Amharic
  ar = "ar", // Arabic
  hy = "hy", // Armenian
  az = "az", // Azerbaijani
  eu = "eu", // Basque
  be = "be", // Belarusian
  bn = "bn", // Bengali
  bs = "bs", // Bosnian
  bg = "bg", // Bulgarian
  ca = "ca", // Catalan
  ceb = "ceb", // Cebuano
  ny = "ny", // Chichewa
  co = "co", // Corsican
  hr = "hr", // Croatian
  cs = "cs", // Czech
  da = "da", // Danish
  nl = "nl", // Dutch
  eo = "eo", // Esperanto
  et = "et", // Estonian
  tl = "tl", // Filipino
  fi = "fi", // Finnish
  fr = "fr", // French
  fy = "fy", // Frisian
  gl = "gl", // Galician
  ka = "ka", // Georgian
  de = "de", // German
  el = "el", // Greek
  gu = "gu", // Gujarati
  ht = "ht", // Haitian Creole
  ha = "ha", // Hausa
  haw = "haw", // Hawaiian
  iw = "iw", // Hebrew
  hi = "hi", // Hindi
  hmn = "hmn", // Hmong
  hu = "hu", // Hungarian
  is = "is", // Icelandic
  ig = "ig", // Igbo
  id = "id", // Indonesian
  ga = "ga", // Irish
  it = "it", // Italian
  ja = "ja", // Japanese
  jw = "jw", // Javanese
  kn = "kn", // Kannada
  kk = "kk", // Kazakh
  km = "km", // Khmer
  rw = "rw", // Kinyarwanda
  ko = "ko", // Korean
  ku = "ku", // Kurdish (Kurmanji)
  ky = "ky", // Kyrgyz
  lo = "lo", // Lao
  la = "la", // Latin
  lv = "lv", // Latvian
  lt = "lt", // Lithuanian
  lb = "lb", // Luxembourgish
  mk = "mk", // Macedonian
  mg = "mg", // Malagasy
  ms = "ms", // Malay
  ml = "ml", // Malayalam
  mt = "mt", // Maltese
  mi = "mi", // Maori
  mr = "mr", // Marathi
  mn = "mn", // Mongolian
  my = "my", // Myanmar (Burmese)
  ne = "ne", // Nepali
  no = "no", // Norwegian
  or = "or", // Odia (Oriya)
  ps = "ps", // Pashto
  fa = "fa", // Persian
  pl = "pl", // Polish
  pt = "pt", // Portuguese
  pa = "pa", // Punjabi
  ro = "ro", // Romanian
  ru = "ru", // Russian
  sm = "sm", // Samoan
  gd = "gd", // Scots Gaelic
  sr = "sr", // Serbian
  st = "st", // Sesotho
  sn = "sn", // Shona
  sd = "sd", // Sindhi
  si = "si", // Sinhala
  sk = "sk", // Slovak
  sl = "sl", // Slovenian
  so = "so", // Somali
  es = "es", // Spanish
  su = "su", // Sundanese
  sw = "sw", // Swahili
  sv = "sv", // Swedish
  tg = "tg", // Tajik
  ta = "ta", // Tamil
  tt = "tt", // Tatar
  te = "te", // Telugu
  th = "th", // Thai
  tr = "tr", // Turkish
  tk = "tk", // Turkmen
  uk = "uk", // Ukrainian
  ur = "ur", // Urdu
  ug = "ug", // Uyghur
  uz = "uz", // Uzbek
  vi = "vi", // Vietnamese
  cy = "cy", // Welsh
  xh = "xh", // Xhosa
  yi = "yi", // Yiddish
  yo = "yo", // Yoruba
  zu = "zu", // Zulu
  he = "he", // Hebrew
}

export const SupportedLanguages = [Language.en, Language.zh];
