import { Component, Inject, OnInit } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import {
  Channel,
  Conversation,
  ConversationParams,
  CustomerParams,
  FRIDAY_SUFFIX,
} from "@models/messenger";
import { REGEX_EMAIL, REGEX_PHONE } from "@models/misc";
import { exactMatchRegex } from "@utility";
import { AuthService } from "../../../services/auth.service";
import { ConversationService } from "../../../services/conversation.service";
import { ChannelSource } from "../../pages/inbox/inbox.component";

@Component({
  selector: "app-add-conversation",
  templateUrl: "./add-conversation.component.html",
  styleUrls: ["./add-conversation.component.scss"],
})
export class AddConversationComponent implements OnInit {
  customer: CustomerParams = {};
  error = {
    name: false,
    phone: false,
    email: false,
  };
  loading = false;
  countryCode = "852";
  number = "";
  me = "";

  constructor(
    private authService: AuthService,
    private conversationService: ConversationService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddConversationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ChannelSource[]
  ) {}

  ngOnInit() {
    this.me = this.data[0].id;
  }

  close() {
    this.dialogRef.close();
  }

  get selectedSource(): ChannelSource {
    return this.data.find((d) => d.id == this.me);
  }

  async startConversation() {
    if (!this.me) return false;
    this.error = {
      name: false,
      phone: false,
      email: false,
    };

    if (!this.customer.name) this.error.name = true;
    if (!this.countryCode || !this.number) this.error.phone = true;
    this.customer.phoneNumber = `${this.countryCode.trim()}${this.number.trim()}`;
    if (
      !this.customer.phoneNumber ||
      !exactMatchRegex(this.customer.phoneNumber, REGEX_PHONE)
    )
      this.error.phone = true;
    if (
      this.customer.email &&
      !exactMatchRegex(this.customer.email, REGEX_EMAIL)
    )
      this.error.email = true;

    if (this.error.email || this.error.name || this.error.phone) return false;

    this.loading = true;

    let conversationParams: ConversationParams = {
      clientId: this.authService.client.id,
      me: this.me,
      channel: Channel.WhatsApp,
      sourceId: this.customer.phoneNumber + FRIDAY_SUFFIX + "c.us",
      customer: this.customer,
    };
    const conversation = new Conversation(conversationParams);
    const success = await this.conversationService.startConversation(
      conversation
    );
    this.loading = false;
    // console.log(success);
    if (success) return this.close();
  }
}
