import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Conversation } from "@models/messenger";
import { animations } from "@shared";
import { Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { ConversationService } from "../../../services/conversation.service";

@Component({
  selector: "app-mobile-inbox-chatroom",
  templateUrl: "./mobile-inbox-chatroom.component.html",
  styleUrls: ["./mobile-inbox-chatroom.component.scss"],
  animations: animations,
})
export class MobileInboxChatroomComponent implements OnInit {
  conversation: Conversation = null;
  conversationSub: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private conversationService: ConversationService
  ) {}

  ngOnInit() {
    this.conversationSub = this.conversationService.conversationsChanges
      .pipe(debounceTime(1000))
      .subscribe((conversations) => {
        if (!conversations) return;
        this.activatedRoute.params.subscribe((params) => {
          const sourceId = params.sourceId;
          if (!sourceId) return;
          this.conversation =
            this.conversationService.getConversationBySourceId(sourceId);
        });
      });
  }
}
