import { Channel } from "../../../messenger";

export interface AutopilotSettingParams {
  active?: boolean;
  channels?: Channel[];
  threshold?: number;
}
export class AutopilotSetting {
  active: boolean;
  threshold: number;
  channels: Channel[];

  constructor(params: AutopilotSettingParams = {}) {
    this.active = params.active ?? false;
    this.channels = params.channels || [];
    this.threshold = params.threshold ?? 0.85;
  }
}
