import { hashShort, toJSON } from "../helper";
import { TextTranslation } from "../misc/translation";

export interface LivechatSettingParams {
  clientId: string;
  hash?: string;
  heading?: TextTranslation;
  description?: TextTranslation;
  urlWhitelist?: string[];
  themeColor?: string;
}

export class LivechatSetting {
  hash: string;
  clientId: string;
  heading: TextTranslation;
  description: TextTranslation;
  urlWhitelist: string[];
  themeColor?: string;

  constructor(params: LivechatSettingParams) {
    this.clientId = params.clientId;
    this.hash = params.hash || hashShort(this.clientId);
    this.heading = params.heading || {};
    this.description = params.description || {};
    this.urlWhitelist = params.urlWhitelist || [];
    this.themeColor = params.themeColor || "#38509c";
  }

  get json() {
    return toJSON(this);
  }
}
