import cronTest from "cron-test";
import moment from "moment-timezone";
import { Timezone } from "../../misc";

export enum TriggerConditionType {
  Always = "always",
  Once = "once",
  Period = "period",
}

export interface TriggerConditionParams {
  type?: TriggerConditionType;
  cron?: string;
  timezone?: Timezone;
}

export class TriggerCondition {
  type: TriggerConditionType;
  cron?: string;
  timezone?: Timezone;

  constructor(params: TriggerConditionParams) {
    this.type = params.type || TriggerConditionType.Always;
    if (this.type == TriggerConditionType.Period) {
      this.cron = params.cron || "*";
      this.timezone = params.timezone || Timezone["Asia/Hong_Kong"];
    }
  }

  setType(type: TriggerConditionType) {
    this.type = type;
    if (this.type == TriggerConditionType.Period) {
      this.cron = "*";
      this.timezone = Timezone["Asia/Hong_Kong"];
    } else {
      delete this.cron;
      delete this.timezone;
    }
  }

  get valid(): boolean {
    if (this.type != TriggerConditionType.Period) return true;
    if (!this.cron || !this.timezone) return false;
    try {
      const interval = cronTest.parseExpression(this.cron, {
        tz: this.timezone.toString(),
      });
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  get inTime(): boolean {
    if (this.type != TriggerConditionType.Period) return true;
    if (!this.timezone || !this.valid) return false;
    try {
      const interval = cronTest.parseExpression(this.cron, {
        tz: this.timezone.toString(),
      });
      const now = moment().tz(this.timezone);
      return interval.test(now);
    } catch (error) {
      console.log(error);
      return false;
    }
  }
}
