<div class="onboarding" *ngIf="clients">
  <div class="onboarding__wrapper">
    <div class="onboarding__header">
      <div class="logo">
        <img src="assets/img/logo-type.png" alt="friday a.i. logo" />
      </div>

      <button class="text-button flat" (click)="authService.signOut()">
        <i class="material-icons">exit_to_app</i>
        <span translate>auth.sign-out</span>
      </button>
    </div>

    <div class="onboarding__content">
      <div class="row" *ngIf="selectedClient">
        <button
          class="text-button bg-primary no-transform"
          mat-button
          [matMenuTriggerFor]="clientMenu"
        >
          {{
            selectedClient.organization || selectedClient.rootUser.info.email
          }}
        </button>

        <button
          class="text-button bg-success"
          [disabled]="!selectedClientId"
          (click)="signInAsClient()"
        >
          <i class="material-icons">arrow_forward</i>
          <span translate>general.confirm</span>
        </button>
      </div>
    </div>
  </div>

  <mat-menu #clientMenu="matMenu" xPosition="after" yPosition="below">
    <button
      class="no-transform"
      *ngFor="let client of clients"
      mat-menu-item
      (click)="selectClient(client.id)"
    >
      {{ client.organization || client.rootUser.info.email }}
    </button>
  </mat-menu>
</div>
<app-loader *ngIf="!clients"></app-loader>
