import { toJSON, unique } from "../../../helper";

export interface ChannelSettingTwilioParams {
  accountSid?: string;
  authToken?: string;
  whatsappNumbers?: string[];
}

export class ChannelSettingTwilio {
  accountSid: string;
  authToken: string;
  whatsappNumbers: string[];

  constructor(params: ChannelSettingTwilioParams = {}) {
    this.accountSid = params.accountSid || "";
    this.authToken = params.authToken || "";
    this.whatsappNumbers = unique(params.whatsappNumbers || []);
  }

  get json() {
    return toJSON(this);
  }
}
