import { Injectable } from "@angular/core";
import { Auth } from "@angular/fire/auth";
import { Language } from "@models/misc";
import { TranslateService } from "@ngx-translate/core";
import { CookieService } from "ngx-cookie-service";

export interface AppSetting {
  language: Language;
  darkmode?: boolean;
  oauth?: boolean;
  contactsWidth?: number;
  [key: string]: any;
}

const STORAGE = "friday-settings";

@Injectable({
  providedIn: "root",
})
export class SettingService {
  public settings: AppSetting;
  public translations: any = {};

  constructor(
    private auth: Auth,
    private translateService: TranslateService,
    private cookieService: CookieService
  ) {}

  initialize() {
    this.settings = this.getSettings();
    this.settings = this.saveSettings();
    // this.settings.darkmode
    //   ? document.documentElement.classList.add("dark-theme")
    //   : document.documentElement.classList.remove("dark-theme");
    this.setLanguage();
    if (this.settings.contactsWidth) {
      document.body.style.setProperty(
        "--contactsWidth",
        this.settings.contactsWidth + "px"
      );
    }
  }

  getSettings() {
    let settingStr = window.localStorage.getItem(STORAGE);
    if (!settingStr && this.cookieService.check(STORAGE))
      settingStr = JSON.parse(this.cookieService.get(STORAGE));

    try {
      if (!settingStr) throw "Setting Unset";
      this.settings = JSON.parse(settingStr) as AppSetting;
    } catch (e) {
      this.settings = {
        language: Language.en,
        darkmode: false,
        oauth: false,
      };
      this.saveSettings();
    }
    // console.log(this.settings);
    return this.settings;
  }

  saveSettings() {
    window.localStorage.setItem(STORAGE, JSON.stringify(this.settings));
    this.cookieService.set(
      STORAGE,
      JSON.stringify(this.settings),
      365,
      null,
      null,
      true,
      "Strict"
    );
    return this.settings;
  }

  getValue(key: string) {
    if (!this.settings) return null;
    return this.settings[key] || null;
  }

  updateValue(key: string, value: any) {
    this.settings[key] = value;
    if (!value) delete this.settings[key];
    return this.saveSettings();
  }

  deleteKey(key: string) {
    delete this.settings[key];
    return this.saveSettings();
  }

  setLanguage(language?: Language) {
    if (language) this.settings.language = language;
    switch (this.settings.language) {
      case Language.zh:
        this.auth.languageCode = "zh-HK";
        break;
      case Language.en:
        this.auth.languageCode = "en";
        break;
    }
    this.translateService.setDefaultLang(this.settings.language);
    this.translateService.use(this.settings.language);
    this.translateService.getTranslation(this.settings.language).subscribe(
      (translations) => (this.translations = translations),
      (err) => console.dir(err, { depth: null })
    );
    document.documentElement.lang = this.settings.language;
    this.saveSettings();
    return this.settings.language;
  }

  toggleDarkmode() {
    this.settings.darkmode = !this.settings.darkmode;
    // this.settings.darkmode
    //   ? document.documentElement.classList.add("dark-theme")
    //   : document.documentElement.classList.remove("dark-theme");
    this.saveSettings();
    return this.settings.darkmode;
  }

  // toggleSidebar() {
  //   this.settings.compact = !this.settings.compact;
  //   this.saveSettings();
  //   return this.settings.compact;
  // }

  resizeChatlist(width: number) {
    this.settings.contactsWidth = width;
    document.body.style.setProperty(
      "--contactsWidth",
      this.settings.contactsWidth + "px"
    );
    this.saveSettings();
    return this.settings.contactsWidth;
  }
}
