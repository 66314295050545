<div class="dialog">
  <div class="dialog__title" translate>dialog.new-conversation</div>
  <div class="remark" translate>inbox.not-allow-new-conversation</div>

  <div class="form">
    <div class="form__row">
      <div class="form__label">
        <i class="material-icons">campaign</i>
        <span translate>general.channel</span>
      </div>
      <div class="form__field">
        <button class="text-button" disabled>
          <img src="assets/img/whatsapp-logo.svg" class="round-corner" />
          <span translate>channel.whatsapp</span>
        </button>
      </div>
    </div>

    <div class="form__row">
      <div class="form__label">
        <i class="material-icons">phone_forwarded</i>
        <span translate>general.from</span>
      </div>

      <div class="form__field">
        <button
          class="text-button"
          mat-button
          [matMenuTriggerFor]="channelSourceMenu"
        >
          <img [src]="selectedSource.picture" class="round-corner" />
          <span>{{ selectedSource.name }}</span>
        </button>
      </div>
    </div>

    <div class="form__row">
      <div class="form__label">
        <i class="material-icons">account_box</i>
        <span translate>inbox.info.name</span>
      </div>
      <div class="form__field">
        <input
          type="text"
          [(ngModel)]="customer.name"
          placeholder="{{ 'placeholder.required' | translate }}"
        />
        <small class="error-message" *ngIf="error?.name" translate
          >error.invalid.required</small
        >
      </div>
    </div>

    <div class="form__row">
      <div class="form__label">
        <i class="material-icons">call</i>
        <span translate>inbox.info.phone</span>
      </div>
      <div class="form__field">
        <div class="phone">
          <span>+</span>
          <input
            type="text"
            class="country-code"
            [(ngModel)]="countryCode"
            placeholder="852"
          />
          <span>-</span>
          <input
            type="text"
            class="number"
            [(ngModel)]="number"
            placeholder="98765432"
          />
        </div>

        <small class="error-message" *ngIf="error?.phone" translate
          >error.invalid.phone</small
        >
      </div>
    </div>

    <div class="form__row">
      <div class="form__label">
        <i class="material-icons">email</i>
        <span translate>inbox.info.email</span>
      </div>
      <div class="form__field">
        <input type="text" [(ngModel)]="customer.email" />
        <small class="error-message" *ngIf="error?.email" translate
          >error.invalid.email</small
        >
      </div>
    </div>
  </div>

  <div class="dialog__action row">
    <button
      class="text-button flat"
      mat-button
      (click)="close()"
      [disabled]="loading"
      translate
    >
      general.cancel
    </button>

    <app-loader-button
      mat-button
      [success]="true"
      [disabled]="loading"
      [loading]="loading"
      (click)="startConversation()"
      icon="send"
      [translation]="'general.submit'"
    >
    </app-loader-button>
  </div>

  <mat-menu #channelSourceMenu="matMenu" xPosition="after" yPosition="below">
    <button
      class="text-button"
      mat-menu-item
      *ngFor="let source of data"
      (click)="me = source.id"
    >
      <img [src]="source.picture" class="round-corner" />
      <span>{{ source.name }}</span>
    </button>
  </mat-menu>
</div>
