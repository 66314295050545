export const environment = {
  production: false,
  mobile: false,
  livechatEndpoint: "https://livechat-dev.getfriday.ai",
  FRIDAY_API_TOKEN: "automagicbot",
  gapi: {
    scopes: [
      "https://www.googleapis.com/auth/drive.file",
      "https://www.googleapis.com/auth/spreadsheets",
    ],
    discoveryDocs: [
      "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest",
      "https://sheets.googleapis.com/$discovery/rest?version=v4",
    ],
  },
  graphAPI: {
    API_VERSION: "v14.0",
    APP_SCOPE:
      "public_profile,email,pages_messaging,pages_show_list,pages_manage_metadata,pages_read_user_content,pages_read_engagement,pages_manage_engagement",
    APP_SUBSCRIBED_FIELDS:
      "feed,messages,messaging_postbacks,message_deliveries,message_reads,message_echoes,message_reactions,whatsapp_messages",
    INSTAGRAM_SCOPE: "instagram_manage_messages,instagram_manage_comments",
    WHATSAPP_SCOPE: "business_management,whatsapp_business_management",
  },
};
