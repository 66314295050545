<div class="auth__form__row column">
  <label translate>auth.email</label>
  <input type="email" [(ngModel)]="email" />
</div>

<div class="auth__form__row column">
  <label
    ><span translate>auth.password</span> (<span translate
      >auth.password-requirement</span
    >)</label
  >
  <input type="password" [(ngModel)]="password" />
</div>

<div class="auth__form__row column">
  <label translate>auth.confirm-password</label>
  <input
    type="password"
    [(ngModel)]="confirmPassword"
    (keydown.enter)="signUp()"
  />
</div>

<button
  class="text-button bg-primary gradient-dark"
  (click)="signUp()"
  translate
  [disabled]="
    email == '' ||
    password == '' ||
    confirmPassword == '' ||
    password != confirmPassword
  "
>
  auth.sign-up
</button>

<div class="instruction">
  <div class="row">
    <span translate>auth.have-account</span>
    <a [routerLink]="['/auth/login']" translate>auth.sign-in</a>
  </div>
</div>
