<div class="chat-item row" *ngIf="conversation" [class.active]="active">
  <div class="user-icon" [style.background-image]="profilePicture"></div>

  <div class="chat-info">
    <div class="customer-info">
      <div class="name">
        {{
          conversation.customer.displayName || ("inbox.customer" | translate)
        }}
      </div>
    </div>
    <div class="chat-preview" *ngIf="lastMessage">
      <ng-container *ngIf="lastMessage.senderId != conversation.customer.id">
        <i class="material-icons" *ngIf="!lastMessage.deliveredAt">done</i>
        <i class="material-icons success" *ngIf="lastMessage.deliveredAt"
          >done_all</i
        >
      </ng-container>
      <span>{{ lastMessage.preview }}</span>
    </div>
  </div>

  <div class="chat-status">
    <div class="unread-indicator" *ngIf="conversation.unread"></div>
    <img [src]="channelIcon" />
  </div>

  <div class="tags">
    <div
      class="tag-indicator"
      *ngFor="let tag of tags"
      [style.backgroundColor]="tag.color"
      [matTooltip]="tag.text"
      [matTooltipPosition]="'above'"
    ></div>
  </div>
</div>
