import { Component, OnDestroy, OnInit } from "@angular/core";
import { Channel } from "@models/messenger";
import { Client } from "@models/user";
import { animations, NotificationService } from "@shared";
import { toJSON, unique } from "@utility";
import { cloneDeep } from "lodash";
import { Subscription } from "rxjs";
import { AuthService } from "../../../../services/auth.service";
import { ClientService } from "../../../../services/client.service";

@Component({
  selector: "app-ai",
  templateUrl: "./ai.component.html",
  styleUrls: ["./ai.component.scss"],
  animations: animations,
})
export class AiComponent implements OnInit, OnDestroy {
  clientSub: Subscription;
  client: Client;
  clientCopy: Client;
  edited = false;
  saving = false;
  channels: Channel[];
  initialized = false;
  isManager = false;

  constructor(
    private authService: AuthService,
    private clientService: ClientService,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.isManager = this.authService.isManager;
    this.clientSub = this.authService.clientChanges.subscribe((client) => {
      this.client = client;
      this.clientCopy = cloneDeep(client);
      this.channels = this.client.settings.conversation.channels;
      if (!this.initialized && this.client.settings.autopilot.active) {
        this.client.settings.autopilot.channels =
          this.client.settings.autopilot.channels || this.channels;
        this.save(true);
      }
      this.initialized = true;
    });
  }

  ngOnDestroy() {
    this.reset();
  }

  reset() {
    this.client.settings.autopilot.threshold =
      this.clientCopy.settings.autopilot.threshold;
    this.client.settings.autopilot.active =
      !!this.clientCopy.settings.autopilot.active;
    this.client.settings.autopilot.channels =
      this.clientCopy.settings.autopilot.channels;

    if (this.clientSub) this.clientSub.unsubscribe();
  }

  toggleAutoreply() {
    this.client.settings.autopilot.channels = this.client.settings.autopilot
      .active
      ? this.channels
      : [];
  }

  toggleChannel(checked: boolean, i: number) {
    // console.log(checked, i);
    if (checked) {
      this.client.settings.autopilot.channels = unique([
        ...this.client.settings.autopilot.channels,
        ...[this.channels[i]],
      ]);
    } else {
      this.client.settings.autopilot.channels =
        this.client.settings.autopilot.channels.filter(
          (c) => c != this.channels[i]
        );
    }

    this.client.settings.autopilot.active =
      this.client.settings.autopilot.channels.length > 0 ? true : false;
  }

  async save(silent = false) {
    this.saving = true;
    console.log(this.client.settings);
    const succeed = await this.clientService.updateClient({
      settings: toJSON(this.client.settings),
    });
    if (succeed && !silent)
      this.notificationService.success("status.save.saved");
    this.saving = false;
  }
}
