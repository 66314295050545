import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { Language, SupportedLanguages } from "@models/misc";
import { User } from "@models/user";
import { Subscription } from "rxjs";
import { SettingService } from "../../services/setting.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit, OnDestroy {
  @Input() routes: any[];
  @Input() authService: any;
  user: User;
  languages: Language[];
  language: Language;
  expanded = false;
  clientSub: Subscription;

  constructor(
    private router: Router,
    private sanitization: DomSanitizer,
    private settingService: SettingService
  ) {}

  ngOnInit() {
    this.clientSub = this.authService.clientChanges.subscribe((client) => {
      this.user = this.authService.currentUser;
    });
    this.language = this.settingService.settings.language;
    this.languages = [
      ...[this.language],
      ...SupportedLanguages.filter((l) => l != this.language),
    ];
    // this.compact = this.settingService.settings.compact;
  }

  ngOnDestroy() {
    if (this.clientSub) this.clientSub.unsubscribe();
  }

  setLanguage(language: Language) {
    this.language = this.settingService.setLanguage(language);
    this.languages = [
      ...[this.language],
      ...SupportedLanguages.filter((l) => l != this.language),
    ];
  }

  toggleDarkmode() {
    return this.settingService.toggleDarkmode();
  }

  isActiveRoute(url: string) {
    return this.router.url.indexOf(url) != -1;
  }

  userPhoto() {
    return this.sanitization.bypassSecurityTrustStyle(
      `url(${this.user.info.photoURL})`
    );
  }

  signOut() {
    return this.authService.signOut();
  }

  expand() {
    this.expanded = true;
  }

  collapse() {
    this.expanded = false;
  }
}
