export const UPLOAD_SIZE_LIMIT = 25000000;

export enum AttachmentType {
  audio = "audio",
  video = "video",
  image = "image",
  file = "file",
  fallback = "fallback",
}

export interface Attachment {
  id: string;
  type: AttachmentType;
  url?: string;
  name?: string;
  location?: string;
  fbAttachmentId?: string;
  data?: string;
  mimetype?: string;
  size?: number;
}

export const toAttachmentType = (contentType: string): AttachmentType => {
  if (!contentType) return AttachmentType.file;
  return contentType.includes("image")
    ? AttachmentType.image
    : contentType.includes("video")
    ? AttachmentType.video
    : contentType.includes("audio")
    ? AttachmentType.audio
    : AttachmentType.file;
};
