import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MessageTrigger, Trigger, TriggerEntrypoint } from "@models/autopilot";
import { Client } from "@models/user";
import { Subscription } from "rxjs";
import { AuthService } from "../../../../../services/auth.service";

@Component({
  selector: "app-edit-trigger",
  templateUrl: "./edit-trigger.component.html",
  styleUrls: ["./edit-trigger.component.scss"],
})
export class EditTriggerComponent implements OnInit, OnDestroy {
  triggerId: string;
  trigger: Trigger;
  client: Client;
  clientSub: Subscription;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService
  ) {
    const triggerId = this.route.snapshot.paramMap.get("triggerId");
    this.clientSub = this.authService.clientChanges.subscribe((client) => {
      this.client = client;
      this.trigger = new MessageTrigger({ clientId: this.client.id });
      this.triggerId = this.trigger.id;

      this.trigger.addEntrypoint(new TriggerEntrypoint());
      console.log(this.trigger);
    });

    // triggerId
    //   ? (this.triggerId = triggerId)
    //   : this.router.navigate(["/autopilot/trigger"]);
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    if (this.clientSub) this.clientSub.unsubscribe();
  }
}
