import { Component, NgZone, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Language, SupportedLanguages } from "@models/misc";
import { SettingService } from "@shared";
import { Subscription } from "rxjs";
import { environment } from "../../../../environments/environment";
import { AuthProvider, AuthService } from "../../../services/auth.service";

@Component({
  selector: "app-auth",
  templateUrl: "./auth.component.html",
  styleUrls: ["./auth.component.scss"],
})
export class AuthComponent implements OnInit, OnDestroy {
  AuthProvider: typeof AuthProvider = AuthProvider;
  authSub: Subscription;
  languages: Language[];
  language: Language;

  email: string;
  password: string;
  confirmPassword: string;
  redirect: string;
  loading = false;
  mobile = !!environment.mobile;

  constructor(
    private authService: AuthService,
    private router: Router,
    private ngZone: NgZone,
    public settingService: SettingService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.redirect = this.activatedRoute.snapshot.queryParams.redirect || "/";

    this.language = this.settingService.settings.language;
    this.languages = [
      ...[this.language],
      ...SupportedLanguages.filter((l) => l != this.language),
    ];

    this.authSub = this.authService.authUserChanges.subscribe((authUser) => {
      if (authUser) {
        console.log("Already signed in. Redirecting to", this.redirect);
        this.ngZone.run(() => this.router.navigate([`..${this.redirect}`]));
      }
    });
  }

  ngOnDestroy() {
    if (this.authSub) this.authSub.unsubscribe();
  }

  setLanguage(language: Language) {
    this.language = this.settingService.setLanguage(language);
  }
}
