import { Pipe, PipeTransform } from "@angular/core";
import { Conversation } from "@models/messenger";

@Pipe({
  name: "chatSearch",
})
export class ChatSearchPipe implements PipeTransform {
  transform(value: Conversation[], ...args: any[]): Conversation[] {
    if (!value || value.length == 0) return [];
    const conversations = value;
    let searchText = args[0];
    if (!searchText) return conversations;
    searchText = searchText.toLowerCase();

    const search = conversations.map((c) => {
      return {
        conversationId: c.id,
        content: JSON.stringify({
          channel: c.channel,
          customer: c.customer,
          messages: c.messages.filter((m) => m.text).map((m) => m.text),
        }).toLowerCase(),
      };
    });

    const targetIds = search
      .filter((s) => s.content.includes(searchText))
      .map((s) => s.conversationId);

    // console.log(targetIds);
    return conversations.filter((c) => targetIds.includes(c.id));
  }
}
