import { toJSON, unique } from "../../../helper";

export interface Instagram {
  id: string;
  igId?: string;
  name?: string;
  username: string;
  profilePictureUrl?: string;

  followersCount?: number;
  biography?: string;
  followsCount?: string;
  mediaCount?: string;
  website?: string;
}

export interface FacebookPage {
  id: string;
  accessToken: string;
  name?: string;
  profilePic?: string;
  instagram?: Instagram;
}

export interface ChannelSettingFacebookParams {
  userId?: string;
  userAccessToken?: string;
  pages?: FacebookPage[];
}

export class ChannelSettingFacebook {
  userId: string;
  userAccessToken: string;
  pageIds: string[];
  pages: FacebookPage[];
  instagramIds: string[];

  constructor(params: ChannelSettingFacebookParams = {}) {
    this.userId = params.userId || "";
    this.userAccessToken = params.userAccessToken || "";
    this.pages = params.pages || [];
    this.pageIds = unique(this.pages.map((page) => page.id || ""));
    this.instagramIds = unique(
      this.pages.map((page) => page.instagram?.id || "")
    );
  }

  get json() {
    return toJSON(this);
  }

  page(id: string): FacebookPage | null {
    return (
      this.pages.find((page) => page.id == id || page.instagram?.id == id) ||
      null
    );
  }

  instagram(id: string): Instagram | null {
    return (
      this.pages.find((page) => page.instagram?.id == id)?.instagram || null
    );
  }
}
