<button
  *ngIf="!icon"
  class="text-button"
  [disabled]="disabled"
  [class.bg-success]="success"
  [class.bg-primary]="primary"
  [class.bg-warn]="warn"
  [class.bg-error]="error"
  [class.bg-black]="black"
  mat-button
  [matMenuTriggerFor]="emoji"
  (click)="initialize()"
>
  <i class="material-icons">insert_emoticon</i>
</button>

<button
  class="icon-button"
  *ngIf="icon"
  mat-button
  [disabled]="disabled"
  [class.success]="success"
  [class.primary]="primary"
  [class.warn]="warn"
  [class.error]="error"
  [class.black]="black"
  [matMenuTriggerFor]="emoji"
  (click)="initialize()"
>
  <i class="material-icons">insert_emoticon</i>
</button>

<mat-menu class="emoji" #emoji="matMenu" xPosition="after" yPosition="below">
  <emoji-mart
    [showPreview]="false"
    [emojiTooltip]="false"
    [darkMode]="false"
    [i18n]="emojiTranslations"
    (emojiClick)="emojiClick($event)"
  ></emoji-mart>
</mat-menu>
