import { Pipe, PipeTransform } from "@angular/core";
import { Conversation } from "@models/messenger";

@Pipe({
  name: "chatFilter",
})
export class ChatFilterPipe implements PipeTransform {
  transform(value: Conversation[], ...args: any[]): Conversation[] {
    if (!value || value.length == 0) return [];
    const conversations = value;
    const filterTagIds: string[] = args[0];
    if (!filterTagIds || filterTagIds.length == 0) return conversations;
    return conversations.filter((c) =>
      c.customer.tags.some((tagId) => filterTagIds.includes(tagId))
    );
  }
}
