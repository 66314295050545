<div class="navbar">
  <a mat-button [matMenuTriggerFor]="languageMenu">
    <i class="material-icons">language</i>
  </a>

  <a (click)="signOut()">
    <i class="material-icons">exit_to_app</i>
  </a>
</div>

<mat-menu #languageMenu="matMenu" xPosition="before" yPosition="below">
  <button class="center language" *ngFor="let language of languages" mat-menu-item (click)="setLanguage(language)">
    {{'general.'+language | translate}}
  </button>
</mat-menu>