import { Pipe, PipeTransform } from "@angular/core";
import { Conversation } from "@models/messenger";

@Pipe({
  name: "conversationId",
})
export class ConversationIdPipe implements PipeTransform {
  transform(value: Conversation[], ...args: any[]): Conversation {
    const conversations = value;
    const conversationId = args[0];
    // console.log(conversationId);
    if (conversations?.length == 0) return null;
    if (!conversationId) return conversations[0];
    const i = conversations.findIndex((c) => c.id == conversationId);
    return i != -1 ? conversations[i] : conversations[0];
  }
}
