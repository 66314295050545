import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Channel } from "@models/messenger";
import { Client } from "@models/user";
import { animations } from "@shared";
import { Subscription } from "rxjs";
import { AuthService } from "../../../services/auth.service";

@Component({
  selector: "app-channel",
  templateUrl: "./channel.component.html",
  styleUrls: ["./channel.component.scss"],
  animations: animations,
})
export class ChannelComponent implements OnInit {
  selectedChannel: Channel;
  clientSub: Subscription;
  client: Client;

  constructor(
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe((params) => {
      this.selectedChannel = (params.get("channel") as Channel) || Channel.Web;
    });

    this.clientSub = this.authService.clientChanges.subscribe(
      (client) => (this.client = client)
    );
  }

  isActiveRoute(url: string) {
    return this.router.url.indexOf(url) != -1;
  }
}
