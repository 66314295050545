<div class="message__text">
  <app-message-header
    [message]="message"
    [previousSenderName]="previousSenderName"
  ></app-message-header>
  <span>{{ message.text }} </span>
  <!-- <span *ngIf="html" [innerHTML]="html"> </span> -->
  <span class="no-select">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
  <span class="no-select" *ngIf="message.senderId != sourceId"
    >&nbsp;&nbsp;&nbsp;&nbsp;</span
  >

  <div class="message__timestamp">
    <ng-container *ngIf="timestamp">{{ timestamp }}</ng-container>
    <ng-container *ngIf="message.senderId != sourceId">
      <ng-container *ngIf="message.senderId != FRIDAY_ID">
        <i class="material-icons" *ngIf="!message.deliveredAt">done</i>
        <i class="material-icons" *ngIf="message.deliveredAt">done_all</i>
      </ng-container>

      <img
        *ngIf="message.senderId == FRIDAY_ID"
        src="assets/img/logo-white.png"
      />
    </ng-container>
  </div>
</div>
