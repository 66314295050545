import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  Attachment,
  Channel,
  Conversation,
  Message,
  WhatsappProvider,
} from "@models/messenger";
import { SettingService } from "@shared";
import { to, unique } from "@utility";
import { BehaviorSubject, debounceTime } from "rxjs";
import { environment } from "../../../../../../environments/environment";
import { ApiService } from "../../../../../services/api.service";
import { AuthService } from "../../../../../services/auth.service";
import { ConversationService } from "../../../../../services/conversation.service";
import { StorageService } from "../../../../../services/storage.service";

@Component({
  selector: "app-chatroom-input",
  templateUrl: "./chatroom-input.component.html",
  styleUrls: ["./chatroom-input.component.scss"],
})
export class ChatroomInputComponent implements OnInit, OnDestroy, OnChanges {
  @Input() conversation: Conversation;
  @ViewChild("upload") uploadElement: ElementRef;
  @ViewChild("input") inputElement: ElementRef;

  Channel: typeof Channel = Channel;
  WhatsappProvider: typeof WhatsappProvider = WhatsappProvider;
  mobile = !!environment.mobile;

  messageText = "";
  messageAttachments: Attachment[] = [];
  uploads: File[] = [];
  sending = false;
  uploading = false;
  emojiTranslations: any;
  adminView = false;
  typingIds: string[] = [];
  replyTimeLimit = 7 * 24 * 3600000;
  private _typing$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    public conversationService: ConversationService,
    private apiService: ApiService,
    private authService: AuthService,
    private settingService: SettingService,
    private storageService: StorageService
  ) {}

  ngOnInit() {
    this.adminView = this.authService.isSuperAdmin;
    this.emojiTranslations = this.settingService.translations.emoji;
    this.initialize();
    this.cacheInput();
  }

  ngOnDestroy() {
    this.initialize();
    this.typingOff();
  }

  ngOnChanges(changes) {
    const conversationChange = changes.conversation;
    if (!conversationChange || conversationChange.firstChange) return;
    if (
      conversationChange.currentValue.id == conversationChange.previousValue.id
    )
      return;
    this.initialize();
    this.typingOff();
    this.cacheInput();
  }

  get disconnected(): boolean {
    const client = this.authService.client;
    return !(
      client.channels.facebook.pageIds.includes(this.conversation.me) ||
      client.channels.facebook.instagramIds.includes(this.conversation.me) ||
      client.channels.twilio.whatsappNumbers.includes(this.conversation.me) ||
      client.channels.fridayWhatsapp.instances.some(
        (i) => i.whatsappId == this.conversation.me
      )
    );
  }

  get allowReply(): boolean {
    if (
      ![Channel.Facebook, Channel.WhatsApp, Channel.Instagram].includes(
        this.conversation.channel
      )
    )
      return true;

    if (
      this.conversation.channel == Channel.WhatsApp &&
      this.conversation.whatsappProvider != WhatsappProvider.Twilio
    )
      return true;

    const incomingMessages = this.conversation.messages.filter(
      (m) => m.senderId == this.conversation.sourceId
    );
    if (incomingMessages.length == 0) return false;
    return (
      new Date().valueOf() -
        incomingMessages[incomingMessages.length - 1].timestamp <
      this.replyTimeLimit
    );
  }

  cacheInput() {
    const input = this.settingService.getValue(this.conversation.id);
    if (input) {
      this.messageText = input;
      setTimeout(() => {
        if (this.inputElement?.nativeElement)
          this.inputElement?.nativeElement.focus();
      }, 0);
    } else {
      this.settingService.deleteKey(this.conversation.id);
    }
  }

  initialize() {
    this.messageText = "";
    if (this.uploadElement?.nativeElement)
      this.uploadElement.nativeElement.value = "";
    this.messageAttachments = [];
    this.uploads = [];
    this.sending = false;
    this.uploading = false;
    this._typing$
      .pipe(debounceTime(1000))
      .subscribe((typing) =>
        typing
          ? this.conversationService.typingOn(this.conversation.id)
          : this.conversationService.typingOff(this.conversation.id)
      );
  }

  async sendMessage() {
    if (!this.messageText && this.uploads.length == 0) return;
    this.sending = true;
    const text = this.messageText;
    this.messageText = "";

    if (this.uploads.length > 0) {
      this.messageAttachments =
        await this.storageService.uploadMessageAttachments(
          this.conversation.id,
          this.uploads
        );
    }

    const message = new Message({
      clientId: this.authService.client.id,
      channel: this.conversation.channel,
      senderId: this.authService.client.id,
      text,
      attachments: this.messageAttachments,
    });
    // console.log(message);

    const succeed = await this.apiService.sendMessage(
      this.conversation.sourceId,
      message,
      this.conversation.me,
      this.conversation.channel
    );
    // console.log(succeed);
    if (!succeed) await this.discardUploads();
    this.settingService.deleteKey(this.conversation.id);
    this.initialize();
  }

  uploadFile() {
    this.uploadElement.nativeElement.click();
  }

  async onFileChange(event: any) {
    console.log(event.target.files, this.uploads);
    this.uploading = true;
    if (event.target.files && event.target.files.length > 0) {
      const files: File[] = event.target.files;
      this.uploads = [...this.uploads, ...files];
      this.uploadElement.nativeElement.value = "";
      console.log(this.uploads);
    }
    this.uploading = false;
  }

  async discardUploads() {
    console.log("discardUploads", this.messageAttachments.length);
    if (this.messageAttachments.length == 0) return true;
    console.log(`Deleting ${this.messageAttachments.length} attachments`);
    await to(this.storageService.batchDelete(this.messageAttachments));
    this.messageAttachments = [];
    return true;
  }

  async deleteFile(i: number) {
    this.uploads.splice(i, 1);
    console.log(this.uploads);
  }

  insertEmoji(event: any) {
    this.messageText += event.emoji.native || "";
    this.saveInput();
    event.$event.stopPropagation();
  }

  typingOn() {
    return this._typing$.next(true);
  }

  typingOff() {
    return this._typing$.next(false);
  }

  typingUsers() {
    const users = unique(
      this.typingIds.map((id) => {
        return (
          this.authService.client.users.find((user) => user.id == id)
            ?.displayName || ""
        );
      })
    );

    return users.reduce(
      (acc, cur, i) => (acc += cur + (i < users.length - 1 ? ", " : "")),
      ""
    );
  }

  saveInput() {
    // console.log(this.messageText);
    this.settingService.updateValue(this.conversation.id, this.messageText);
  }
}
