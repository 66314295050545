import { Component, Input, OnChanges, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Conversation, Customer } from "@models/messenger";
import { ConversationTag } from "@models/user";
import { formatDate, formatFullDate } from "@utility";
import { cloneDeep } from "lodash";
import { Subscription } from "rxjs";
import { AuthService } from "../../../../../services/auth.service";
import { ConversationService } from "../../../../../services/conversation.service";
import { ManageTagsComponent } from "../../../../dialogs/manage-tags/manage-tags.component";

@Component({
  selector: "app-chatroom-info",
  templateUrl: "./chatroom-info.component.html",
  styleUrls: ["./chatroom-info.component.scss"],
})
export class ChatroomInfoComponent implements OnInit, OnChanges, OnDestroy {
  @Input() conversation: Conversation;
  customer: Customer;
  customerCopy: Customer;
  changed = false;
  initialized = false;
  newNote = "";
  tags: ConversationTag[] = [];
  availableTags: ConversationTag[] = [];
  clientSub: Subscription;

  constructor(
    private authService: AuthService,
    private conversationService: ConversationService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.clientSub = this.authService.clientChanges.subscribe((client) => {
      this.tags = client.settings.conversation.tags || [];
      this.initialized = true;
      this.updateTags();
    });
  }

  async ngOnDestroy() {
    if (this.clientSub) this.clientSub.unsubscribe();
    await this.save();
  }

  ngOnChanges() {
    this.initialize();
    this.updateTags();
  }

  initialize() {
    this.customer = this.conversation.customer;
    this.customerCopy = cloneDeep(this.conversation.customer);
    const d = formatDate(new Date(this.conversation.createdAt));
    this.newNote = "";
  }

  updateTags() {
    // console.log(this.tags);
    if (this.initialized)
      this.customer.tags = this.customer.tags.filter((tagId) =>
        this.tags.some((t) => t.tagId == tagId)
      );

    this.availableTags = this.tags.filter(
      (t) => !this.customer.tags.some((tagId) => t.tagId == tagId)
    );
  }

  onChange() {
    this.changed = true;
  }

  get createdAt() {
    return formatFullDate(this.conversation.createdAt);
  }

  async save() {
    if (!this.changed) return true;
    console.log(this.customer);
    this.changed = false;
    await this.conversationService.update(
      this.conversation.id,
      {
        customer: this.customer,
      },
      false,
      true
    );
  }

  discard() {
    this.customer = new Customer(this.customerCopy);
    // console.log(this.customer);
    this.changed = false;
  }

  addNote() {
    this.newNote = this.newNote.trim();
    if (!this.newNote) return;
    this.customer.notes.push(this.newNote);
    this.newNote = "";
    this.changed = true;
    this.save();
  }

  deleteNote(i: number) {
    this.customer.notes.splice(i, 1);
    this.changed = true;
    this.save();
  }

  manageTags() {
    return this.dialog.open(ManageTagsComponent);
  }

  addTag(tagId: string) {
    this.customer.tags.push(tagId);
    this.changed = true;
    this.save();
    this.updateTags();
  }

  tagIdToTag(tagId: string) {
    return this.tags.find((t) => t.tagId == tagId) || null;
  }

  deleteTag(i: number) {
    this.customer.tags.splice(i, 1);
    this.changed = true;
    this.save();
    this.updateTags();
  }
}
