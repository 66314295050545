<div class="onboarding" *ngIf="authUser">
  <div class="onboarding__wrapper">
    <div class="onboarding__header">
      <div class="logo">
        <img src="assets/img/logo-type.png" alt="friday a.i. logo">
      </div>

      <button class="text-button flat" (click)="authService.signOut()">
        <i class="material-icons">exit_to_app</i>
        <span translate>auth.sign-out</span>
      </button>
    </div>

    <div class="onboarding__content">
      <h1 translate>email.sent-email</h1>
      <h1 translate>email.click-to-activate</h1>

      <p translate>email.redirect</p>
      <p>
        <span translate>email.not-receive</span>
        <a class="primary" (click)="sendAgain()" translate>email.send-again</a>
      </p>

    </div>

  </div>
</div>
<app-loader *ngIf="!authUser"></app-loader>
