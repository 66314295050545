<div class="dialog">
  <div class="dialog__title capitalize" translate>
    general.conversation-tags
  </div>

  <div class="column center">
    <div class="tag-item">
      <input
        class="colorpicker tag-color"
        [(colorPicker)]="newColor"
        [style.background]="newColor"
        [cpOutputFormat]="'hex'"
        readonly
      />

      <input
        class="tag-name new"
        type="text"
        [(ngModel)]="newTag"
        placeholder="{{ 'placeholder.new-tag' | translate }}"
        (keyup.enter)="addTag()"
      />

      <button
        class="icon-button success"
        [disabled]="!newTag || loading"
        (click)="addTag()"
      >
        <i class="material-icons">add</i>
      </button>
    </div>
    <small class="error-message" *ngIf="error" translate>{{ error }}</small>
  </div>

  <div class="divider"></div>

  <div class="tags">
    <div class="tag-item" *ngFor="let tag of tags; let i = index">
      <input
        class="colorpicker tag-color"
        [(colorPicker)]="tag.color"
        [style.background]="tag.color"
        [cpOutputFormat]="'hex'"
        readonly
      />

      <input
        class="tag-name"
        type="text"
        [(ngModel)]="tag.text"
        [style.borderColor]="tag.color"
      />

      <button class="icon-button error" (click)="deleteTag(i)">
        <i class="material-icons">close</i>
      </button>
    </div>
  </div>

  <div class="dialog__action row">
    <button class="text-button flat" (click)="close()" translate>
      general.close
    </button>
  </div>
</div>
