import { Component, NgZone, OnDestroy, OnInit } from "@angular/core";
import { User } from "@angular/fire/auth";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { AuthService } from "../../../../services/auth.service";
@Component({
  selector: "app-verification",
  templateUrl: "./verification.component.html",
  styleUrls: ["./verification.component.scss"],
})
export class VerificationComponent implements OnInit, OnDestroy {
  refreshUserInterval: any;
  sent = false;
  authSub: Subscription;
  authUser: User;
  interval = 5000;

  constructor(
    public authService: AuthService,
    private router: Router,
    private ngZone: NgZone
  ) {}

  ngOnInit() {
    this.authSub = this.authService.authUserChanges.subscribe(
      async (authUser) => {
        // console.log('user changed');
        this.authUser = authUser;
        if (authUser) {
          if (authUser.emailVerified) {
            this.ngZone.run(() => this.router.navigate(["dashboard"]));
          } else {
            if (!this.sent) {
              const sent = await this.authService.sendVerificationEmail(
                authUser
              );
              if (sent) {
                this.sent = true;
                this.refreshUserInterval = setInterval(() => {
                  console.log("[verificationEmailComponent] Refresh User");
                  this.authService.refreshUser();
                }, this.interval);
              }
            }
          }
        }
      }
    );
  }

  ngOnDestroy() {
    clearInterval(this.refreshUserInterval);
    if (this.authSub) this.authSub.unsubscribe();
  }

  async sendAgain() {
    if (!this.authUser) return false;
    await this.authService.sendVerificationEmail(this.authUser);
  }
}
