import { DragDropModule } from "@angular/cdk/drag-drop";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { TextFieldModule } from "@angular/cdk/text-field";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatBadgeModule } from "@angular/material/badge";
import { MatCheckboxModule } from "@angular/material/checkbox";
import {
  MatDialogModule,
  MAT_DIALOG_DEFAULT_OPTIONS,
} from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSliderModule } from "@angular/material/slider";
import {
  MatSnackBarModule,
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
} from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule, MAT_TABS_CONFIG } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import {
  BrowserModule,
  HammerGestureConfig,
  HammerModule,
  HAMMER_GESTURE_CONFIG,
} from "@angular/platform-browser";
// Angular Material Modules
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { PickerModule } from "@ctrl/ngx-emoji-mart";
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { SharedModule } from "@shared";
import { QRCodeModule } from "angularx-qrcode";
import { ColorPickerModule } from "ngx-color-picker";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { MobileContainerComponent } from "./mobile/mobile-container/mobile-container.component";
import { MobileInboxChatroomComponent } from "./mobile/mobile-inbox/mobile-inbox-chatroom/mobile-inbox-chatroom.component";
import { MobileInboxComponent } from "./mobile/mobile-inbox/mobile-inbox.component";
import { ChannelPipe } from "./pipes/channel.pipe";
import { ChatFilterPipe } from "./pipes/chat-filter.pipe";
import { ChatSearchPipe } from "./pipes/chat-search.pipe";
import { ConversationIdPipe } from "./pipes/conversationId.pipe";
import { ApiService } from "./services/api.service";
import { AuthService } from "./services/auth.service";
import { ClientService } from "./services/client.service";
import { ConversationService } from "./services/conversation.service";
import { ContainerComponent } from "./ui/container/container.component";
import { AddConversationComponent } from "./ui/dialogs/add-conversation/add-conversation.component";
import { AddUserComponent } from "./ui/dialogs/add-user/add-user.component";
import { FbPageSelectorComponent } from "./ui/dialogs/fb-page-selector/fb-page-selector.component";
import { FbPostSelectorComponent } from "./ui/dialogs/fb-post-selector/fb-post-selector.component";
import { ManageTagsComponent } from "./ui/dialogs/manage-tags/manage-tags.component";
import { WhatsappApiSelectorComponent } from "./ui/dialogs/whatsapp-api-selector/whatsapp-api-selector.component";
import { GoogleOauthComponent } from "./ui/oauth/google-oauth/google-oauth.component";
import { AdminComponent } from "./ui/pages/admin/admin.component";
import { AuthComponent } from "./ui/pages/auth/auth.component";
import { ResetComponent } from "./ui/pages/auth/reset/reset.component";
import { SigninComponent } from "./ui/pages/auth/signin/signin.component";
import { SignupComponent } from "./ui/pages/auth/signup/signup.component";
import { ChannelComponent } from "./ui/pages/channel/channel.component";
import { FacebookComponent } from "./ui/pages/channel/facebook/facebook.component";
import { LivechatComponent } from "./ui/pages/channel/livechat/livechat.component";
import { FridayComponent } from "./ui/pages/channel/whatsapp/friday/friday.component";
import { TwilioComponent } from "./ui/pages/channel/whatsapp/twilio/twilio.component";
import { DashboardComponent } from "./ui/pages/dashboard/dashboard.component";
import { ChatroomContentComponent } from "./ui/pages/inbox/chatroom/chatroom-content/chatroom-content.component";
import { MessageAttachmentComponent } from "./ui/pages/inbox/chatroom/chatroom-content/message-item/message-attachment/message-attachment.component";
import { MessageItemComponent } from "./ui/pages/inbox/chatroom/chatroom-content/message-item/message-item.component";
import { MessageTextComponent } from "./ui/pages/inbox/chatroom/chatroom-content/message-item/message-text/message-text.component";
import { ChatroomHeaderComponent } from "./ui/pages/inbox/chatroom/chatroom-header/chatroom-header.component";
import { ChatroomInfoComponent } from "./ui/pages/inbox/chatroom/chatroom-info/chatroom-info.component";
import { ChatroomInputComponent } from "./ui/pages/inbox/chatroom/chatroom-input/chatroom-input.component";
import { ChatroomComponent } from "./ui/pages/inbox/chatroom/chatroom.component";
import { ContactItemComponent } from "./ui/pages/inbox/contact-item/contact-item.component";
import { InboxComponent } from "./ui/pages/inbox/inbox.component";
import { RedirectComponent } from "./ui/pages/redirect/redirect.component";
import { AccountComponent } from "./ui/pages/setting/account/account.component";
import { AiComponent } from "./ui/pages/setting/ai/ai.component";
import { OrganizationComponent } from "./ui/pages/setting/organization/organization.component";
import { SettingComponent } from "./ui/pages/setting/setting.component";
// import { TaskComponent } from "./ui/task/task.component";
import { VerificationComponent } from "./ui/pages/auth/verification/verification.component";
import { AutopilotComponent } from "./ui/pages/autopilot/autopilot.component";
// import { OnboardingComponent } from "./ui/onboarding/onboarding.component";
// import { ChatflowComponent } from "./ui/chatflow/chatflow.component";
// import { ChatformResponseComponent } from "./ui/chatform/chatform-response/chatform-response.component";
// import { ChatformComponent } from "./ui/chatform/chatform.component";
// import { EditChatformItemComponent } from "./ui/chatform/edit-chatform/edit-chatform-item/edit-chatform-item.component";
// import { EditChatformComponent } from "./ui/chatform/edit-chatform/edit-chatform.component";
import { initializeApp, provideFirebaseApp } from "@angular/fire/app";
import { getAuth, provideAuth } from "@angular/fire/auth";
import { getFirestore, provideFirestore } from "@angular/fire/firestore";
import { environment } from "../environments/environment";
import { ChatflowComponent } from "./ui/pages/autopilot/chatflow/chatflow.component";
import { EditTriggerComponent } from "./ui/pages/autopilot/trigger/edit-trigger/edit-trigger.component";
import { TriggerEntrypointComponent } from "./ui/pages/autopilot/trigger/edit-trigger/trigger-entrypoint/trigger-entrypoint.component";
import { TriggerItemComponent } from "./ui/pages/autopilot/trigger/edit-trigger/trigger-item/trigger-item.component";
import { TriggerComponent } from "./ui/pages/autopilot/trigger/trigger.component";
import { WhatsappComponent } from "./ui/pages/channel/whatsapp/whatsapp.component";
import { MessageHeaderComponent } from "./ui/pages/inbox/chatroom/chatroom-content/message-item/message-header/message-header.component";

export const HttpLoaderFactory = (http: HttpClient) => {
  return new TranslateHttpLoader(http);
};

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    ContainerComponent,
    // OnboardingComponent,
    VerificationComponent,
    // KnowledgePipe,
    // KnowledgeComponent,
    // KnowledgeItemComponent,
    // AddKnowledgeComponent,
    MessageItemComponent,
    InboxComponent,
    FbPageSelectorComponent,
    ChannelPipe,
    ChatroomComponent,
    SettingComponent,
    AccountComponent,
    AiComponent,
    RedirectComponent,
    // TaskComponent,
    DashboardComponent,
    WhatsappApiSelectorComponent,
    ChannelComponent,
    LivechatComponent,
    FridayComponent,
    TwilioComponent,
    FacebookComponent,
    GoogleOauthComponent,
    FbPostSelectorComponent,
    ContactItemComponent,
    ChatroomHeaderComponent,
    AdminComponent,
    OrganizationComponent,
    AddUserComponent,
    ChatroomInfoComponent,
    ManageTagsComponent,
    ChatSearchPipe,
    ChatFilterPipe,
    AddConversationComponent,
    SignupComponent,
    SigninComponent,
    ResetComponent,
    MessageTextComponent,
    MessageAttachmentComponent,
    ChatroomContentComponent,
    ChatroomInputComponent,
    ConversationIdPipe,
    MobileInboxComponent,
    MobileContainerComponent,
    MobileInboxChatroomComponent,
    AutopilotComponent,

    TriggerItemComponent,
    TriggerComponent,
    ChatflowComponent,
    EditTriggerComponent,
    TriggerEntrypointComponent,
    MessageHeaderComponent,
    WhatsappComponent,
    // ChatformComponent,
    // EditChatformComponent,
    // EditChatformItemComponent,
    // ChatformResponseComponent,
    // ChatflowComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    MatBadgeModule,
    MatMenuModule,
    MatSidenavModule,
    MatTooltipModule,
    MatTabsModule,
    MatDialogModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    MatSliderModule,
    ColorPickerModule,
    QRCodeModule,
    DragDropModule,
    TextFieldModule,
    ScrollingModule,
    MatCheckboxModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    PickerModule,
    HammerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    SharedModule.forRoot(),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
  ],
  providers: [
    AuthService,
    ClientService,
    // KnowledgeService,
    ApiService,
    ConversationService,
    TranslateService,
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 5000 } },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } },
    { provide: MAT_TABS_CONFIG, useValue: { animationDuration: "200ms" } },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerGestureConfig,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
