import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Channel } from "@models/messenger";
import { Client, FacebookPage } from "@models/user";
import { TranslateService } from "@ngx-translate/core";
import { animations, ConfirmationDialogComponent } from "@shared";
import { to } from "@utility";
import { Subscription } from "rxjs";
import { AuthService } from "../../../../services/auth.service";
import { ClientService } from "../../../../services/client.service";
import { FacebookService } from "../../../../services/facebook.service";

@Component({
  selector: "app-facebook",
  templateUrl: "./facebook.component.html",
  styleUrls: ["./facebook.component.scss"],
  animations: animations,
})
export class FacebookComponent implements OnInit, OnDestroy {
  client: Client;
  clientSub: Subscription;
  pages: FacebookPage[] = [];
  loading = false;
  adminView = false;
  isManager = false;
  Channel: typeof Channel = Channel;

  constructor(
    private authService: AuthService,
    private clientService: ClientService,
    public dialog: MatDialog,
    private translateService: TranslateService,
    private facebookService: FacebookService
  ) {}

  ngOnInit() {
    this.adminView = this.authService.isSuperAdmin;
    this.isManager = this.authService.isManager;
    this.clientSub = this.authService.clientChanges.subscribe(
      async (client) => {
        if (!client) return;
        this.client = client;
        if (this.client.channels.facebook)
          this.pages = this.client.channels.facebook.pages;
        console.log(this.pages);
      }
    );
  }

  ngOnDestroy() {
    if (this.clientSub) this.clientSub.unsubscribe();
  }

  async connectPage() {
    this.loading = true;
    const [err, done] = await to(this.facebookService.connectFacebook());
    if (err || !done) {
      this.loading = false;
    } else {
      setTimeout((_) => (this.loading = false), 500);
    }
  }

  async connectInstagram(pageId: string) {
    this.loading = true;
    const [err, done] = await to(this.facebookService.connectInstagram(pageId));
    if (err || !done) {
      this.loading = false;
    } else {
      setTimeout((_) => (this.loading = false), 500);
    }
  }

  removePage(page: FacebookPage) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        messageHTML: `<small class="warning"><i class="material-icons">warning</i>${this.translateService.instant(
          "dialog.warning.cannot-undone"
        )}</small> <div>${this.translateService.instant(
          "dialog.warning.confirm-remove"
        )}<span class="highlight">${page.name || page.id}
        </span>?\n ${this.translateService.instant(
          "dialog.warning.remove-facebook-page"
        )}</div> `,
        destructive: true,
      },
    });

    dialogRef.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        const unsubscribed = await this.facebookService.unsubscribeApp(page.id);
        if (!unsubscribed) return false;

        let emptyChannel = true;
        this.client.channels.facebook.pages =
          this.client.channels.facebook.pages.filter((c) => c.id != page.id);

        let update: any = {
          channels: this.client.channels,
        };

        if (this.client.channels.facebook.pages.length > 0)
          emptyChannel = false;

        await this.clientService.updateClient(update);

        if (emptyChannel)
          await this.clientService.removeChannel(Channel.Facebook);
      }
    });
  }

  removeInstagram(page: FacebookPage) {
    if (!page.instagram) return true;
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        messageHTML: `<small class="warning"><i class="material-icons">warning</i>${this.translateService.instant(
          "dialog.warning.cannot-undone"
        )}</small> <div>${this.translateService.instant(
          "dialog.warning.confirm-remove"
        )}<span class="highlight">${
          page.instagram.username || page.instagram.name || page.instagram.id
        }
        </span>? </div> `,
        destructive: true,
      },
    });

    dialogRef.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        let emptyChannel = true;
        const i = this.client.channels.facebook.pages.findIndex(
          (_page) => _page.id == page.id
        );
        if (i == -1) return false;
        delete this.client.channels.facebook.pages[i].instagram;

        let update: any = {
          "channels.facebook.pages": this.client.channels.facebook.pages,
        };

        if (
          this.client.channels.facebook.pages.filter((p) => p.instagram)
            .length > 0
        )
          emptyChannel = false;

        await this.clientService.updateClient(update);

        if (emptyChannel)
          await this.clientService.removeChannel(Channel.Instagram);
      }
    });
  }

  async signUpWhatsapp() {
    return await to(this.facebookService.signUpWhatsapp());
  }
}
