import { Component, Input, OnInit } from "@angular/core";
import { Message } from "@models/messenger";

@Component({
  selector: "app-message-header",
  templateUrl: "./message-header.component.html",
  styleUrls: ["./message-header.component.scss"],
})
export class MessageHeaderComponent implements OnInit {
  @Input() message: Message;
  @Input() previousSenderName: string;

  constructor() {}

  ngOnInit(): void {}
}
