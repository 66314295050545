import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { User as firebaseUser } from "@angular/fire/auth";
import { DomSanitizer } from "@angular/platform-browser";
import { Client, User } from "@models/user";
import { animations, NotificationService, SettingService } from "@shared";
import { cloneDeep } from "lodash";
import { Subscription } from "rxjs";
import { environment } from "../../../../../environments/environment";
import { AuthProvider, AuthService } from "../../../../services/auth.service";
import { ClientService } from "../../../../services/client.service";
import { StorageService } from "../../../../services/storage.service";

@Component({
  selector: "app-account",
  templateUrl: "./account.component.html",
  styleUrls: ["./account.component.scss"],
  animations: animations,
})
export class AccountComponent implements OnInit, OnDestroy {
  @ViewChild("upload") uploadElement: ElementRef;
  clientSub: Subscription;
  client: Client;
  clientCopy: Client;
  user: User;
  userCopy: User;
  authUser: firebaseUser;
  authSub: Subscription;
  showEditPhoto = false;
  editName = false;
  editOrganization = false;
  setPassword = false;
  editPassword = false;
  edited = false;
  saving = false;
  providerIds: string[];
  AuthProvider: typeof AuthProvider = AuthProvider;
  password = "";
  confirmPassword = "";
  darkmode: boolean;
  adminView = false;
  isSubAccount = false;
  mobile = !!environment.mobile;
  isManager = false;

  constructor(
    private authService: AuthService,
    private sanitization: DomSanitizer,
    private clientService: ClientService,
    private storageService: StorageService,
    private notificationService: NotificationService,
    private settingService: SettingService
  ) {}

  ngOnInit() {
    this.isManager = this.authService.isManager;
    this.clientSub = this.authService.clientChanges.subscribe((client) => {
      if (client) {
        this.client = client;
        this.clientCopy = cloneDeep(client);
        this.user = this.authService.currentUser;
        this.userCopy = cloneDeep(this.user);
        // console.log(this.user.info);
      }
    });

    this.authSub = this.authService.authUserChanges.subscribe((authUser) => {
      this.authUser = authUser;
      this.providerIds = this.authUser.providerData.map((p) => p.providerId);
    });
    this.darkmode = this.settingService.settings.darkmode;
    this.adminView = this.authService.isSuperAdmin;
    this.isSubAccount = this.authService.isSubAccount;
  }

  ngOnDestroy() {
    this.reset();
    if (this.clientSub) this.clientSub.unsubscribe();
    if (this.authSub) this.authSub.unsubscribe();
  }

  isLinked(provider: AuthProvider) {
    return this.providerIds.some((p) => p == provider);
  }

  async linkProvider(provider: AuthProvider) {
    const succeed = await this.authService.linkProvider(provider);
    if (succeed) this.providerIds.push(provider);
  }

  async unlinkProvider(provider: AuthProvider) {
    const succeed = await this.authService.unlinkProvider(provider);
    if (succeed)
      this.providerIds = this.providerIds.filter((p) => p != provider);
  }

  userPhoto() {
    return this.sanitization.bypassSecurityTrustStyle(
      `url(${this.user.info.photoURL})`
    );
  }

  reset() {
    this.client.organization = this.clientCopy.organization || "";
    this.user.info.displayName = this.userCopy.info.displayName || "";
    this.editName = false;
    this.editOrganization = false;
    this.password = "";
    this.confirmPassword = "";
  }

  async changePassword() {
    if (
      !this.password ||
      !this.confirmPassword ||
      this.password != this.confirmPassword
    )
      return false;
    this.isLinked(AuthProvider.Email)
      ? await this.authService.changePassword(
          this.password,
          this.confirmPassword
        )
      : await this.authService.setPassword(this.password, this.confirmPassword);
    this.password = "";
    this.confirmPassword = "";
    this.editPassword = false;
  }

  async save() {
    this.saving = true;
    const update: any = { users: cloneDeep(this.client.users) };
    if (this.client.organization != this.clientCopy.organization)
      update.organization = this.client.organization;

    const succeed = await this.clientService.updateClient(update);
    if (succeed) this.notificationService.success("status.save.saved");

    this.editName = false;
    this.editOrganization = false;
    this.saving = false;
  }

  uploadUserPhoto() {
    this.uploadElement.nativeElement.click();
  }

  async onFileChange(event) {
    if (!event.target.files) return false;
    const file = event.target.files[0];
    const url = await this.storageService.uploadUserPhoto(file);
    if (!url) return false;
    this.user.info.photoURL = url;
    this.uploadElement.nativeElement.value = "";
    return this.save();
  }

  toggleDarkmode() {
    this.darkmode = this.settingService.toggleDarkmode();
  }

  removeNotificationDevice(i: number) {
    this.user.notification.splice(i, 1);
    return this.save();
  }
}
