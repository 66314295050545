import { Injectable, NgZone } from "@angular/core";
import {
  arrayRemove,
  arrayUnion,
  collection,
  collectionGroup,
  CollectionReference,
  doc,
  Firestore,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  runTransaction,
  updateDoc,
  where,
  writeBatch,
} from "@angular/fire/firestore";
import { MatDialog } from "@angular/material/dialog";
import { DomSanitizer, SafeStyle } from "@angular/platform-browser";
import { Router } from "@angular/router";
import {
  Channel,
  Conversation,
  ConversationParams,
  Message,
  MessageParams,
  MESSAGE_LOAD_LIMIT,
} from "@models/messenger";
import { Client } from "@models/user";
import { TranslateService } from "@ngx-translate/core";
import { ConfirmationDialogComponent, NotificationService } from "@shared";
import { addClass, removeClass, to, toJSON } from "@utility";
import { pullAt } from "lodash";
import { BehaviorSubject } from "rxjs";
import { WhatsappApiSelectorComponent } from "../ui/dialogs/whatsapp-api-selector/whatsapp-api-selector.component";
import { ApiService } from "./api.service";
import { AuthService } from "./auth.service";
import { FacebookService } from "./facebook.service";

export const CONVERSATION_COLLECTION = "conversations";
export const MESSAGE_COLLECTION = "messages";

@Injectable({
  providedIn: "root",
})
export class ConversationService {
  private _client: Client;
  private _collection: CollectionReference;

  private _conversationsChanges$: BehaviorSubject<Conversation[]> =
    new BehaviorSubject(null);
  private conversationsUnsub: any;

  public conversationsChanges = this._conversationsChanges$.asObservable();
  public conversations: Conversation[] = null;

  private messagesUnsub: any;
  private conversationLimit = 100;
  private messageLimit = 3000;

  private messagesInitialized = false;
  private conversationsInitialized = false;

  constructor(
    private firestore: Firestore,
    private authService: AuthService,
    private notificationService: NotificationService,
    private apiService: ApiService,
    private facebookService: FacebookService,
    private translateService: TranslateService,
    private sanitization: DomSanitizer,
    public dialog: MatDialog,
    private router: Router,
    private ngZone: NgZone
  ) {
    this._collection = collection(this.firestore, CONVERSATION_COLLECTION);
    this.authService.clientChanges.subscribe((client) => {
      if (!client) return this.reset();
      if (!this._client) {
        this._client = client;
        return this.listenConversations();
      }
      if (
        this._client.settings.conversation.channels.length !=
        client.settings.conversation.channels.length
      ) {
        this._client = client;
        return this.listenConversations(true);
      }
    });
  }

  reset() {
    if (this.conversationsUnsub) this.conversationsUnsub();
    this._conversationsChanges$.next(null);
    this.conversations = null;
    this._client = null;
  }

  listenMessages() {
    if (this.messagesInitialized) return;
    if (this.messagesUnsub) this.messagesUnsub();
    const messagesQuery = query(
      collectionGroup(this.firestore, MESSAGE_COLLECTION),
      where("clientId", "==", this._client.id),
      orderBy("timestamp", "desc"),
      limit(this.messageLimit)
    );

    this.messagesUnsub = onSnapshot(
      messagesQuery,
      (snapshot) => {
        // console.log("New message:", snapshot.size);
        snapshot.docChanges().forEach((change) => {
          const conversationId = change.doc.ref.parent.parent.id;
          const conversationIdx = this.conversations.findIndex(
            (c) => c.id == conversationId
          );
          if (conversationIdx == -1) return;
          const _message = new Message(change.doc.data() as MessageParams);
          switch (change.type) {
            case "added": {
              this.conversations[conversationIdx].messages.push(_message);
              // console.log(this.conversations[conversationIdx].messages);
              break;
            }
            case "modified": {
              // console.log(this.conversations[conversationIdx].messages);
              const i = this.conversations[conversationIdx].messages.findIndex(
                (m) => m.id == _message.id
              );
              i == -1
                ? this.conversations[conversationIdx].messages.push(_message)
                : (this.conversations[conversationIdx].messages[i] = _message);
              break;
            }
            case "removed": {
              const i = this.conversations[conversationIdx].messages.findIndex(
                (m) => m.id == _message.id
              );
              if (i != -1)
                pullAt(this.conversations[conversationIdx].messages, i);
            }
          }
        });

        for (let i = 0, n = this.conversations.length; i < n; i++) {
          this.conversations[i].messages = this.sortMessages(
            this.conversations[i].messages
          );
        }
        this.messagesInitialized = true;
        // console.log(this.conversations);
        this._conversationsChanges$.next(this.conversations);
      },
      (error) => {
        console.error("Message Listener", error);
        this.notificationService.error("error.conversation.get");
      }
    );
  }

  listenConversations(reset = false) {
    if (this.conversationsInitialized && !reset) return;
    if (this._client.settings.conversation.channels.length == 0) {
      this.conversations = [];
      this.conversationsInitialized = true;
      this._conversationsChanges$.next(this.conversations);
      return true;
    }
    const conversationsQuery = query(
      this._collection,
      where("clientId", "==", this._client.id),
      where("hidden", "==", false),
      where("channel", "in", this._client.settings.conversation.channels),
      orderBy("updatedAt", "desc")
    );
    // .limit(this.conversationLimit);

    if (this.conversationsUnsub) this.conversationsUnsub();
    this.conversationsUnsub = onSnapshot(
      conversationsQuery,
      (snapshot) => {
        if (!this.conversations) this.conversations = [];
        snapshot.docChanges().forEach((change) => {
          // console.log(change.doc.data());
          const _conversation = new Conversation(
            change.doc.data() as ConversationParams
          );
          // console.log(_conversation);
          switch (change.type) {
            case "added": {
              this.conversations.push(_conversation);
              break;
            }
            case "modified": {
              const i = this.conversations.findIndex(
                (c) => c.id == _conversation.id
              );
              if (i == -1) {
                this.conversations.push(_conversation);
              } else {
                _conversation.messages = this.conversations[i].messages;
                this.conversations[i] = _conversation;
              }
              break;
            }
            case "removed": {
              const i = this.conversations.findIndex(
                (c) => c.id == _conversation.id
              );
              if (i != -1) pullAt(this.conversations, i);
            }
          }
        });
        this.conversations.sort((a, b) => b.updatedAt - a.updatedAt);
        // console.log(this.conversations);
        this.conversationsInitialized = true;
        this.listenMessages();
        if (this.messagesInitialized)
          this._conversationsChanges$.next(this.conversations);
      },
      (error) => {
        console.log("Conversation Listener", error);
        this.notificationService.error("error.conversation.get");
      }
    );
  }

  sortMessages(messages: Message[]) {
    for (let m = 0, l = messages.length; m < l; m++) {
      const _message = messages[m];
      if (_message.attachments) messages[m].timestamp += 0.1;
      if (_message.replyTo) {
        const k = messages.findIndex((_m) => _m.id == _message.replyTo);
        if (k != -1) {
          messages[m].timestamp = messages[k].timestamp + 0.2;
        }
      }
    }
    return messages.sort((a, b) => a.timestamp - b.timestamp);
  }

  getConversationBySourceId(sourceId: string) {
    // console.log(this.conversations);
    if (!this.conversations || this.conversations.length == 0 || !sourceId)
      return null;
    const i = this.conversations.findIndex((c) => c.sourceId == sourceId);
    return i == -1 ? null : this.conversations[i];
  }

  async getOlderMessages(
    conversationId: string,
    before?: number
  ): Promise<boolean> {
    before = Math.round(before || new Date().valueOf());
    try {
      const i = this.conversations.findIndex((c) => c.id == conversationId);
      if (i == -1) throw "unknown conversation";
      const snapshot = await getDocs(
        query(
          collection(doc(this._collection, conversationId), MESSAGE_COLLECTION),
          where("timestamp", "<", before),
          orderBy("timestamp", "desc"),
          limit(MESSAGE_LOAD_LIMIT)
        )
      );

      const messages = snapshot.docs
        .map((doc) => new Message(doc.data() as MessageParams))
        .sort((a, b) => a.timestamp - b.timestamp);
      console.log(messages);
      console.log(this.conversations[i].messages);

      this.conversations[i].messages = this.sortMessages([
        ...messages,
        ...this.conversations[i].messages,
      ]);

      this._conversationsChanges$.next(this.conversations);
      return messages.length < 100;
    } catch (error) {
      return this.notificationService.error("error.conversation.get", true);
    }
  }

  async update(
    conversationId: string,
    data: any = {},
    isArray = false,
    silent = false
  ): Promise<boolean> {
    data = silent ? data : { ...data, ...{ updatedAt: new Date().valueOf() } };
    // console.log(`Update conversation: ${conversationId}`, update);
    const [err] = await to(
      updateDoc(
        doc(this._collection, conversationId),
        isArray ? data : toJSON(data)
      )
    );
    if (err && !silent)
      return this.notificationService.error("error.data.update", false);
    return true;
  }

  markRead(conversationId: string): Promise<boolean> {
    return this.update(conversationId, { unread: false }, false, true);
  }

  markUnread(conversationId: string): Promise<boolean> {
    return this.update(conversationId, { unread: true }, false, true);
  }

  archive(conversationId: string): Promise<boolean> {
    return this.update(conversationId, { archived: true });
  }

  unarchive(conversationId: string): Promise<boolean> {
    return this.update(conversationId, { archived: false });
  }

  typingOn(conversationId: string): Promise<boolean> {
    return this.update(
      conversationId,
      {
        typing: arrayUnion(this.authService.currentUser.id),
      },
      true,
      true
    );
  }

  typingOff(conversationId: string): Promise<boolean> {
    return this.update(
      conversationId,
      {
        typing: arrayRemove(this.authService.currentUser.id),
      },
      true,
      true
    );
  }

  async allTypingOff() {
    const [err, snapshots] = await to(
      getDocs(
        query(
          this._collection,
          where("typing", "array-contains", this.authService.currentUser.id)
        )
      )
    );
    if (err || snapshots.empty) return false;
    const batch = writeBatch(this.firestore);
    snapshots.docs.forEach((doc) => {
      batch.update(doc.ref, {
        typing: arrayRemove(this.authService.currentUser.id),
      });
    });
    await to(batch.commit());
    // if (!batchErr) console.log("All typing off");
    return true;
  }

  async delete(conversationId: string, customerDisplayName: string) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        messageHTML: `<small class="warning"><i class="material-icons">warning</i>${this.translateService.instant(
          "dialog.warning.cannot-undone"
        )}</small> <div>${this.translateService.instant(
          "dialog.warning.confirm-delete"
        )}${this.translateService.instant(
          "inbox.conversation-with"
        )}<span class="highlight">${customerDisplayName}</span>${this.translateService.instant(
          "inbox.conversation"
        )}? </div> `,
        destructive: true,
      },
    });

    dialogRef.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        console.log(`Delete conversation: ${conversationId}`);
        return this.apiService.deleteConversation(conversationId);
      }
    });
  }

  async addChannel(channel: Channel) {
    switch (channel) {
      case Channel.Facebook:
        return this.facebookService.connectFacebook();
      case Channel.WhatsApp:
        return this.dialog.open(WhatsappApiSelectorComponent);
      default:
        return this.ngZone.run(() => this.router.navigate(["/channel"]));
    }
  }

  async updateMessage(
    conversationId: string,
    newMessage: Message
  ): Promise<boolean> {
    // console.log(conversationId, newMessage);
    const [transactionErr, transactionRes] = await to(
      runTransaction(this.firestore, async (t) => {
        const ref = doc(this._collection, conversationId);
        // console.log(ref);
        const [currentErr, currentConversationDoc] = await to(t.get(ref));
        if (currentErr)
          return this.notificationService.error(
            "error.conversation.get",
            false
          );
        if (!currentConversationDoc.exists()) return false;
        let currentConversation = currentConversationDoc.data();
        if (!currentConversation) return false;
        // console.log(currentConversation.messages);
        const index = currentConversation.messages.findIndex(
          (m) => m.id == newMessage.id
        );
        if (index == -1) return false;

        await t.update(ref, {
          messages: arrayRemove(currentConversation.messages[index]),
        });
        // console.log(currentConversation.messages);
        await t.update(ref, {
          messages: arrayUnion(newMessage.json),
        });
      })
    );

    if (transactionErr)
      return this.notificationService.error("error.conversation.update", false);
    return true;
  }

  async startConversation(conversation: Conversation) {
    if (!conversation) return false;
    if (this.conversations.some((c) => c.id == conversation.id))
      return this.notificationService.error(
        "error.conversation.existing",
        false
      );
    return this.apiService.createConversation(conversation);
  }

  formatCustomerName(name?: string): string {
    return name || this.translateService.instant("inbox.customer");
  }

  formatCustomerPicture(sourceId: string, url?: string): SafeStyle {
    const avatar = `"https://avatars.dicebear.com/api/identicon/${sourceId}.svg"`;
    return this.sanitization.bypassSecurityTrustStyle(
      url ? `url(${url}), url(${avatar})` : `url(${avatar})`
    );
  }

  showChatlist() {
    this.allTypingOff();
    removeClass("contacts", "hide-in-mobile");
    removeClass("inbox", "full-screen");
    removeClass("app-nav-bottom", "hidden");
    removeClass("app-nav-top", "hidden");
  }

  hideChatlist() {
    this.allTypingOff();
    addClass("contacts", "hide-in-mobile");
    addClass("inbox", "full-screen");
    addClass("app-nav-bottom", "hidden");
    addClass("app-nav-top", "hidden");
  }
}
