export interface ClientQuotaParams {
  storage?: number;
  user?: number;
}

export class ClientQuota {
  storage: number;
  user: number;

  constructor(params: ClientQuotaParams = {}) {
    this.storage = 104857600; // 100MB
    //TODO this.storage = params.storage || 104857600; // 100MB
    this.user = params.user || 5;
  }
}
