import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Client } from "@models/user";
import { TranslateService } from "@ngx-translate/core";
import { animations, ChartType } from "@shared";
import { Subscription } from "rxjs";
import { environment } from "../../../../environments/environment";
import { AuthService } from "../../../services/auth.service";
import { ClientService } from "../../../services/client.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
  animations: animations,
})
export class DashboardComponent implements OnInit, OnDestroy {
  client: Client;
  clientSub: Subscription;
  statistics: any = {
    automation_percentage: 0,
    autoreply_message_by_period: [],
    average_respond_time: 0,
    customer_count: 0,
    incoming_message_by_period: [],
    message_count: 0,
    task_by_client: 0,
    task_by_taskforce: 0,
    total_autoreply_message: 0,
    total_incoming_message: 0,
    total_task: 0,
  };
  ChartType: typeof ChartType = ChartType;
  colors = {
    success: "#40e0d0",
    warn: "#ffca00",
    error: "#ff1b69",
    primary: "#38509c",
    purple: "#6b48af",
    black: "#282828",
  };
  timeSaved: any = {};
  messageChart: any = [];
  automationChart: any = [];
  taskforceChart: any = [];

  mobile = !!environment.mobile;

  constructor(
    private authService: AuthService,
    private clientService: ClientService,
    private translateService: TranslateService,
    private router: Router
  ) {}

  ngOnInit() {
    this.clientSub = this.authService.clientChanges.subscribe((client) => {
      if (client) {
        this.client = client;
      }
    });

    this.clientService.getClientStat().then((stat) => {
      // console.log(stat);
      if (stat) {
        delete stat.success;
        this.statistics = stat;
        console.log(this.statistics);
        this.getStatistic();
      } else {
        this.timeSaved = {
          value: 0,
          unit: "general.minute-unit",
        };
        this.taskforceChart = [];
        this.automationChart = [];
        this.messageChart = [];
      }
    });
  }

  ngOnDestroy() {
    if (this.clientSub) this.clientSub.unsubscribe();
  }

  getStatistic() {
    this.timeSaved = {
      value:
        this.statistics.total_autoreply_message > 24 * 60
          ? Math.ceil((this.statistics.total_autoreply_message / 24) * 60)
          : this.statistics.total_autoreply_message > 60
          ? Math.ceil(this.statistics.total_autoreply_message / 60)
          : this.statistics.total_autoreply_message,
      unit:
        this.statistics.total_autoreply_message > 24 * 60
          ? "general.day-unit"
          : this.statistics.total_autoreply_message > 60
          ? "general.hour-unit"
          : "general.minute-unit",
    };
    // console.log(this.timeSaved);
    this.taskforceChart =
      this.statistics.total_task > 0
        ? [
            {
              name: this.translateService.instant("general.staff"),
              value: this.statistics.task_by_client,
            },
            {
              name: this.translateService.instant("general.taskforce"),
              value: this.statistics.task_by_taskforce,
            },
          ]
        : [];

    this.automationChart =
      this.statistics.automation_percentage > 0
        ? [
            {
              name: this.translateService.instant("general.unrecognized"),
              value:
                this.statistics.total_incoming_message -
                this.statistics.total_autoreply_message,
            },
            {
              name: this.translateService.instant("general.identified"),
              value: this.statistics.total_autoreply_message,
            },
          ]
        : [];

    this.messageChart =
      this.statistics.incoming_message_by_period?.length > 0 ||
      this.statistics.autoreply_message_by_period?.length > 0
        ? [
            {
              name: this.translateService.instant("general.total"),
              series:
                this.statistics.incoming_message_by_period
                  .map((d, i) => {
                    return {
                      name: `[${this.clientService.statPeriod - i}] ${d.date}`,
                      value: d.value,
                    };
                  })
                  .reverse() || [],
            },
            {
              name: this.translateService.instant("general.autoreply"),
              series:
                this.statistics.autoreply_message_by_period
                  .map((d, i) => {
                    return {
                      name: `[${this.clientService.statPeriod - i}] ${d.date}`,
                      value: d.value,
                    };
                  })
                  .reverse() || [],
            },
          ]
        : [];
    // console.log(this.messageChart);
  }

  xFormat(x) {
    const arr = x.split("] ");
    const i = parseInt(arr[0].replace("[", ""));
    const date = arr[1];
    return i % 5 == 0 || i == 0 || i == 30 ? date : "";
  }

  isActiveRoute(url: string) {
    return this.router.url.includes(url);
  }
}
