<div class="page" *ngIf="client">
  <div class="page__header">
    <div class="page__container">
      <div class="header">
        <div class="row">
          <a id="back" class="icon-button" [routerLink]="'/autopilot/trigger'">
            <i class="material-icons">chevron_left</i>
          </a>
          <!-- <input type="text" /> -->
        </div>
        <div class="page__header__tab">
          <!-- <a
            [class.selected]="isActiveRoute('trigger')"
            [routerLink]="'trigger'"
          >
            Trigger
          </a> -->
          <!-- <a
            [class.selected]="isActiveRoute('workflow')"
            [routerLink]="'workflow'"
          >
            Workflow
          </a> -->
        </div>
        <div class="row">
          <mat-slide-toggle></mat-slide-toggle>
        </div>
      </div>
    </div>
  </div>

  <div class="page__content">
    <div class="page__container">
      <div class="autopilot-trigger">
        <div class="card">
          <div class="trigger">
            <div class="trigger__header">
              <div class="trigger__header__icon">
                <span class="material-icons"> flag </span>
              </div>
              <div class="trigger__header__title">
                <h1>When...</h1>
                <p>
                  The Autopilot Chatflow is triggered when the following(s)
                  happen
                </p>
              </div>
            </div>

            <app-trigger-entrypoint
              *ngFor="let entrypoint of trigger.entrypoints"
              [entrypoint]="entrypoint"
            ></app-trigger-entrypoint>
          </div>
          <!-- <app-trigger-item
            *ngFor="let entrypoint of trigger.entrypoints"
          ></app-trigger-item> -->
        </div>

        <div class="arrow">
          <span class="material-icons arrow__tail"> circle </span>
          <img
            class="arror__body"
            src="/assets/img/arrow-body-blue.svg"
            alt=""
          />
        </div>

        <div class="chatflow-preview"></div>
      </div>
    </div>
  </div>
</div>

<app-loader *ngIf="!client"></app-loader>
