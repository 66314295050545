import md5 from "md5";

export * from "@models/helper";
export { containEN, containZH, isEN } from "@models/misc/regex";
export * from "./jointjs";

export const waitFor = (selector: string): Promise<HTMLElement> => {
  return new Promise((resolve) => {
    let element: HTMLElement = document.querySelector(selector);
    if (element) return resolve(element);
    const interval = setInterval(() => {
      element = document.querySelector(selector);
      if (element) {
        clearInterval(interval);
        return resolve(element);
      }
    }, 100);
  });
};

export const scrollToTop = (element: HTMLElement, delay = 200) => {
  if (!element) return;
  if (!delay) return (element.scrollTop = 0);
  setTimeout((_) => {
    element.scrollTop = 0;
  }, delay);
};

export const scrollToBottom = (element: HTMLElement, delay = 0) => {
  if (!element) return;
  if (!delay)
    return (element.scrollTop = element.scrollHeight - element.offsetHeight);
  setTimeout((_) => {
    element.scrollTop = element.scrollHeight - element.offsetHeight;
  }, delay);
};

export const addClass = (id: string, className: string) => {
  const element = document.getElementById(id);
  if (!element) return;
  return element.classList.add(className);
};

export const removeClass = (id: string, className: string) => {
  const element = document.getElementById(id);
  if (!element) return;
  return element.classList.remove(className);
};

export const toBase64 = async (file: File): Promise<string> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () =>
      resolve(reader.result.toString().replace(/^data:(.*,)?/, ""));
    reader.onerror = (error) => resolve(null);
  });
};

export const toArrayBuffer = async (file: File): Promise<ArrayBuffer> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = () => resolve(reader.result as ArrayBuffer);
    reader.onerror = (error) => resolve(null);
  });
};

export const md5Hash = async (file: File): Promise<string> => {
  const buffer = await toArrayBuffer(file);
  if (!buffer) return "";
  return md5(buffer);
};
