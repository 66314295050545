<div class="auth__form__row column">
  <label translate>auth.email</label>
  <input type="email" [(ngModel)]="email" (keydown.enter)="resetPassword()" />
</div>

<button
  class="text-button bg-primary gradient-dark"
  (click)="resetPassword()"
  translate
  [disabled]="email == ''"
>
  auth.send-reset-email
</button>

<div class="instruction">
  <div class="row">
    <a [routerLink]="['/auth/login']" translate>general.cancel</a>
  </div>
</div>
