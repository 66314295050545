<div class="chatroom-container" *ngIf="conversation">
  <div class="chatroom">
    <app-chatroom-header
      [conversation]="conversation"
      [@smooth]
    ></app-chatroom-header>
    <app-chatroom-content [conversation]="conversation"></app-chatroom-content>
    <app-chatroom-input [conversation]="conversation"></app-chatroom-input>
  </div>
</div>
