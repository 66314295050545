<div class="chatroom__content" #chatroom>
  <app-empty
    *ngIf="showEmpty"
    [icon]="'inbox'"
    [message]="'inbox.no-message'"
  ></app-empty>

  <button
    class="text-button end-indicator"
    *ngIf="!conversation.loadedAll"
    (click)="getOlderMessages()"
  >
    <span translate>chatroom.load-messages</span>
  </button>

  <app-message-item
    *ngFor="
      let message of conversation.messages;
      let i = index;
      trackBy: messageTracker
    "
    [message]="message"
    [previousMessage]="i > 0 ? conversation.messages[i - 1] : null"
    [index]="i"
    [sourceId]="conversation.sourceId"
    [@smooth]
  >
  </app-message-item>

  <div class="end-indicator" *ngIf="conversation.messages.length != 0">
    <span translate>general.latest</span>
  </div>
</div>
