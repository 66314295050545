import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {
  FRIDAY_ID,
  Message,
  MessageFlag,
  QuickReplyType,
} from "@models/messenger";
import { formatDate } from "@utility";
import "firebase/firestore";
import { environment } from "../../../../../../../environments/environment";

@Component({
  selector: "app-message-item",
  templateUrl: "./message-item.component.html",
  styleUrls: ["./message-item.component.scss"],
})
export class MessageItemComponent implements OnInit {
  @Input() message: Message;
  @Input() index: number;
  @Input() sourceId: string;
  @Input() previousMessage: Message;
  // @Output() reply = new EventEmitter();

  MessageFlag: typeof MessageFlag = MessageFlag;
  QuickReplyType: typeof QuickReplyType = QuickReplyType;
  ready = false;
  showDate = true;
  FRIDAY_ID = FRIDAY_ID;
  mobile = !!environment.mobile;

  constructor(public dialog: MatDialog) {}

  ngOnInit() {
    const currentDate = new Date(this.message.timestamp);
    const previousDate = this.previousMessage
      ? new Date(this.previousMessage.timestamp)
      : new Date();
    if (
      currentDate.getDate() == previousDate.getDate() &&
      currentDate.getMonth() == previousDate.getMonth() &&
      currentDate.getFullYear() == previousDate.getFullYear()
    )
      this.showDate = false;
  }

  get date() {
    if (!this.showDate) return "";
    return formatDate(new Date(this.message.timestamp) || new Date());
  }

  // get html() {
  //   if (!this.message.html) return "";
  //   return this.sanitization.bypassSecurityTrustHtml(this.message.html);
  // }

  // quickReply(text: string) {
  //   this.reply.emit(text);
  // }
}
