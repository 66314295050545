import { isEmpty } from "lodash";
import { v4 as uuid } from "uuid";
import { toJSON } from "../../helper";
import { TriggerCondition, TriggerConditionParams } from "./condition";
import {
  AUTOPILOT_TRIGGER_EVENTS,
  AUTOPILOT_TRIGGER_EVENTS_LIST,
} from "./event";

export interface TriggerEntrypointParams {
  id?: string;
  event?: string;
  condition?: TriggerConditionParams;
  target?: string;
}

// Entrypoints share the same matching logic under the same Trigger, different in Channel, condition, target
export class TriggerEntrypoint {
  id: string;
  event: string;
  target?: string;
  condition: TriggerCondition;

  constructor(params: TriggerEntrypointParams = {}) {
    this.id = params.id || uuid();
    this.event = params.event || AUTOPILOT_TRIGGER_EVENTS.All.Message;
    this.condition = new TriggerCondition(params.condition || {});
    if (params.target) this.target = params.target;
  }

  get ref(): string {
    return `${this.event.trim()}${this.target ? ":" + this.target : ""}`.trim();
  }

  get valid(): boolean {
    console.log(!!this.event, this.condition.valid);
    return (
      !!this.event &&
      AUTOPILOT_TRIGGER_EVENTS_LIST.includes(this.event) &&
      this.condition.valid
    );
  }
}

export interface TriggerParams {
  clientId: string;
  id?: string;
  chatflowId?: string;
  entrypoints?: TriggerEntrypointParams[];
  refs?: string[];
  name?: string;
  description?: string;
  createdAt?: number;
  updatedAt?: number;
  active?: boolean;
}

export interface MessageTriggerParams extends TriggerParams {
  nlpSamples?: string[];
  nlpKeywords?: string[];
  textMatches?: string[];
}

export class Trigger {
  clientId: string;
  id: string;
  name: string;
  description: string;
  createdAt: number;
  updatedAt: number;
  entrypoints: TriggerEntrypoint[];
  chatflowId: string;
  active: boolean;
  refs?: string[];

  constructor(params: TriggerParams) {
    this.clientId = params.clientId;
    this.id = params.id || uuid();
    this.name = params.name || "";
    this.description = params.description || "";
    this.createdAt = params.createdAt || new Date().valueOf();
    this.updatedAt = params.updatedAt || new Date().valueOf();
    this.chatflowId = params.chatflowId || "";
    this.active = params.active ?? false;
    this.entrypoints = params.entrypoints
      ? params.entrypoints.map((e) => new TriggerEntrypoint(e))
      : [];
    this.updateRefs();
  }

  addEntrypoint(entrypoint: TriggerEntrypoint) {
    this.entrypoints.push(entrypoint);
    this.updateRefs();
  }

  removeEntrypoint(entrypointId: string) {
    this.entrypoints = this.entrypoints.filter((e) => e.id != entrypointId);
    this.updateRefs();
  }

  updateRefs(): string[] {
    this.refs = this.entrypoints.map((entrypoint) => entrypoint.ref);
    return this.refs;
  }

  get valid(): boolean {
    return (
      this.entrypoints.length > 0 && this.entrypoints.every((e) => e.valid)
    );
  }

  get activable(): boolean {
    return !!this.chatflowId && this.valid;
  }

  get json() {
    this.updateRefs();
    return toJSON(this);
  }
}

export class MessageTrigger extends Trigger {
  nlpSamples?: string[];
  nlpKeywords?: string[]; // NLP keywords have higher weight in match score
  textMatches?: string[]; // exact text match

  constructor(params: MessageTriggerParams) {
    super(params);
    if (params.nlpSamples) {
      this.nlpSamples = params.nlpSamples;
      this.nlpKeywords = params.nlpKeywords || [];
    }
    if (params.textMatches) this.textMatches = params.textMatches;
  }

  get skipMatch(): boolean {
    return (
      isEmpty(this.nlpSamples) &&
      isEmpty(this.nlpKeywords) &&
      isEmpty(this.textMatches)
    );
  }
}
