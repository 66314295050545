<div class="chatroom__header" *ngIf="conversation">
  <a id="back" [routerLink]="'/m/inbox'">
    <i class="material-icons">arrow_back_ios</i>
  </a>

  <div class="user-icon" [style.background-image]="profilePicture"></div>

  <div class="chatroom__header__customer">
    <div class="chatroom__header__customer__name">
      {{ conversation.customer.displayName || ("inbox.customer" | translate) }}
    </div>

    <div class="chatroom__header__customer__contact">
      {{ conversation.customer.email || conversation.customer.phoneNumber }}
    </div>
  </div>

  <div class="chatroom__header__action">
    <button
      class="text-button success"
      *ngIf="conversation.unread"
      (click)="markRead()"
    >
      <i class="material-icons">mark_chat_read</i>
      <span translate>inbox.mark-read</span>
    </button>

    <button
      class="text-button warn"
      *ngIf="!conversation.unread"
      (click)="markUnread()"
    >
      <i class="material-icons">mark_chat_unread</i>
      <span translate>inbox.mark-unread</span>
    </button>

    <ng-container *ngIf="!mobile">
      <button
        class="text-button gray"
        *ngIf="!conversation.archived"
        (click)="archiveConversation()"
      >
        <i class="material-icons">archive</i>
        <span translate>general.archive</span>
      </button>

      <button
        class="text-button gray"
        *ngIf="conversation.archived"
        (click)="unarchiveConversation()"
      >
        <i class="material-icons">unarchive</i>
        <span translate>general.unarchive</span>
      </button>

      <button
        class="text-button error"
        *ngIf="conversation.archived"
        (click)="delete()"
      >
        <i class="material-icons">delete_forever</i>
        <span translate>general.delete</span>
      </button>
    </ng-container>

    <!-- <button
			class="icon-button"
			mat-button
			[matMenuTriggerFor]="conversationActions"
		>
			<i class="material-icons">more_vert</i>
		</button> -->
  </div>
</div>

<!-- <mat-menu #conversationActions="matMenu" xPosition="before" yPosition="below">
	<button
		class="success"
		*ngIf="conversation.unread"
		(click)="markRead()"
		mat-menu-item
	>
		<i class="material-icons">mark_chat_read</i>
		<span translate>inbox.mark-read</span>
	</button>

	<button
		class="warn"
		*ngIf="!conversation.unread"
		(click)="markUnread()"
		mat-menu-item
	>
		<i class="material-icons">mark_chat_unread</i>
		<span translate>inbox.mark-unread</span>
	</button>

	<button
		class="error"
		*ngIf="!conversation.archived"
		(click)="archiveConversation()"
		mat-menu-item
	>
		<i class="material-icons">archive</i>
		<span translate>general.archive</span>
	</button>

	<button
		class="success"
		*ngIf="conversation.archived"
		(click)="unarchiveConversation()"
		mat-menu-item
	>
		<i class="material-icons">archive</i>
		<span translate>general.unarchive</span>
	</button>
</mat-menu> -->
