import { Component, OnInit } from "@angular/core";
import { Client } from "@models/user";
import { AuthService } from "../../../services/auth.service";
import { ClientService } from "../../../services/client.service";

@Component({
  selector: "app-admin",
  templateUrl: "./admin.component.html",
  styleUrls: ["./admin.component.scss"],
})
export class AdminComponent implements OnInit {
  clients: Client[];
  selectedClient: Client;
  selectedClientId: string;

  constructor(
    public authService: AuthService,
    private clientService: ClientService
  ) {}

  async ngOnInit() {
    this.clients = await this.clientService.listActiveClients();
    if (this.clients.length == 0) return this.authService.signOut();
    this.selectedClient = this.clients[0];
    this.selectedClientId = this.selectedClient.id;
    console.log(this.clients, this.selectedClient, this.selectedClientId);
  }

  selectClient(clientId: string) {
    this.selectedClientId = clientId;
    this.selectedClient = this.clients.find((c) => c.id == clientId);
  }

  signInAsClient() {
    return this.authService.signInAsClient(this.selectedClientId);
  }
}
