import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Channel, Conversation } from "@models/messenger";
import { animations } from "@shared";
import { environment } from "../../../../../environments/environment";

const PRODUCTION = environment.ENV == "PROD";

@Component({
  selector: "app-chatroom",
  templateUrl: "./chatroom.component.html",
  styleUrls: ["./chatroom.component.scss"],
  animations: animations,
})
export class ChatroomComponent implements OnInit {
  @Input() conversation: Conversation;
  @Output() archived = new EventEmitter();
  @Output() unarchived = new EventEmitter();

  Channel: typeof Channel = Channel;
  sending = false;

  constructor() {}

  ngOnInit() {}
}
