import { v4 as uuid } from "uuid";
import { toJSON } from "../helper";
import { Channel, WhatsappProvider } from "./channel";
import { Customer, CustomerParams } from "./customer";
import { Message, MessageParams } from "./message";

export const TWILIO_PREFIX = "whatsapp:";
export const MESSAGE_LOAD_LIMIT = 100;

export const toConversationId = (
  clientId: string,
  sourceId: string
): string => {
  return `${clientId}:::${sourceId}`;
};

export const sourceIdFromConversationId = (conversationId: string): string => {
  if (!conversationId) return "";
  try {
    return conversationId.split(":::").slice(-1)[0];
  } catch (error) {
    return conversationId;
  }
};

export interface ConversationParams {
  clientId: string;
  me: string;
  channel: Channel;
  id?: string;
  sourceId?: string;
  customer?: CustomerParams;
  whatsappProvider?: WhatsappProvider;
  messages?: MessageParams[];
  createdAt?: number;
  updatedAt?: number;
  hidden?: boolean;
  unread?: boolean;
  archived?: boolean;
  typing?: string[];
}

export class Conversation {
  id: string;
  clientId: string;
  sourceId: string;
  me: string;
  customer: Customer;
  channel: Channel;
  messages: Message[];
  createdAt: number;
  updatedAt: number;
  hidden: boolean;
  unread: boolean;
  archived: boolean;
  typing: string[];

  constructor(params: ConversationParams) {
    this.clientId = params.clientId;
    this.me = params.me;
    this.sourceId = params.sourceId || uuid();
    this.id = params.id || toConversationId(this.clientId, this.sourceId);
    this.channel = params.channel;

    this.customer = new Customer({
      ...(params.customer || {}),
      ...{ id: this.sourceId },
    });

    this.messages =
      params.messages && params.messages?.length > 0
        ? params.messages.map(
            (m) =>
              new Message({
                ...m,
                ...{ channel: this.channel, clientId: this.clientId },
              })
          )
        : [];

    this.hidden = !!params.hidden;
    this.unread = !!params.unread;
    this.archived = !!params.archived;
    this.typing = params.typing || [];

    this.createdAt = params.createdAt || new Date().valueOf();
    this.updatedAt = params.updatedAt || new Date().valueOf();
  }

  get clone() {
    return toJSON(this);
  }

  get json() {
    const json = toJSON(this) as ConversationParams;
    // Remove subcollections
    delete json.messages;
    return json;
  }

  get whatsappProvider() {
    if (this.channel != Channel.WhatsApp) return WhatsappProvider.Unknown;
    return this.sourceId.includes(TWILIO_PREFIX)
      ? WhatsappProvider.Twilio
      : WhatsappProvider.Friday;
  }

  get loadedAll() {
    return this.messages.length < MESSAGE_LOAD_LIMIT;
  }

  update() {
    this.updatedAt = new Date().valueOf();
  }
}
