import { Injectable, NgZone } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { AuthService } from "../services/auth.service";

@Injectable({
	providedIn: "root",
})
export class AdminGuard implements CanActivate {
	constructor(
		private authService: AuthService,
		private router: Router,
		private ngZone: NgZone
	) {}

	canActivate(): Observable<boolean> {
		return new Observable(observer => {
			this.authService.authUserChanges.subscribe(authUser => {
				if (!this.authService.initialized || !authUser) return;
				if (this.authService.isSuperAdmin) return observer.next(true);
				this.ngZone.run(() => this.router.navigate(["/dashboard"]));
				observer.next(false);
			});
		});
	}
}
