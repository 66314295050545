<div class="trigger__entrypoint" *ngIf="entrypoint">
  <h1>Event</h1>
  <div class="select-wrapper">
    <div
      class="select-item"
      [class.selected]="entrypoint.event == triggerEvents.All.Message"
      (click)="entrypoint.event = triggerEvents.All.Message"
    >
      <span class="material-icons"> chat </span>
      <h2>Any Message</h2>
    </div>

    <div
      class="select-item"
      [class.selected]="entrypoint.event == triggerEvents.WhatsApp.Message"
      (click)="entrypoint.event = triggerEvents.WhatsApp.Message"
    >
      <img src="/assets/img/whatsapp-logo.svg" alt="" />
      <h2>Message</h2>
    </div>

    <div
      class="select-item"
      [class.selected]="entrypoint.event == triggerEvents.Telegram.Message"
      (click)="entrypoint.event = triggerEvents.Telegram.Message"
    >
      <img src="/assets/img/telegram-logo.svg" alt="" />
      <h2>Message</h2>
    </div>

    <div
      class="select-item"
      [class.selected]="entrypoint.event == triggerEvents.Facebook.Message"
      (click)="entrypoint.event = triggerEvents.Facebook.Message"
    >
      <img src="/assets/img/facebook-logo.svg" alt="" />
      <h2>Message</h2>
    </div>

    <div
      class="select-item"
      [class.selected]="entrypoint.event == triggerEvents.Facebook.PostComment"
      (click)="entrypoint.event = triggerEvents.Facebook.PostComment"
    >
      <img src="/assets/img/facebook-logo.svg" alt="" />
      <h2>Comment on Post</h2>
    </div>

    <div
      class="select-item"
      [class.selected]="entrypoint.event == triggerEvents.Instagram.Message"
      (click)="entrypoint.event = triggerEvents.Instagram.Message"
    >
      <img src="/assets/img/instagram-logo.svg" alt="" />
      <h2>Message</h2>
    </div>

    <div
      class="select-item"
      [class.selected]="entrypoint.event == triggerEvents.Instagram.PostComment"
      (click)="entrypoint.event = triggerEvents.Instagram.PostComment"
    >
      <img src="/assets/img/instagram-logo.svg" alt="" />
      <h2>Comment on Post</h2>
    </div>
  </div>

  <ng-container
    *ngIf="
      [
        triggerEvents.Instagram.PostComment,
        triggerEvents.Facebook.PostComment
      ].includes(entrypoint.event)
    "
  >
    <h1>Target</h1>
    <div class="target-wrapper"></div>
  </ng-container>

  <h1>Condition</h1>
  <div class="select-wrapper">
    <div
      class="select-item"
      [class.selected]="
        entrypoint.condition.type == TriggerConditionType.Always
      "
      (click)="entrypoint.condition.setType(TriggerConditionType.Always)"
    >
      <span class="material-icons"> calendar_month </span>
      <h2>Always</h2>
    </div>
    <div
      class="select-item"
      [class.selected]="entrypoint.condition.type == TriggerConditionType.Once"
      (click)="entrypoint.condition.setType(TriggerConditionType.Once)"
    >
      <span class="material-icons success"> task_alt </span>
      <h2>Once Only</h2>
    </div>
    <div
      class="select-item"
      [class.selected]="
        entrypoint.condition.type == TriggerConditionType.Period
      "
      (click)="entrypoint.condition.setType(TriggerConditionType.Period)"
    >
      <span class="material-icons success"> schedule </span>
      <h2>Specific Period</h2>
    </div>
  </div>

  <div
    class="form"
    *ngIf="entrypoint.condition.type == TriggerConditionType.Period"
  >
    <div class="form__row">
      <div class="form__group">
        <label>
          <i class="material-icons"> view_week </i>
          <span>day of weeks</span>
        </label>
        <div class="weekdays">
          <div
            class="weekday"
            *ngFor="let i of dayOfWeek"
            (click)="toggleWeekday(i)"
            [class.selected]="weekdays.includes(i)"
          >
            <span translate> general.weekdays.{{ i }}</span>
          </div>
        </div>
      </div>

      <div class="form__group">
        <label>
          <i class="material-icons"> alarm_on </i>
          <span>hour (start - end)</span>
        </label>
        <div class="row">
          <input
            class="hour"
            type="number"
            min="0"
            max="23"
            [(ngModel)]="hourStart"
          />
          <i class="material-icons"> arrow_right </i>
          <input
            class="hour"
            type="number"
            min="0"
            max="23"
            [(ngModel)]="hourEnd"
          />
        </div>
      </div>
    </div>
  </div>
</div>
