<div
  class="message"
  [class.right]="message.senderId != sourceId"
  *ngIf="message && !message.shouldHide"
>
  <div class="message__date" *ngIf="date">
    {{ date }}
  </div>

  <div
    class="message__wrapper"
    [class.not-delivered]="message.senderId != sourceId && !message.deliveredAt"
  >
    <ng-container *ngIf="!message.pending && !message.revoked">
      <!-- Message -->
      <app-message-text
        *ngIf="message.text"
        [message]="message"
        [sourceId]="sourceId"
        [previousSenderName]="previousMessage?.senderName || ''"
      ></app-message-text>

      <!-- Message -->

      <!-- Attachments -->
      <app-message-attachment
        *ngIf="message.attachments?.length > 0"
        [message]="message"
        [sourceId]="sourceId"
        [previousSenderName]="previousMessage?.senderName || ''"
      ></app-message-attachment>
      <!-- Attachments -->
    </ng-container>

    <div class="message__text revoked" *ngIf="message.revoked">
      <span translate>inbox.message-deleted</span>
    </div>
  </div>
</div>

<!-- <ng-container *ngIf="showAction()">
  <div class="message__remark" *ngIf="knowledgeQuery">
    <span translate>task.identified-as</span>&nbsp;'{{ knowledgeQuery }}'
  </div>

  <div class="message__action">
    <button
      class="text-button flat"
      *ngIf="message.flag"
      (click)="addToKnowledgeBase()"
    >
      <div>
        {{ "task.click-to-handle" | translate }}:
        {{ "message.flag." + message.flag | translate }}
      </div>
      <div *ngIf="respondManually()" translate>task.respond-manually</div>
    </button>

    <button
      class="text-button flat btn-dismiss"
      (click)="dismissWarning()"
      translate
    >
      general.dismiss
    </button>
  </div>
</ng-container> -->
