import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { Client, User } from "@models/user";
import { animations } from "@shared";
import { formatDate } from "@utility";
import { Subscription } from "rxjs";
import { environment } from "../../../../../environments/environment";
import { ApiService } from "../../../../services/api.service";
import { AuthService } from "../../../../services/auth.service";
import { AddUserComponent } from "../../../dialogs/add-user/add-user.component";

@Component({
  selector: "app-organization",
  templateUrl: "./organization.component.html",
  styleUrls: ["./organization.component.scss"],
  animations: animations,
})
export class OrganizationComponent implements OnInit, OnDestroy {
  clientSub: Subscription;
  client: Client;
  initialized = false;
  currentUserId: string;
  currentPermission = 0;
  mobile = !!environment.mobile;
  isManager = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  columns = ["name", "email", "createdAt", "action"];
  dataSource: MatTableDataSource<any>;

  constructor(
    private authService: AuthService,
    private apiService: ApiService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.isManager = this.authService.isManager;
    this.currentUserId = this.authService.currentUser.id;
    this.currentPermission = this.authService.currentUser.permission || 0;

    this.clientSub = this.authService.clientChanges.subscribe((client) => {
      if (!client) return;
      this.client = client;
      if (!this.initialized) {
        this.dataSource = new MatTableDataSource(this.client.users);
        this.dataSource.paginator = this.paginator;
      } else {
        this.dataSource.data = this.client.users;
      }
    });
  }

  ngOnDestroy() {
    if (this.clientSub) this.clientSub.unsubscribe();
  }

  formatDate(timestamp: number) {
    return formatDate(new Date(timestamp));
  }

  allowAddUser() {
    if (this.mobile) return false;
    return this.isManager;
  }

  allowEditUser(user: User) {
    if (this.mobile || !this.isManager) return false;
    return (
      this.currentUserId != user.id && this.currentPermission > user.permission
    );
  }

  deleteUser(uid) {
    return this.apiService.deleteUser(uid);
  }

  createSubAccount() {
    this.dialog.open(AddUserComponent);
  }
}
