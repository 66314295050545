export const REGEX_EMOJI =
  /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi;

export const REGEX_ZH =
  /[\u4E00-\u9FCC\u3400-\u4DB5\uFA0E\uFA0F\uFA11\uFA13\uFA14\uFA1F\uFA21\uFA23\uFA24\uFA27-\uFA29]|[\ud840-\ud868][\udc00-\udfff]|\ud869[\udc00-\uded6\udf00-\udfff]|[\ud86a-\ud86c][\udc00-\udfff]|\ud86d[\udc00-\udf34\udf40-\udfff]|\ud86e[\udc00-\udc1d]/gu;
export const REGEX_ZH_CHAR =
  /[\u4E00-\u9FCC\u3400-\u4DB5\uFA0E\uFA0F\uFA11\uFA13\uFA14\uFA1F\uFA21\uFA23\uFA24\uFA27-\uFA29]|[\ud840-\ud868][\udc00-\udfff]|\ud869[\udc00-\uded6\udf00-\udfff]|[\ud86a-\ud86c][\udc00-\udfff]|\ud86d[\udc00-\udf34\udf40-\udfff]|\ud86e[\udc00-\udc1d]|[\p{P}\p{S}\w\s]/giu;
export const REGEX_EN = /[a-z']/gi;

export const REGEX_PUNCTUATION = /[\p{P}\p{S}]/giu;

export const REGEX_COMMA = /[,，]/gi;

export const REGEX_SPACE = /\s+/g;

export const REGEX_EMAIL =
  /([a-z\d]+)([a-z\d_\-\.]*)([a-z\d]+)@([a-z\d]+)([a-z\d_\-\.]*)\.([a-z]{2,5})/gi;

export const REGEX_NUMBER = /[\+-]?\d+(.\d*)?/gi;

export const REGEX_PARAMETER = /\{\{[^\}]*\}\}/gi;

export const REGEX_URL =
  /(?:(?:https?|ftp):\/\/)?[a-z]+[\w\/\-?=%&:.]+\.[\w\/\-?=%&:.]+/gi;

export const REGEX_PHONE = /\+?(\d{2,6})?\s?(\(\d{2,6}\))?\s?[\d\-\s]{8,}/gi;

export const REGEX_QUESTION_NO = /^\d+\)/gi;

export const REGEX_MATH_OPERATOR = /[\+\-\*\/\^\%\.\d\s\(\)\{\}\[\]]/gi;

export const REGEX_HASHTAG = /#\w+/gi;
export const REGEX_MENTION = /@\w+/gi;
export const REGEX_NUMBER_ID = /^\d+$/gim;

export const REGEX_EOS = /[\r\n。!;?~]|(\. )/gi;

export const REGEX_JP = /[\p{sc=Hiragana}\p{sc=Katakana}]/giu;

export const REGEX_KR = /\p{sc=Hangul}/giu;

// Negative Lookbehind not supported by Safari
// export const REGEX_ITALIC = /(?<!\w)_.+_(?!\w)/gi;
// export const REGEX_BOLD = /(?<!\w)\*.+\*(?!\w)/gi;
// export const REGEX_MONOSPACE = /(?<!\w)```.+```(?!\w)/gi;
// export const REGEX_STRIKETHROUGH = /(?<!\w)~.+~(?!\w)/gi;
export const REGEX_ITALIC = /(^_.+_(?![a-z0-9]))|(\s_.+_(?![a-z0-9]))/gim;
export const REGEX_BOLD = /(^\*.+\*(?![a-z0-9]))|(\s\*.+\*(?![a-z0-9]))/gim;
export const REGEX_MONOSPACE =
  /(^```.+```(?![a-z0-9]))|(\s```.+```(?![a-z0-9]))/gim;
export const REGEX_STRIKETHROUGH =
  /(^~.+~(?![a-z0-9]))|(\s~.+~(?![a-z0-9]))/gim;
export const REGEX_COMMAND = /^\/[\w-]+$/im;

export const containZH = (text: string): boolean => {
  return !!text.match(REGEX_ZH);
};

export const containEN = (text: string): boolean => {
  return !!text.match(REGEX_EN);
};

export const containJP = (text: string): boolean => {
  return !!text.match(REGEX_JP);
};

export const containKR = (text: string): boolean => {
  return !!text.match(REGEX_KR);
};

export const isPunctuation = (text: string): boolean => {
  return !!text.match(REGEX_PUNCTUATION);
};

export const isZH = (text: string): boolean => {
  if (!containZH(text)) return false;
  if (containJP(text) || containKR(text)) return false;
  return true;
};

export const isEN = (text: string): boolean => {
  if (!containEN(text)) return false;
  if (containZH(text) || containJP(text) || containKR(text)) return false;
  return true;
};
