import { Component, NgZone, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SettingService } from "@shared";
import { environment } from "../../../../../environments/environment";
import { AuthProvider, AuthService } from "../../../../services/auth.service";

@Component({
  selector: "app-signin",
  templateUrl: "./signin.component.html",
  styleUrls: ["./signin.component.scss"],
})
export class SigninComponent implements OnInit {
  AuthProvider: typeof AuthProvider = AuthProvider;
  redirect: string;
  loading = false;
  email: string;
  password: string;
  mobile = !!environment.mobile;

  constructor(
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private ngZone: NgZone,
    public settingService: SettingService
  ) {}

  ngOnInit() {
    this.redirect = this.activatedRoute.snapshot.queryParams.redirect || "/";
    this.email = this.activatedRoute.snapshot.queryParams.email || "";
  }

  async signIn(provider: AuthProvider) {
    this.loading = true;
    const success = await this.authService.signIn(
      provider,
      this.redirect,
      this.email,
      this.password
    );
    if (!success) {
      this.password = "";
    }
    this.loading = false;
    this.ngZone.run(() => this.router.navigate([this.redirect]));
  }
}
