import { Component, Input } from "@angular/core";

@Component({
  selector: "app-status",
  templateUrl: "./status.component.html",
  styleUrls: ["./status.component.scss"],
})
export class StatusComponent {
  @Input() tooltip: string;
  @Input() text: string;
  @Input() success: boolean;
  @Input() error: boolean;
  @Input() warn: boolean;

  constructor() {}
}
