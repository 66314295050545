import { END_OF_CHATFLOW } from "../state";

export interface ChatflowStaffActionParams {
  fields?: string[];
  approved?: string;
  rejected?: string;
}

export class ChatflowStaffAction {
  fields: string[];
  approved: string;
  rejected: string;

  constructor(params: ChatflowStaffActionParams = {}) {
    this.fields = params.fields || [];
    this.approved = params.approved || "";
    this.rejected = params.rejected || END_OF_CHATFLOW;
  }
}
