<div class="page__container" *ngIf="client && channels" [@smooth]>
  <div class="form">
    <div class="form__section" translate>general.general</div>

    <div class="form__row">
      <div class="form__label" translate>setting.enable-autoreply</div>
      <div class="form__field autoreply">
        <mat-slide-toggle
          [(ngModel)]="client.settings.autopilot.active"
          (ngModelChange)="toggleAutoreply()"
          [disabled]="!isManager"
        >
        </mat-slide-toggle>

        <div class="channels">
          <mat-checkbox
            *ngFor="let channel of channels; let i = index"
            [checked]="client.settings.autopilot.channels.includes(channel)"
            (change)="toggleChannel($event.checked, i)"
            [disabled]="!isManager"
          >
            {{ "channel." + channel | translate }}
          </mat-checkbox>
        </div>
      </div>

      <div
        class="form__action"
        *ngIf="
          client.settings.autopilot.active !=
            clientCopy.settings.autopilot.active ||
          client.settings.autopilot.channels.length !=
            clientCopy.settings.autopilot.channels.length
        "
      >
        <button class="icon-button warn" (click)="reset()">
          <i class="material-icons">cancel</i>
        </button>
        <button class="icon-button success" (click)="save()">
          <i class="material-icons">check_circle</i>
        </button>
      </div>
    </div>

    <div class="form__row">
      <div class="form__label" translate>setting.threshold</div>
      <div class="form__field">
        <mat-slider
          [min]="0.5"
          [max]="0.95"
          [step]="0.05"
          [thumbLabel]="true"
          [(ngModel)]="client.settings.autopilot.threshold"
          [disabled]="!isManager"
        >
        </mat-slider>
        <label>{{ client.settings.autopilot.threshold }}</label>
      </div>
      <div
        class="form__action"
        *ngIf="
          client.settings.autopilot.threshold !=
          clientCopy.settings.autopilot.threshold
        "
      >
        <button class="icon-button warn" (click)="reset()">
          <i class="material-icons">cancel</i>
        </button>
        <button class="icon-button success" (click)="save()">
          <i class="material-icons">check_circle</i>
        </button>
      </div>
    </div>

    <!-- <div class="form__section beta" translate>
        setting.taskforce
      </div>

      <div class="form__row">
        <div class="form__label" translate>setting.enable-taskforce</div>
        <div class="form__field">
          <mat-slide-toggle [(ngModel)]="client.settings.taskforce.active">
          </mat-slide-toggle>
        </div>
        <div
          class="form__action"
          *ngIf="
            client.settings.taskforce.active !=
            clientCopy.settings.taskforce.active
          "
        >
          <button class="icon-button warn" (click)="reset()">
            <i class="material-icons">cancel</i>
          </button>
          <button class="icon-button success" (click)="save()">
            <i class="material-icons">check_circle</i>
          </button>
        </div>
      </div>

      <div class="form__row" [class.disabled]="!client.settings.taskforce.active">
        <div class="form__label" translate>setting.multiple-check</div>
        <div class="form__field">
          <mat-slider
            [min]="1"
            [max]="3"
            [step]="1"
            [thumbLabel]="true"
            [(ngModel)]="client.settings.taskforce.check"
            [disabled]="!client.settings.taskforce.active"
          >
          </mat-slider>
          <label>{{ client.settings.taskforce.check }}</label>
        </div>
        <div
          class="form__action"
          *ngIf="
            client.settings.taskforce.check !=
            clientCopy.settings.taskforce.check
          "
        >
          <button class="icon-button warn" (click)="reset()">
            <i class="material-icons">cancel</i>
          </button>
          <button class="icon-button success" (click)="save()">
            <i class="material-icons">check_circle</i>
          </button>
        </div>
      </div> -->
  </div>
</div>
<app-loader *ngIf="!client"></app-loader>
