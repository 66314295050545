import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Conversation, Message } from "@models/messenger";
import { animations } from "@shared";
import { scrollToBottom } from "@utility";
import { ConversationService } from "../../../../../services/conversation.service";

@Component({
  selector: "app-chatroom-content",
  templateUrl: "./chatroom-content.component.html",
  styleUrls: ["./chatroom-content.component.scss"],
  animations: animations,
})
export class ChatroomContentComponent implements OnInit, OnChanges {
  @Input() conversation: Conversation;
  @ViewChild("chatroom") chatroomElement: ElementRef;

  constructor(private conversationService: ConversationService) {}

  ngOnInit() {
    this.toBottom();
  }

  ngOnChanges() {
    console.log(this.conversation);
    this.toBottom();
  }

  get showEmpty() {
    if (this.conversation.messages.length > 0) return false;
    if (new Date().valueOf() - this.conversation.createdAt > 3600000)
      return false;
    return true;
  }

  messageTracker(index: number, message: Message) {
    return `${index.toString()}-${message.id}`;
  }

  toBottom() {
    setTimeout(() => {
      scrollToBottom(this.chatroomElement?.nativeElement);
    }, 1000);
  }

  getOlderMessages() {
    return this.conversationService.getOlderMessages(
      this.conversation.id,
      this.conversation.messages.length > 0
        ? this.conversation.messages[0].timestamp
        : null
    );
  }
}
