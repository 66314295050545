import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationDialogComponent {
  destructive: boolean;
  messageHTML: string;
  message: string;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    private sanitization: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.destructive = data.destructive;
    this.messageHTML = data.messageHTML;
    this.message = data.message;
  }

  html(text: string) {
    return this.sanitization.bypassSecurityTrustHtml(text);
  }

  close() {
    this.dialogRef.close(false);
  }

}
