import axios, { AxiosRequestConfig } from "axios";
import { isEmpty } from "lodash";
import { to } from "../../../../helper";
import { HttpContentType, HttpMethod, REGEX_PARAMETER } from "../../../../misc";
import { ChatflowStateValues } from "../../state";
import {
  getObjectValueFromStringRepresentation,
  getVariableNames,
} from "./variable";

export interface ChatflowApiResponseParams {
  url?: string;
  method?: HttpMethod;
  contentType?: HttpContentType;
  params?: { [key: string]: string };
  body?: { [key: string]: string };
  headers?: { [key: string]: string };
}

export class ChatflowApiResponse {
  url: string;
  method: HttpMethod;
  params: { [key: string]: string };
  body: { [key: string]: string };
  headers: { [key: string]: string };
  contentType?: HttpContentType;

  constructor(params: ChatflowApiResponseParams = {}) {
    this.url = params.url || "";
    this.method = params.method || HttpMethod.Get;
    this.headers = params.headers || {};
    this.params = params.params || {};
    this.body = params.body || {};
    if (params.contentType) this.contentType = params.contentType;
  }

  valid(definedVariables: string[] = []): boolean {
    if (definedVariables.length == 0) return true;
    for (let field of ["headers", "params", "body"]) {
      // console.log(definedVariables, this[field]);
      if (isEmpty(this[field])) continue;
      const fieldKeys = Object.keys(this[field]);
      // console.log(fieldKeys);
      for (let fieldKey of fieldKeys) {
        const fieldValue = this[field][fieldKey].trim();
        const fieldValueVariables = getVariableNames(fieldValue);
        if (fieldValueVariables.length == 0) continue;
        // Invalid field value with variable
        const fieldVariable = fieldValueVariables[0];
        // console.log(fieldVariable);
        if (
          fieldValueVariables.length != 1 ||
          fieldVariable.length + 4 != fieldValue.length
        )
          return false;
        // Field variable not exist
        if (!definedVariables.includes(fieldVariable.split(".")[0]))
          return false;
      }
    }
    return true;
  }

  get axiosConfig(): AxiosRequestConfig {
    let config: AxiosRequestConfig = {};
    if (this.url) config.url = this.url;
    if (!isEmpty(this.params)) config.params = this.params;
    if (!isEmpty(this.body)) config.data = this.body;
    config.headers = this.headers;
    if (this.contentType) config.headers["Content-Type"] = this.contentType;
    return config;
  }

  async request(values: ChatflowStateValues = {}) {
    try {
      for (let field of ["headers", "params", "body"]) {
        if (isEmpty(this[field])) continue;
        for (let _key of Object.keys(this[field])) {
          const _value = this[field][_key];
          if (!_value.match(REGEX_PARAMETER)) continue;
          this[field][_key] = getObjectValueFromStringRepresentation(
            values,
            _value,
            _value
          );
        }
      }

      const [err, res] = await to(axios(this.axiosConfig));
      if (err)
        return err.message || err.response.data || err.response.status || "";
      return res?.data || res?.statusText || res?.status;
    } catch (error) {
      return "";
    }
  }
}
