import { Component, OnInit } from "@angular/core";
import { Client } from "@models/user";
import { AuthService } from "../../services/auth.service";
import { ClientService } from "../../services/client.service";

@Component({
  selector: "app-mobile-container",
  templateUrl: "./mobile-container.component.html",
  styleUrls: ["./mobile-container.component.scss"],
})
export class MobileContainerComponent implements OnInit {
  client: Client;
  routes = [
    { name: "inbox", url: "/m/inbox", icon: "mail" },
    // { name: "channel", url: "/channel", icon: "hub" },
    // { name: "setting", url: "/setting", icon: "settings" },
  ];
  initialized = false;

  constructor(
    public authService: AuthService,
    private clientService: ClientService
  ) {}

  async ngOnInit() {
    this.client = this.authService.client;
    if (!this.authService.isSuperAdmin)
      await this.clientService.initializeNotification();
    this.initialized = true;
  }
}
