import { Component, OnInit } from "@angular/core";
import { arrayRemove, arrayUnion } from "@angular/fire/firestore";
import { MatDialog } from "@angular/material/dialog";
import { Channel } from "@models/messenger";
import { Client } from "@models/user";
import { TranslateService } from "@ngx-translate/core";
import {
  animations,
  ConfirmationDialogComponent,
  NotificationService,
} from "@shared";
import { toJSON } from "@utility";
import "firebase/firestore";
import { Subscription } from "rxjs";
import { environment } from "../../../../../../environments/environment";
import { ApiService } from "../../../../../services/api.service";
import { AuthService } from "../../../../../services/auth.service";
import { ClientService } from "../../../../../services/client.service";

@Component({
  selector: "app-twilio",
  templateUrl: "./twilio.component.html",
  styleUrls: ["./twilio.component.scss"],
  animations: animations,
})
export class TwilioComponent implements OnInit {
  client: Client;
  clientSub: Subscription;
  loading = false;
  accountSid = "";
  authToken = "";
  newNumber = "";
  numbers: string[] = [];
  webhook: string;
  editCredential = false;
  credentialCopy: any = {};
  adminView = false;
  isManager = false;

  constructor(
    private authService: AuthService,
    private clientService: ClientService,
    private apiService: ApiService,
    public dialog: MatDialog,
    private translateService: TranslateService,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.adminView = this.authService.isSuperAdmin;
    this.isManager = this.authService.isManager;
    this.webhook = environment.apiEndpoint + "/webhook/twilio";
    this.clientSub = this.authService.clientChanges.subscribe(
      async (client) => {
        if (!client) return;
        this.client = client;
        this.accountSid = this.client.channels?.twilio?.accountSid || "";
        this.authToken = this.client.channels?.twilio?.authToken
          ? "secret"
          : "";
        if (!this.accountSid || !this.authToken) this.editCredential = true;

        this.numbers = this.client.channels.twilio.whatsappNumbers;
        this.credentialCopy = toJSON({
          accountSid: this.accountSid,
          authToken: this.authToken,
        });
      }
    );
  }

  copyCode(id: string) {
    let copyText = document.querySelector("#" + id);
    (copyText as any).select();
    document.execCommand("copy");
    this.notificationService.success("general.copied-to-clipboard");
  }

  async saveCredential() {
    if (!this.accountSid || !this.authToken || this.authToken == "secret")
      return false;
    const succeed = await this.apiService.saveTwilioCredential(
      this.accountSid,
      this.authToken
    );
    if (succeed) this.notificationService.success("status.save.saved");
  }

  async addNumber() {
    if (
      this.newNumber == "" ||
      this.numbers.some((n) => n.includes(this.newNumber))
    ) {
      this.newNumber = "";
      return false;
    }
    const n = "whatsapp:+" + this.newNumber;
    const ref = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        messageHTML: `<div>${this.translateService.instant(
          "dialog.warning.confirm-add"
        )}<span class="highlight">${n}</span>? </div> `,
        destructive: false,
      },
    });

    ref.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        this.numbers.push(n);
        const succeed = await this.clientService.updateClient(
          {
            "channels.twilio.numbers": arrayUnion(n),
            "settings.conversation.channels": arrayUnion(Channel.WhatsApp),
          },
          true
        );
        if (succeed) this.notificationService.success("status.save.saved");
        this.newNumber = "";
      }
    });
  }

  async removeNumber(number: string) {
    if (number == "") return;
    const ref = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        messageHTML: `<small class="warning"><i class="material-icons">warning</i>${this.translateService.instant(
          "dialog.warning.cannot-undone"
        )}</small> <div>${this.translateService.instant(
          "dialog.warning.confirm-delete"
        )}<span class="highlight">${number}</span>? </div> `,
        destructive: true,
      },
    });

    ref.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        const succeed = await this.clientService.updateClient(
          {
            "channels.twilio.numbers": arrayRemove(number),
          },
          true
        );
        if (succeed) {
          this.notificationService.success("status.save.saved");
          if (
            this.numbers.length == 0 &&
            (!this.client.channels.fridayWhatsapp ||
              this.client.channels.fridayWhatsapp.instances.length == 0)
          )
            await this.clientService.removeChannel(Channel.WhatsApp);
        }
      }
    });
  }
}
