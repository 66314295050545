import { Component, Inject } from "@angular/core";
import { MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar";

@Component({
  selector: "app-notification",
  templateUrl: "./notification.component.html",
  styleUrls: ["./notification.component.scss"],
})
export class NotificationComponent {
  title: string;
  content: string;
  icon: string;
  state: string;
  upper = false;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {
    this.icon = data?.icon || "notifications";
    this.title = data?.title || "";
    this.content = data?.content || "";
    this.state = data?.state || "success";
    this.upper = this.icon != "notifications";
  }
}
