export enum HttpMethod {
  Post = "POST",
  Get = "GET",
  Delete = "DELETE",
  Put = "PUT",
}

export const HTTP_METHODS = [
  HttpMethod.Post,
  HttpMethod.Get,
  HttpMethod.Delete,
  HttpMethod.Put,
];

export enum HttpContentType {
  JSON = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
}

export const HttpHeaders = [
  "a-im",
  "accept",
  "accept-charset",
  "accept-encoding",
  "accept-language",
  "accept-datetime",
  "access-control-request-method",
  "access-control-request-headers",
  "authorization",
  "cache-control",
  "connection",
  "content-length",
  "content-type",
  "cookie",
  "date",
  "expect",
  "forwarded",
  "from",
  "host",
  "if-match",
  "if-modified-since",
  "if-none-match",
  "if-range",
  "if-unmodified-since",
  "max-forwards",
  "origin",
  "pragma",
  "proxy-authorization",
  "range",
  "referer",
  "te",
  "user-agent",
  "upgrade",
  "via",
  "warning",
  "dnt",
  "x-requested-with",
  "x-csrf-token",
];
