import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ConversationTag } from "@models/user";
import { Subscription } from "rxjs";
import { AuthService } from "../../../services/auth.service";
import { ClientService } from "../../../services/client.service";

@Component({
  selector: "app-manage-tags",
  templateUrl: "./manage-tags.component.html",
  styleUrls: ["./manage-tags.component.scss"],
})
export class ManageTagsComponent implements OnInit, OnDestroy {
  newColor = "#38509C";
  newTag = "";
  tags: ConversationTag[] = [];
  error = "";
  clientSub: Subscription;
  loading = false;

  constructor(
    private clientService: ClientService,
    private authService: AuthService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ManageTagsComponent>
  ) {}

  ngOnInit() {
    this.clientSub = this.authService.clientChanges.subscribe((client) => {
      this.tags = client.settings.conversation.tags || [];
      // console.log(this.tags);
    });
  }

  async ngOnDestroy() {
    if (this.clientSub) this.clientSub.unsubscribe();
    await this.save();
  }

  initialize() {
    this.newColor = "#38509C";
    this.newTag = "";
    this.error = "";
    this.loading = false;
  }

  async close() {
    await this.save();
    this.dialogRef.close();
  }

  async addTag() {
    this.newTag = this.newTag.trim();
    this.newColor = this.newColor.toUpperCase().trim();
    if (
      this.tags.some((t) => t.text.toLowerCase() == this.newTag.toLowerCase())
    ) {
      this.error = "error.tag.duplicate";
      return;
    }

    if (this.tags.some((t) => t.color == this.newColor)) {
      this.error = "error.tag.duplicate-color";
      return;
    }

    this.loading = true;
    await this.clientService.addTag(
      new ConversationTag({ color: this.newColor, text: this.newTag })
    );
    this.initialize();
  }

  deleteTag(i: number) {
    const tag = this.tags[i];
    return this.clientService.deleteTag(tag);
  }

  save() {
    // console.log(this.tags);
    return this.clientService.updateClient({
      "settings.conversation.tags": this.tags,
    });
  }
}
