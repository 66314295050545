import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Client } from "@models/user";
import { animations } from "@shared";
import { Subscription } from "rxjs";
import { AuthService } from "../../../../services/auth.service";

@Component({
  selector: "app-whatsapp",
  templateUrl: "./whatsapp.component.html",
  styleUrls: ["./whatsapp.component.scss"],
  animations: animations,
})
export class WhatsappComponent implements OnInit {
  client: Client;
  clientSub: Subscription;
  loading = false;
  adminView = false;
  isManager = false;

  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit(): void {
    this.adminView = this.authService.isSuperAdmin;
    this.isManager = this.authService.isManager;
    this.clientSub = this.authService.clientChanges.subscribe(
      async (client) => {
        if (!client) return;
        this.client = client;
      }
    );
  }

  isActiveRoute(url: string) {
    return this.router.url.indexOf(url) != -1;
  }
}
