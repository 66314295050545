import { Component, Input, OnInit } from "@angular/core";
import { SettingService } from "../../services/setting.service";

@Component({
  selector: "app-loader",
  templateUrl: "./loader.component.html",
  styleUrls: ["./loader.component.scss"],
})
export class LoaderComponent implements OnInit {
  @Input() size = 5;
  @Input() darkmode: boolean;

  constructor(private settingService: SettingService) {}

  ngOnInit() {
    if (this.darkmode == undefined)
      this.darkmode = this.settingService.settings.darkmode;
    // console.log(this.darkmode);
  }
}
