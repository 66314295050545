import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-trigger-item",
  templateUrl: "./trigger-item.component.html",
  styleUrls: ["./trigger-item.component.scss"],
})
export class TriggerItemComponent implements OnInit {
  @Input() selected = false;

  constructor() {}

  ngOnInit(): void {}
}
