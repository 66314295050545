import { environment as defaultEnvironment } from "./environment.default";
export const environment = {
  ...defaultEnvironment,
  ENV: "DEV",
  appTitle: "Friday Dev",
  apiEndpoint: "https://dev-api.getfriday.ai",
  host: "https://dev.getfriday.ai",
  firebase: {
    apiKey: "AIzaSyBfGaxbFr1mgd45gBQaYWlDBEUNwqoe7Jo",
    authDomain: "dev.getfriday.ai",
    databaseURL: "https://magicbot-238102.firebaseio.com",
    projectId: "magicbot-238102",
    storageBucket: "magicbot-238102.appspot.com",
    messagingSenderId: "59964886572",
    appId: "1:59964886572:web:0cfe4dfe4b8ece12",
    measurementId: "G-P1DR4JZTBC",
    clientId:
      "59964886572-norn66gqa813pakp7bnr9tfjalmhf0ug.apps.googleusercontent.com",
  },
  facebook: {
    APP_ID: "744089785967516",
  },
  fcmKey:
    "BMx5nZqWvjlmGnbfyjZ7nJqsV-hPAxholqSbGeyXIxuDMiditYBTSQlk3MKuNNEK6xYJ-Kok4xIUad6dV7wPTtM",
};
