<div class="page">
  <div class="page__header">
    <div class="page__container">
      <div class="page__header__tab">
        <!-- <a
          [routerLink]="['web']"
          [class.selected]="isActiveRoute('/channel/web')"
        >
          <img src="assets/img/logo.png" />
          <span translate>inbox.website</span>
        </a> -->
        <a
          [routerLink]="['facebook']"
          [class.selected]="isActiveRoute('/channel/facebook')"
        >
          <img src="assets/img/facebook-logo.svg" />
          <span>Facebook</span>
          <span class="separator"></span>
          <img src="assets/img/instagram-logo.svg" />
          <span>Instagram</span>
        </a>
        <a
          [routerLink]="['whatsapp']"
          [class.selected]="isActiveRoute('/channel/whatsapp')"
        >
          <img src="assets/img/whatsapp-logo.svg" />
          <span>WhatsApp</span>
        </a>
        <!-- <a
          [routerLink]="['twilio']"
          [class.selected]="isActiveRoute('/channel/twilio')"
        >
          <img src="assets/img/twilio-logo.svg" />
          <span>Twilio</span>
        </a> -->
      </div>
    </div>
  </div>

  <div class="page__content">
    <router-outlet></router-outlet>
  </div>
</div>
