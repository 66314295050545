import { trigger, transition, style, animate } from "@angular/animations";

export const animations = [
  trigger("smooth", [
    transition(":enter", [
      style({ opacity: 0 }),
      animate("0.2s ease-in", style({ opacity: 1 })),
    ]),
  ]),
];
