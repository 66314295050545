<div class="page">
  <div class="page__header">
    <div class="page__container">
      <div class="page__header__tab">
        <a
          [routerLink]="['account']"
          [class.selected]="isActiveRoute('/setting/account')"
        >
          <i class="material-icons">account_box</i>
          <span translate>nav.account</span>
        </a>

        <a
          *ngIf="!mobile"
          [routerLink]="['ai']"
          [class.selected]="isActiveRoute('/setting/ai')"
        >
          <i class="material-icons">memory</i>
          <span class="no-transform" translate>nav.ai</span>
        </a>

        <a
          [routerLink]="['organization']"
          [class.selected]="isActiveRoute('/setting/organization')"
        >
          <i class="material-icons">business</i>
          <span translate>nav.organization</span>
        </a>
      </div>
    </div>
  </div>

  <div class="page__content">
    <router-outlet></router-outlet>
  </div>
</div>
