import { isEmpty } from "lodash";
import { v4 as uuid } from "uuid";
import { toJSON } from "../../../helper";
import { Attachment } from "../../../messenger/attachment";
import {
  AttachmentTranslation,
  Language,
  TextTranslation,
} from "../../../misc";
import { ChatflowInput, ChatflowInputParams } from "./input";
import {
  API_RESULT,
  CALCULATION_RESULT,
  ChatflowApiResponse,
  ChatflowApiResponseParams,
  ChatflowCalculationResponse,
  ChatflowCalculationResponseParams,
} from "./response";
import { ChatflowStaffAction, ChatflowStaffActionParams } from "./staff";

export enum ChatflowStepType {
  Input = "input",

  // Response
  Message = "message",
  API = "api",
  Calculation = "calculation",

  // Wait for staff actions
  Staff = "staff",

  // WaitForTimer = "wait_for_timer",
  // WaitForWebhook = "wait_for_webhook",

  // Automated
  Export = "export",

  Chatflow = "chatflow",
}

export const CHATFLOW_RESPONSE_STEPS = [
  ChatflowStepType.Message,
  ChatflowStepType.API,
  ChatflowStepType.Calculation,
];

export interface ChatflowStepParams {
  id?: string;
  type?: ChatflowStepType;
  next?: string;
  text?: TextTranslation;
  attachments?: AttachmentTranslation;
  input?: ChatflowInputParams;
  api?: ChatflowApiResponseParams;
  calculation?: ChatflowCalculationResponseParams;
  staff?: ChatflowStaffActionParams;
  variableName?: string;
  redirect?: {
    chatflowId: string;
    stepId?: string;
  };
}

export class ChatflowStep {
  id: string;
  type: ChatflowStepType;
  text: TextTranslation;
  attachments: AttachmentTranslation;
  next?: string;
  input?: ChatflowInput;
  api?: ChatflowApiResponse;
  calculation?: ChatflowCalculationResponse;
  staff?: ChatflowStaffAction;
  variableName?: string;
  redirect?: {
    chatflowId: string;
    stepId?: string;
  };

  constructor(params: ChatflowStepParams = {}) {
    this.id = params.id || uuid();
    this.type = params.type || ChatflowStepType.Message;
    this.text = params.text || {};
    this.attachments = params.attachments || {};

    switch (this.type) {
      case ChatflowStepType.Input:
        this.input = new ChatflowInput(params.input);
        break;
      case ChatflowStepType.API:
        this.api = new ChatflowApiResponse(params.api);
        this.variableName = params.variableName || API_RESULT;
        break;
      case ChatflowStepType.Calculation:
        this.calculation = new ChatflowCalculationResponse(params.calculation);
        this.variableName = params.variableName || CALCULATION_RESULT;
        break;
      case ChatflowStepType.Staff:
        this.staff = new ChatflowStaffAction(params.staff);
        break;
    }
    if (params.next) this.next = params.next;
    if (params.variableName) this.variableName = params.variableName;
    if (params.redirect) this.redirect = params.redirect;
  }

  get json() {
    return toJSON(this);
  }

  get hasOptions(): boolean {
    if (
      this.type != ChatflowStepType.Input ||
      !this.input ||
      isEmpty(this.input?.options)
    )
      return false;
    return true;
  }

  get variableNameValid(): boolean {
    if (!this.variableName) return true;
    return !this.variableName.match(/\W/g);
  }

  optionTexts(language: Language): string[] {
    if (!this.hasOptions) return [];
    return this.input!.optionTexts(language);
  }

  messageText(language: Language): string {
    let text = "";
    const optionTexts = this.optionTexts(language);
    if (isEmpty(this.text) && isEmpty(optionTexts)) return text;
    text =
      this.text[language] ||
      this.text[
        Object.keys(this.text).find((l) => l != language) || Language.en
      ] ||
      this.text[Language.zh] ||
      "";
    if (isEmpty(optionTexts)) return text;
    text += optionTexts.reduce(
      (acc, cur, i) => (acc += `\n${i + 1}) ${cur}`),
      ""
    );
    return text;
  }

  messageAttachments(language: Language): Attachment[] {
    if (isEmpty(this.attachments)) return [];
    return (
      this.attachments[language] ||
      this.attachments[
        Object.keys(this.attachments).find((l) => l != language) || Language.en
      ] ||
      this.attachments[Language.zh] ||
      []
    );
  }
}
