import * as joint from "jointjs";
import { v4 as uuid } from "uuid";

const defaultWidth = 300;
const defaultHeight = 88;
const defaultX = 50;
const defaultY = 50;
const defaultPadding = 16;
const defaultFontSize = 14;

export class GraphStep extends joint.shapes.standard.Rectangle {
  width: number;
  height: number;
  title: string;
  description: string;
  icon: string;

  constructor(params: {
    title: string;
    description: string;
    icon: string;
    id?: string;
    containerWidth?: number;
    x?: number;
    y?: number;
    width?: number;
    height?: number;
    padding?: number;
    fontSize?: number;
  }) {
    params.id = params.id || uuid();
    params.width = params.width || defaultWidth;
    params.height = params.height || defaultHeight;
    params.padding = params.padding || defaultPadding;
    params.fontSize = params.fontSize || defaultFontSize;

    super({
      position: {
        x:
          params.x ||
          (params.containerWidth
            ? Math.round((params.containerWidth * 0.7) / 2) -
              (params.width || defaultWidth)
            : defaultX),
        y: params.y || defaultY,
      },
      size: {
        width: params.width || defaultWidth,
        height: params.height || defaultHeight,
      },
      attrs: {
        body: {
          "data-id": params.id,
          rx: 4,
          ry: 4,
          fill: "white",
          strokeWidth: 0,
          filter: {
            name: "dropShadow",
            args: {
              dx: 0,
              dy: 2,
              blur: 6,
            },
          },
        },
      },
      markup: [
        {
          tagName: "rect",
          className: "chatflow__step__wrapper",
          selector: "body",
        },
        {
          tagName: "rect",
          attributes: {
            x: params.padding,
            y: params.padding,
            height: params.height - 2 * params.padding,
            width: params.height - 2 * params.padding,
            rx: 4,
            ry: 4,
            fill: "#E9EBFF",
          },
        },
        {
          tagName: "image",
          attributes: {
            x: 1.5 * params.padding,
            y: 1.5 * params.padding,
            height: params.height - 3 * params.padding,
            width: params.height - 3 * params.padding,
            href: params.icon,
          },
        },
        {
          tagName: "text",
          className: "chatflow__step__title",
          style: {
            "font-size": `${params.fontSize}px`,
          },
          attributes: {
            x: params.height,
            y: params.padding + params.fontSize + 2,
            height: params.fontSize,
            width: params.width - params.height - params.padding,
          },
          textContent: params.title,
        },
        {
          tagName: "path",
          attributes: {
            fill: "transparent",
            strokeWidth: 0,
            id: "text-path",
            d: `M${params.height},${Math.ceil(
              params.padding + 2.5 * params.fontSize
            )} H${params.width - params.padding} M${params.height},${Math.ceil(
              params.padding + 3.5 * params.fontSize
            )} H${params.width - params.padding}`,
          },
        },
        {
          tagName: "text",
          children: [
            {
              tagName: "textPath",
              className: "chatflow__step__description",
              style: {
                "font-size": `${Math.round(params.fontSize * 0.8)}px`,
              },
              attributes: {
                href: "#text-path",
              },
              textContent: params.description,
            },
          ],
        },
      ],
    });
    this.title = params.title;
    this.description = params.description;
    this.width = params.width || 300;
    this.height = params.height || 80;
  }
}

export const initGraph = (
  element: HTMLElement
): [joint.dia.Graph, joint.dia.Paper] => {
  const graph = new joint.dia.Graph();
  const paper = new joint.dia.Paper({
    el: element,
    model: graph,
    width: element.parentElement.offsetWidth * 2,
    height: element.parentElement.offsetHeight * 2,
  } as any);
  return [graph, paper];
};
