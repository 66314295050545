<div class="page__container" *ngIf="client" [@smooth]>
  <div class="page__content__title">
    <h1 translate>general.users</h1>
    <div class="actions">
      <button
        class="text-button bg-primary"
        (click)="createSubAccount()"
        *ngIf="allowAddUser()"
      >
        <i class="material-icons">add</i>
        <span translate>user.add-user</span>
      </button>
    </div>
  </div>

  <table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>
        <span translate>user.displayName</span>
      </th>
      <td mat-cell *matCellDef="let element">
        <span>{{ element.info.displayName }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>
        <span translate>user.email</span>
      </th>
      <td mat-cell *matCellDef="let element">
        <span>{{ element.info.email }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef>
        <span translate>general.createdAt</span>
      </th>
      <td mat-cell *matCellDef="let element">
        <span>{{ formatDate(element.createdAt) }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let user; let i = index">
        <a
          *ngIf="allowEditUser(user)"
          class="icon-button"
          mat-button
          [matMenuTriggerFor]="actionMenu"
          [matMenuTriggerData]="{ user }"
        >
          <i class="material-icons">more_vert</i>
        </a>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns"></tr>
  </table>

  <mat-paginator
    [hidden]="!client.users"
    [pageSize]="10"
    [length]="client.users ? client.users.length : 0"
    showFirstLastButtons
  >
  </mat-paginator>

  <mat-menu #actionMenu="matMenu" xPosition="after" yPosition="below">
    <ng-template matMenuContent let-user="user">
      <button
        class="text-button error"
        mat-menu-item
        (click)="deleteUser(user.id)"
      >
        <i class="material-icons">delete</i>
        <span translate>general.delete</span>
      </button>
    </ng-template>
  </mat-menu>
</div>
<app-loader *ngIf="!client"></app-loader>
