<div class="dialog column">
  <div class="dialog__title" translate>facebook.select-page</div>
  <div class="fb-page row">
    <div *ngFor="let page of pages" class="fb-page__wrapper">
      <input type="radio" [(ngModel)]="selectedPage" [value]="page.id" [id]="page.id">
      <label [for]="page.id" class="fb-page__item" [class.checked]="selectedPage==page.id"
        [style.background-image]="profilePicture(page)"></label>
      <label [for]="page.id" class="fb-page__title" [class.checked]="selectedPage==page.id">{{page.name}}</label>
    </div>
  </div>

  <div class="dialog__action row">
    <button class="text-button flat" mat-button (click)="close()" translate>
      general.cancel
    </button>
    <button class="text-button bg-success" mat-button [mat-dialog-close]="selectedPage" translate>
      general.confirm
    </button>
  </div>
</div>