import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import {
  Attachment,
  AttachmentType,
  FRIDAY_ID,
  Message,
} from "@models/messenger";
import { filenameFromURL, formatSize, formatTime } from "@utility";

@Component({
  selector: "app-message-attachment",
  templateUrl: "./message-attachment.component.html",
  styleUrls: ["./message-attachment.component.scss"],
})
export class MessageAttachmentComponent implements OnInit {
  @Input() message: Message;
  @Input() sourceId: string;
  @Input() previousSenderName: string;

  AttachmentType: typeof AttachmentType = AttachmentType;
  FRIDAY_ID = FRIDAY_ID;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {}

  get timestamp() {
    return formatTime(this.message.timestamp);
  }

  fileSize(size: number) {
    return formatSize(size);
  }

  fileName(url: string) {
    return filenameFromURL(url);
  }

  toBase64(attachment: Attachment) {
    if (!attachment.mimetype || !attachment.data) return "";
    return this.sanitizer.bypassSecurityTrustUrl(
      `data:${attachment.mimetype};base64,${attachment.data}`
    );
  }

  toMediaSource(attachment: Attachment) {
    return attachment.url || this.toBase64(attachment);
  }

  attachmentTracker(index: number, attachment: Attachment) {
    return `${index.toString()}-${attachment.id}`;
  }
}
