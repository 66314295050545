import { Component, OnDestroy, OnInit } from "@angular/core";
import { Conversation } from "@models/messenger";
import { animations } from "@shared";
import { Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { ConversationService } from "../../services/conversation.service";

@Component({
  selector: "app-mobile-inbox",
  templateUrl: "./mobile-inbox.component.html",
  styleUrls: ["./mobile-inbox.component.scss"],
  animations: animations,
})
export class MobileInboxComponent implements OnInit, OnDestroy {
  conversationSub: Subscription;
  conversations: Conversation[] = null;

  constructor(public conversationService: ConversationService) {}

  ngOnInit() {
    this.conversationSub = this.conversationService.conversationsChanges
      .pipe(debounceTime(1000))
      .subscribe((conversations) => {
        if (!conversations) return;
        const unreadConversations = conversations
          .filter((c) => c.unread)
          .sort((a, b) => b.updatedAt - a.updatedAt);
        const readConversations = conversations
          .filter((c) => !c.unread)
          .sort((a, b) => b.updatedAt - a.updatedAt);

        this.conversations = [...unreadConversations, ...readConversations];
        // console.log(this.conversations);
      });
  }

  ngOnDestroy() {
    if (this.conversationSub) this.conversationSub.unsubscribe();
    this.conversationService.allTypingOff();
    this.conversations = null;
  }

  conversationTracker(index: number, conversation: Conversation) {
    return `${index}-${conversation.id}`;
  }
}
