<div class="page">
  <div class="page__header">
    <h1 translate>nav.inbox</h1>
  </div>
  <div class="page__content">
    <div class="inbox" *ngIf="conversations">
      <div class="inbox__contacts" [@smooth]>
        <app-contact-item
          *ngFor="
            let conversation of conversations;
            let i = index;
            trackBy: conversationTracker
          "
          [routerLink]="conversation.sourceId"
          [conversation]="conversation"
          [@smooth]
        ></app-contact-item>

        <div class="end-indicator" *ngIf="conversations.length > 0">
          <span translate>general.end</span>
        </div>
      </div>
    </div>
  </div>
</div>
