<div class="auth" *ngIf="!loading">
  <div class="auth__wrapper">
    <div class="auth__feature">
      <div class="logo">
        <img src="assets/img/logo-type.png" alt="friday a.i. logo" />
      </div>

      <div class="feature-img row">
        <img
          src="assets/img/login-feature.png"
          alt="friday a.i. feature image"
        />
      </div>
    </div>

    <div class="auth__form">
      <button
        class="flat language"
        mat-button
        [matMenuTriggerFor]="languageMenu"
      >
        <i class="material-icons">language</i>
        <span>{{ "general." + language | translate }}</span>
      </button>

      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<mat-menu #languageMenu="matMenu" xPosition="after" yPosition="below">
  <button
    class="center language"
    *ngFor="let language of languages"
    mat-menu-item
    (click)="setLanguage(language)"
  >
    {{ "general." + language | translate }}
  </button>
</mat-menu>

<app-loader *ngIf="loading"></app-loader>
