import { Pipe, PipeTransform } from "@angular/core";
import { Channel, Conversation } from "@models/messenger";

@Pipe({
  name: "channel",
})
export class ChannelPipe implements PipeTransform {
  transform(value: Conversation[], ...args: any[]): Conversation[] {
    if (!value || value.length == 0) return [];
    const conversations = value;
    const channel = args[0];
    const channelSource = args[1];

    switch (channel) {
      case Channel.All:
        return conversations.filter((c) => !c.archived);
      case Channel.Archived:
        return conversations.filter((c) => c.archived);
      default:
        return conversations.filter(
          (c) =>
            !c.archived &&
            (channelSource == "all"
              ? c.channel == channel
              : c.channel == channel && c.me == channelSource)
        );
    }
  }
}
