import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AutopilotService } from "@models/autopilot";
import {
  Channel,
  Conversation,
  Message,
  toConversationId,
} from "@models/messenger";
import { HttpMethod } from "@models/misc";
import { Client, ClientPermission } from "@models/user";
import { NotificationService } from "@shared";
import { hashShort, to } from "@utility";
import { firstValueFrom } from "rxjs";
import { environment } from "../../environments/environment";
import { AuthService } from "./auth.service";

const apiEndpoint = environment.apiEndpoint;

@Injectable({
  providedIn: "root",
})
export class ApiService {
  private _client: Client;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private notificationService: NotificationService
  ) {
    this.authService.clientChanges.subscribe(
      (client) => (this._client = client)
    );
  }

  async request(
    method: HttpMethod,
    url: string,
    body: any,
    errorMessage: string,
    simpleResponse = true,
    notifyError = true
  ) {
    const [err, res] = await to(
      firstValueFrom(
        this.http.request(method, apiEndpoint + url, {
          body,
          headers: new HttpHeaders().set(
            "authorization",
            `Bearer ${environment.FRIDAY_API_TOKEN}`
          ),
        })
      )
    );
    if (notifyError) {
      if (err) return this.notificationService.error(errorMessage, false);
      if (!(res as any).success)
        return this.notificationService.error(errorMessage, false);
    }
    // console.log(err, res);
    if (err) return false;
    if (!simpleResponse) console.log(method, url, res);
    return simpleResponse ? true : res;
  }

  async post(
    url: string,
    body: any,
    m: string,
    simpleResponse = true,
    notifyError = true
  ) {
    return this.request(
      HttpMethod.Post,
      url,
      body,
      m,
      simpleResponse,
      notifyError
    );
  }

  async delete(
    url: string,
    body: any,
    m: string,
    simpleResponse = true,
    notifyError = true
  ) {
    return this.request(
      HttpMethod.Delete,
      url,
      body,
      m,
      simpleResponse,
      notifyError
    );
  }

  async getClientStat(period = 7) {
    const body = { clientId: this._client.id, period };
    return this.post("/statistic/client", body, "error.data.get", false, false);
  }

  async getUploadForm(path: string): Promise<{
    url: string;
    fields: { [key: string]: string };
    download: string;
  }> {
    const body = { path };
    const [err, res] = await to(
      this.post("/storage/upload/form", body, "error.data.get", false, false)
    );
    if (err || !res?.url || !res?.fields) return null;
    return { url: res.url, fields: res.fields, download: res.download };
  }

  async deleteObject(path: string) {
    const body = { path };
    return this.post("/storage/delete", body, "error.data.delete", true, false);
  }

  async deleteFolder(folder: string) {
    const body = { folder };
    return this.post(
      "/storage/delete/folder",
      body,
      "error.data.delete",
      true,
      false
    );
  }

  async getFacebookPosts() {
    const body = { clientId: this._client.id };
    return this.post("/facebook/posts", body, "error.data.get", false);
  }

  async getFacebookPostById(pageId: string, postId: string) {
    const body = { clientId: this._client.id, pageId, postId };
    return this.post("/facebook/post", body, "error.data.get", false);
  }

  async saveCredential(
    service: AutopilotService,
    userId: string,
    accessToken: string
  ) {
    const body = {
      clientId: this._client.id,
      credential: { service, userId, accessToken },
    };
    return this.post("/user/credential", body, "error.data.save", false);
  }

  async saveTwilioCredential(accountSid: string, authToken: string) {
    const body = {
      clientId: this._client.id,
      accountSid,
      authToken,
    };
    return this.post("/twilio/credential", body, "error.data.save", false);
  }

  async createSubAccount(
    email: string,
    password: string,
    displayName: string,
    permission = ClientPermission.Operator
  ) {
    const body = {
      parentId: this._client.id,
      email,
      password,
      displayName,
      permission,
    };
    const res = await this.post(
      "/user/subaccount/create",
      body,
      "error.data.update",
      false,
      false
    );
    if (res && res.error)
      return this.notificationService.error(
        "error.firebase." + res.error,
        false
      );
    return true;
  }

  async deleteUser(uid: string) {
    const body = {
      parentId: this._client.id,
      uid,
    };
    return this.post("/user/subaccount/delete", body, "error.data.delete");
  }

  async createConversation(conversation: Conversation) {
    const body = { clientId: this._client.id, conversation: conversation.json };
    return this.post("/conversation", body, "error.conversation.create");
  }

  async deleteConversation(conversationId: string) {
    const body = { clientId: this._client.id, conversationId };
    return this.delete("/conversation", body, "error.conversation.delete");
  }

  async reply(message: Message) {
    const body = {
      clientId: this._client.id,
      conversationId: toConversationId(this._client.id, this._client.id),
      message,
    };
    return this.post("/message/reply", body, "error.message.reply");
  }

  async createBuiltinTask(builtinId: string, query: string) {
    const body = { builtinId, query };
    return this.post("/task/builtin/create", body, "error.knowledge.training");
  }

  async submitTask(
    taskId: string,
    answerIds: string[],
    replyOnly = false
  ): Promise<boolean> {
    const body = {
      taskId,
      record: {
        answerIds,
        taskforceId: this._client.id,
      },
      replyOnly,
    };
    return this.post("/task/submit", body, "error.task.submit");
  }

  async saveFBToken(
    userId: string,
    userAccessToken: string,
    pageId: string,
    pageName: string,
    picture: string
  ): Promise<boolean> {
    const body = {
      clientId: this._client.id,
      pageId,
      pageName,
      picture,
      userId,
      userAccessToken,
    };
    return this.post("/facebook/token", body, "error.facebook.save-token");
  }

  async unsubscribeFB(pageId: string): Promise<boolean> {
    const body = {
      clientId: this._client.id,
      pageId,
    };
    const succeed = await this.post(
      "/facebook/unsubscribe",
      body,
      "error.facebook.remove-page"
    );
    if (succeed)
      return this.notificationService.success("notification.data.saved", true);
    return false;
  }

  async connectInstagram(
    pageId: string,
    instagramId: string,
    pageAccessToken: string
  ): Promise<boolean> {
    const body = {
      clientId: this._client.id,
      pageId,
      instagramId,
      pageAccessToken,
    };
    const succeed = await this.post(
      "/facebook/instagram/connect",
      body,
      "error.instagram.connect"
    );
    if (succeed)
      return this.notificationService.success("notification.data.saved", true);
    return false;
  }

  async sendMessage(
    sourceId: string,
    message: Message,
    me: string,
    channel: Channel
  ): Promise<boolean> {
    const body = {
      clientId: this._client.id,
      sourceId,
      message: message.json,
      me,
      channel,
    };
    return this.post("/message/send", body, "error.message.send");
  }

  async createWhatsappInstance() {
    const body = {
      clientId: this._client.id,
    };
    return this.post(
      "/whatsapp/instance/start",
      body,
      "error.whatsapp.create-instance",
      false
    );
  }

  async closeWhatsappInstance(instanceId: string) {
    const body = { clientId: this._client.id, instanceId };
    return this.post(
      "/whatsapp/instance/close",
      body,
      "error.whatsapp.close-instance"
    );
  }

  getGoogleOauthUrl() {
    const url = "https://accounts.google.com/o/oauth2/v2/auth";
    const params = new HttpParams({
      fromObject: {
        scope: environment.gapi.scopes.join(" "),
        access_type: "offline",
        include_granted_scopes: "false",
        response_type: "code",
        state: hashShort(this._client.id),
        redirect_uri: `${environment.host}/oauth`,
        client_id: environment.firebase.clientId,
      },
    });
    return `${url}?${params.toString()}`;
  }

  getGoogleOauthToken(code: string) {
    const body = { clientId: this._client.id, code };
    return this.post("/google/token", body, "error.knowledge.delete");
  }

  async getWhatsappAccounts(userAccessToken: string): Promise<string[]> {
    const body = {
      clientId: this._client.id,
      userAccessToken,
    };
    const res = await this.post(
      "/facebook/whatsapp/accounts",
      body,
      "error.instagram.connect",
      false
    );
    console.log(res);
    if (res && res.accountIds) return res.accountIds;
    return [];
  }
}
