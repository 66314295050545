<div
  class="status"
  [class.success]="success"
  [class.warn]="warn"
  [class.error]="error"
>
  <span
    class="status__signal"
    *ngIf="tooltip"
    [matTooltip]="tooltip"
    matTooltipPosition="above"
  >
  </span>

  <span class="status__signal" *ngIf="!tooltip"> </span>
  <label *ngIf="text">{{ text }}</label>
</div>
