<div>
  <label translate>auth.continue</label>

  <div class="oauth">
    <button class="text-button" (click)="signIn(AuthProvider.Google)">
      <img src="assets/img/google-logo.svg" alt="google sign in" />
      <span>google</span>
    </button>

    <button class="text-button" (click)="signIn(AuthProvider.Facebook)">
      <img src="assets/img/facebook-logo.svg" alt="facebook sign in" />
      <span>facebook</span>
    </button>
  </div>

  <p translate>general.or</p>
</div>

<div class="auth__form__row column">
  <label translate>auth.email</label>
  <input type="email" [(ngModel)]="email" />
</div>

<div class="auth__form__row column">
  <label translate>auth.password</label>
  <input
    type="password"
    [(ngModel)]="password"
    (keydown.enter)="signIn(AuthProvider.Email)"
  />
</div>

<button
  class="text-button bg-primary gradient-dark"
  (click)="signIn(AuthProvider.Email)"
  translate
  [disabled]="email == '' || password == ''"
>
  auth.sign-in
</button>

<div class="instruction">
  <div class="row">
    <span translate>auth.no-account</span>
    <a [routerLink]="['/auth/signup']" translate>auth.sign-up</a>
  </div>

  <div class="row">
    <span translate>auth.forgot-password</span>
    <a [routerLink]="['/auth/reset']" translate>auth.reset-password</a>
  </div>
</div>
