import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { LivechatSetting } from "@models/livechat";
import { Language } from "@models/misc";
import { Client } from "@models/user";
import { TranslateService } from "@ngx-translate/core";
import { ConfirmationDialogComponent, NotificationService } from "@shared";
import en from "@translation/en.json";
import zh from "@translation/zh.json";
import { Subscription } from "rxjs";
import { environment } from "../../../../../environments/environment";
import { AuthService } from "../../../../services/auth.service";
import { ClientService } from "../../../../services/client.service";

@Component({
  selector: "app-livechat",
  templateUrl: "./livechat.component.html",
  styleUrls: ["./livechat.component.scss"],
})
export class LivechatComponent implements OnInit, OnDestroy {
  client: Client;
  clientSub: Subscription;
  selected = new FormControl(0);
  languages: Language[];
  config: LivechatSetting;
  url = "";
  code = "";
  loading = false;

  constructor(
    private clientService: ClientService,
    private authService: AuthService,
    private translateService: TranslateService,
    public dialog: MatDialog,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.clientSub = this.authService.clientChanges.subscribe(
      async (client) => {
        if (client) {
          this.client = client;
          this.languages = [this.client.primaryLanguage];
          this.config = await this.clientService.getLivechatSetting();
          if (!this.config)
            this.config = new LivechatSetting({
              clientId: this.client.id,
              heading: {
                zh: zh.livechat["default-heading"],
                en: en.livechat["default-heading"],
              },
              description: {
                zh: zh.livechat["default-description"],
                en: en.livechat["default-description"],
              },
            });
          console.log(this.config);

          this.code = `<script type="text/javascript">\n window.fridayToken="${this.config.hash}";\n window.fridayURL="${environment.livechatEndpoint}";\n</script>\n<script async src="${environment.livechatEndpoint}/static/livechat.min.js"></script>`;
        }
      }
    );
  }

  ngOnDestroy() {
    if (this.clientSub) this.clientSub.unsubscribe();
  }

  selectTab(event) {
    this.selected.setValue(event);
  }

  addUrl() {
    if (this.url != "" && !this.config.urlWhitelist.includes(this.url)) {
      const ref = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          messageHTML: `<div>${this.translateService.instant(
            "dialog.warning.confirm-add"
          )}<span class="highlight">${this.url}</span>? </div> `,
          destructive: false,
        },
      });

      ref.afterClosed().subscribe(async (confirmed) => {
        if (confirmed) {
          this.config.urlWhitelist.push(this.url);
          this.url = "";
          await this.clientService.saveLivechatSetting(this.config);
        }
      });
    }
  }

  removeUrl(url: string) {
    const ref = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        messageHTML: `<small class="warning"><i class="material-icons">warning</i>${this.translateService.instant(
          "dialog.warning.cannot-undone"
        )}</small> <div>${this.translateService.instant(
          "dialog.warning.confirm-delete"
        )}<span class="highlight">${url}</span>? </div> `,
        destructive: true,
      },
    });

    ref.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        this.config.urlWhitelist = this.config.urlWhitelist.filter(
          (u) => u != url
        );
        await this.clientService.saveLivechatSetting(this.config);
      }
    });
  }

  copyCode() {
    let copyText = document.querySelector("#friday-code-snippet");
    (copyText as any).select();
    document.execCommand("copy");
    this.notificationService.success("general.copied-to-clipboard");
  }

  async save() {
    if (this.config.urlWhitelist.length == 0)
      return this.notificationService.warn("livechat.security-required", false);
    this.loading = true;
    const succeed = await this.clientService.saveLivechatSetting(this.config);
    if (succeed) this.notificationService.success("status.save.saved");
    this.loading = false;
  }
}
