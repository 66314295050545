import { v4 as uuid } from "uuid";
import { toJSON } from "../helper";
import { REGEX_NUMBER_ID } from "../misc/regex";

export interface CustomerParams {
  id?: string;
  name?: string;
  firstName?: string;
  lastName?: string;
  profilePic?: string;
  locale?: string;
  timezone?: string;
  gender?: string;
  email?: string;
  tags?: string[];
  devices?: string[];
  phoneNumber?: string;
  notes?: string[];
}

export class Customer {
  id: string;
  tags: string[];
  notes: string[];
  name?: string;
  firstName?: string;
  lastName?: string;
  profilePic?: string;
  locale?: string;
  timezone?: string;
  gender?: string;
  email?: string;
  devices?: string[];
  phoneNumber?: string;

  constructor(params: CustomerParams = {}) {
    this.id = params.id || uuid();
    this.notes = params.notes || [];
    this.tags = params.tags || [];
    if (params.firstName) this.firstName = params.firstName;
    if (params.lastName) this.lastName = params.lastName;
    if (params.name) this.name = params.name;
    if (params.profilePic) this.profilePic = params.profilePic;
    if (params.locale) this.locale = params.locale;
    if (params.timezone) this.locale = params.timezone;
    if (params.gender) this.gender = params.gender;
    if (params.email) this.email = params.email;
    if (params.phoneNumber) this.phoneNumber = params.phoneNumber;
    if (params.devices) this.devices = params.devices;
  }

  get json() {
    return toJSON(this);
  }

  get fullname(): string {
    return `${this.firstName || ""} ${this.lastName || ""}`.trim();
  }

  get displayName(): string {
    return this.name || this.fullname || this.phoneNumber || this.email || "";
  }

  get facebookMention(): string {
    return this.id && REGEX_NUMBER_ID.test(this.id) ? `@[${this.id}] ` : "";
  }
}
