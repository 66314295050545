import { Component, OnInit } from "@angular/core";
import { Client } from "@models/user";
import { animations } from "@shared";
import { AuthService } from "../../services/auth.service";
import { ClientService } from "../../services/client.service";

@Component({
  selector: "app-container",
  templateUrl: "./container.component.html",
  styleUrls: ["./container.component.scss"],
  animations: animations,
})
export class ContainerComponent implements OnInit {
  client: Client;
  routes = [
    { name: "dashboard", url: "/dashboard", icon: "dashboard" },
    { name: "inbox", url: "/inbox", icon: "mail" },
    { name: "channel", url: "/channel", icon: "hub" },
    // { name: "autopilot", url: "/autopilot/trigger/edit/123", icon: "rocket" },
    // { name: "task", url: "/task", icon: "headset_mic" },
    // { name: "chatform", url: "/chatform", icon: "list_alt" },
    { name: "setting", url: "/setting", icon: "settings" },
  ];
  initialized = false;

  constructor(
    public authService: AuthService,
    private clientService: ClientService
  ) {}

  async ngOnInit() {
    this.client = this.authService.client;
    if (!this.authService.isSuperAdmin)
      await this.clientService.initializeNotification();
    this.initialized = true;
  }
}
