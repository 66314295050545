<div class="page__container" *ngIf="client" [@smooth]>
  <!-- <div class="page__content__title">
    <h1 class="beta" translate>whatsapp.title</h1>
  </div> -->

  <div class="channel-header">
    <div class="row">
      <div class="channel-description">
        <ul>
          <li translate>whatsapp.instruction.1</li>
          <li translate>whatsapp.instruction.2</li>
          <li translate>whatsapp.instruction.3</li>
          <li translate>whatsapp.instruction.4</li>
        </ul>
      </div>

      <div class="channel-image">
        <img src="assets/img/logo.png" />
      </div>
    </div>

    <div class="form__row">
      <div class="form__label">
        <i class="material-icons">devices</i>
        <span translate>whatsapp.quota</span>
      </div>
      <div class="form__field">
        <input type="number" [value]="quota" readonly />
      </div>
      <!-- <div class="form__action" *ngIf="!adminView">
        <button class="text-button bg-primary gradient-dark">
          <span translate>whatsapp.buy-quota</span>
        </button>
      </div> -->
    </div>
  </div>

  <div class="channel-details">
    <div class="channel-details-title">
      <h2 translate>whatsapp.connected-numbers</h2>
      <div class="action">
        <app-loader-button
          (click)="startInstance()"
          *ngIf="quota > 0 && isManager"
          [disabled]="quota == 0 || loading || !isManager"
          [loading]="loading"
          icon="add"
          translation="whatsapp.number"
          [success]="true"
        ></app-loader-button>
      </div>
    </div>

    <div class="channel-item" *ngFor="let instance of instances">
      <ng-container *ngIf="instance.id">
        <div class="info">
          <div class="form__row">
            <div class="form__label">
              <i class="material-icons">dialpad</i>
              <span translate>whatsapp.number</span>
            </div>
            <div class="form__field">
              <div>{{ instance.number || "-" }}</div>
            </div>
          </div>

          <div class="form__row">
            <div class="form__label">
              <i class="material-icons">settings_ethernet</i>
              <span translate>general.status</span>
            </div>
            <div class="form__field">
              <div class="status" [ngClass]="statusClass(instance.state)">
                <span class="status__signal"></span>
              </div>
              <div>
                {{
                  instance.state
                    ? ("whatsapp.state." + instance.state | translate)
                    : ("whatsapp.state.LOADING" | translate)
                }}
              </div>
            </div>
          </div>
        </div>

        <div class="picture">
          <app-loader
            [size]="3"
            *ngIf="!instance.qr && instance.state != 'CONNECTED'"
          ></app-loader>
          <qrcode
            [qrdata]="instance.qr"
            [width]="200"
            *ngIf="instance.qr && instance.state != 'CONNECTED'"
          ></qrcode>
          <img
            [src]="instance.profilePic || 'assets/img/whatsapp-logo.svg'"
            *ngIf="instance.state == 'CONNECTED'"
            width="200"
            height="200"
          />
        </div>

        <div class="action">
          <button
            class="icon-button error"
            (click)="closeInstance(instance)"
            *ngIf="
              isManager && !loading && instance.id && instance.id != 'QUEUE'
            "
          >
            <i class="material-icons">delete_forever</i>
          </button>
        </div>
      </ng-container>

      <app-loader [size]="3" *ngIf="!instance.id"></app-loader>
    </div>
  </div>
</div>
