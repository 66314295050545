export enum UserType {
  Admin = "admin",
  Client = "client",
  Taskforce = "taskforce",
}

export enum ClientPermission {
  Operator = 0,
  Manager = 90,
  Admin = 100,
}

export enum AdminPermission {
  Operator = 0,
  Admin = 90,
  SuperAdmin = 100,
}

export enum TaskforcePermission {
  Operator = 0,
}
