import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { NotFoundComponent, PrivacyComponent } from "@shared";
import { environment } from "../environments/environment";
import { AdminGuard } from "./guards/admin.guard";
import { AuthGuard, Guard } from "./guards/auth.guard";
import { MobileContainerComponent } from "./mobile/mobile-container/mobile-container.component";
import { MobileInboxChatroomComponent } from "./mobile/mobile-inbox/mobile-inbox-chatroom/mobile-inbox-chatroom.component";
import { MobileInboxComponent } from "./mobile/mobile-inbox/mobile-inbox.component";
import { ContainerComponent } from "./ui/container/container.component";
import { GoogleOauthComponent } from "./ui/oauth/google-oauth/google-oauth.component";
import { AdminComponent } from "./ui/pages/admin/admin.component";
import { AuthComponent } from "./ui/pages/auth/auth.component";
import { ResetComponent } from "./ui/pages/auth/reset/reset.component";
import { SigninComponent } from "./ui/pages/auth/signin/signin.component";
import { SignupComponent } from "./ui/pages/auth/signup/signup.component";
import { VerificationComponent } from "./ui/pages/auth/verification/verification.component";
import { AutopilotComponent } from "./ui/pages/autopilot/autopilot.component";
import { ChatflowComponent } from "./ui/pages/autopilot/chatflow/chatflow.component";
import { EditTriggerComponent } from "./ui/pages/autopilot/trigger/edit-trigger/edit-trigger.component";
import { TriggerComponent } from "./ui/pages/autopilot/trigger/trigger.component";
import { ChannelComponent } from "./ui/pages/channel/channel.component";
import { FacebookComponent } from "./ui/pages/channel/facebook/facebook.component";
import { FridayComponent } from "./ui/pages/channel/whatsapp/friday/friday.component";
import { TwilioComponent } from "./ui/pages/channel/whatsapp/twilio/twilio.component";
import { WhatsappComponent } from "./ui/pages/channel/whatsapp/whatsapp.component";
import { DashboardComponent } from "./ui/pages/dashboard/dashboard.component";
import { InboxComponent } from "./ui/pages/inbox/inbox.component";
import { RedirectComponent } from "./ui/pages/redirect/redirect.component";
import { AccountComponent } from "./ui/pages/setting/account/account.component";
import { AiComponent } from "./ui/pages/setting/ai/ai.component";
import { OrganizationComponent } from "./ui/pages/setting/organization/organization.component";
import { SettingComponent } from "./ui/pages/setting/setting.component";

const routes: Routes = [
  {
    path: "m",
    component: MobileContainerComponent,
    children: [
      { path: "inbox", component: MobileInboxComponent },
      {
        path: "inbox/:sourceId",
        component: MobileInboxChatroomComponent,
      },
      {
        path: "",
        redirectTo: "inbox",
        pathMatch: "full",
      },
    ],
    data: {
      guards: [
        Guard.Email,
        // , Guard.Profile
      ],
    },
    canActivate: [AuthGuard],
  },
  {
    path: "",
    component: ContainerComponent,
    children: [
      { path: "dashboard", component: DashboardComponent },
      // { path: "knowledge", component: KnowledgeComponent },
      // { path: "task", component: TaskComponent },
      // { path: "chatform", component: ChatformComponent },
      // { path: "chatform/edit/:chatformId", component: EditChatformComponent },
      // {
      //   path: "chatform/response/:chatformId",
      //   component: ChatformResponseComponent,
      // },
      // { path: "chatflow/edit/:chatformId", component: ChatflowComponent },
      {
        path: "inbox",
        redirectTo: environment.mobile ? "/m/inbox" : "inbox/all",
        pathMatch: "full",
      },
      { path: "inbox/:channel", component: InboxComponent },
      {
        path: "autopilot",
        component: AutopilotComponent,
        children: [
          { path: "trigger", component: TriggerComponent },
          { path: "chatflow", component: ChatflowComponent },
          { path: "", redirectTo: "trigger", pathMatch: "full" },
        ],
      },
      {
        path: "autopilot/trigger/edit/:triggerId",
        component: EditTriggerComponent,
      },
      {
        path: "channel",
        component: ChannelComponent,
        children: [
          // { path: "web", component: LivechatComponent },
          { path: "facebook", component: FacebookComponent },
          {
            path: "whatsapp",
            component: WhatsappComponent,
            children: [
              { path: "twilio", component: TwilioComponent },
              { path: "friday", component: FridayComponent },
              { path: "", redirectTo: "twilio", pathMatch: "full" },
            ],
          },
          { path: "", redirectTo: "facebook", pathMatch: "full" },
        ],
      },
      {
        path: "setting",
        component: SettingComponent,
        children: [
          { path: "account", component: AccountComponent },
          { path: "organization", component: OrganizationComponent },
          { path: "ai", component: AiComponent },
          {
            path: "",
            redirectTo: "account",
            pathMatch: "full",
          },
        ],
      },
      {
        path: "",
        redirectTo: environment.mobile ? "/m/inbox" : "dashboard",
        pathMatch: "full",
      },
    ],
    data: {
      guards: [
        Guard.Email,
        // , Guard.Profile
      ],
    },
    canActivate: [AuthGuard],
  },
  { path: "admin", component: AdminComponent, canActivate: [AdminGuard] },
  {
    path: "verification",
    component: VerificationComponent,
    canActivate: [AuthGuard],
  },
  // {
  //   path: "onboarding",
  //   component: OnboardingComponent,
  //   data: { guards: [Guard.Email] },
  //   canActivate: [AuthGuard],
  // },
  {
    path: "auth",
    component: AuthComponent,
    children: [
      { path: "login", component: SigninComponent },
      { path: "signup", component: SignupComponent },
      { path: "reset", component: ResetComponent },
      {
        path: "",
        redirectTo: "login",
        pathMatch: "full",
      },
    ],
  },
  { path: "oauth", component: GoogleOauthComponent },
  { path: "redirect", component: RedirectComponent },
  { path: "privacy", component: PrivacyComponent },
  { path: "**", component: NotFoundComponent },
];

const routeImports = environment.mobile
  ? [RouterModule.forRoot(routes, { useHash: true })]
  : [RouterModule.forRoot(routes)];

@NgModule({
  imports: routeImports,
  exports: [RouterModule],
})
export class AppRoutingModule {}
