export const ERROR = {
  Friday: {
    UserNotFound: "friday/user_not_found",
    UnsupportedLanguage: "friday/unsupported_language",
  },
  Unknown: "unknown",
  Twilio: {
    NotConnected: "twilio/not_connected",
  },
  Facebook: {
    NotConnected: "facebook/not_connected",
    Retry: "facebook/wait_and_retry",
    Reauthenticate: "facebook/reauthenticate",
    PermissionDenied: "facebook/permission_denied",
    MissingRole: "facebook/missing_role",
    UserAccountError: "facebook/user_account_error",
  },
};

const FacebookError = {
  SendAPI: {
    "100": {
      "2534014": "No matching Instagram user",
      "2534037": "The action is invalid since it's not the thread owner.",
      "2534025": "The comment is invalid for a private reply",
      "2534015": "Invalid message data",
      "2018320": "Invalid product id",
      "2534013": "The page is not linked to an Instagram account",
      "2534029":
        "The business has been blocked from sending messages via the IG Messaging API",
    },
    "10": {
      "2534022": "This message is sent outside of allowed window.",
    },
    "613": {
      "2534040": "Calls to this api have exceeded the rate limit.",
    },
    "200": {
      "2534041":
        "The account owner has disabled access to instagram direct messages.",
    },
  },
};
