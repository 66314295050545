<div class="page__container" *ngIf="client" [@smooth]>
  <div class="page__content__title">
    <h1 translate>facebook.title</h1>
  </div>

  <div class="channel-header">
    <div class="row">
      <div class="channel-description">
        <ul>
          <li translate>facebook.instruction.1</li>
          <li translate>facebook.instruction.2</li>
          <li translate>facebook.instruction.3</li>
        </ul>
      </div>

      <div class="channel-image">
        <img src="assets/img/messenger-logo.svg" />
        <img src="assets/img/instagram-logo.svg" />
      </div>
    </div>
  </div>

  <div class="channel-details">
    <div class="channel-details-title">
      <h2 translate>facebook.connected-pages</h2>
      <div class="action" *ngIf="!adminView && isManager">
        <app-loader-button
          (click)="connectPage()"
          [disabled]="loading"
          [loading]="loading"
          icon="add"
          translation="facebook.page"
          [success]="true"
        ></app-loader-button>
      </div>
    </div>

    <div class="channel-item" *ngFor="let page of pages">
      <div class="info">
        <!-- <div class="form__row">
          <div class="form__label">
            <i class="material-icons">fingerprint</i>
            <span translate>facebook.page-id</span>
          </div>
          <div class="form__field">
            <div>{{ page.id }}</div>
          </div>
        </div> -->

        <div class="form__row">
          <div class="form__label">
            <img src="assets/img/facebook-logo.svg" />
            <span translate>facebook.page-name</span>
          </div>
          <div class="form__field">
            <a href="https://www.facebook.com/{{ page.id }}" target="_blank">
              <img
                class="profile-picture"
                [src]="page.profilePic"
                [alt]="page.name"
              />
              <div class="bold">{{ page.name }}</div>
            </a>
          </div>
          <div class="form__action">
            <button
              class="icon-button gray"
              mat-button
              [matMenuTriggerFor]="actionMenu"
              [matMenuTriggerData]="{ page, channel: Channel.Facebook }"
            >
              <i class="material-icons">more_vert</i>
            </button>
          </div>
        </div>

        <div class="form__row">
          <div class="form__label">
            <img src="assets/img/instagram-logo.svg" />
            <span translate>instagram.ig-business</span>
          </div>
          <div class="form__field">
            <app-loader-button
              *ngIf="!page.instagram && isManager"
              (click)="connectInstagram(page.id)"
              [disabled]="loading"
              [loading]="loading"
              icon="link"
              translation="instagram.connect-ig"
              btnClass="ig-button"
            ></app-loader-button>

            <!-- <button
              class="text-button "
              (click)="connectInstagram(page.id)"
              *ngIf="!page.instagram && isManager"
            >
              <i class="material-icons">link</i>
              <span translate>instagram.connect-ig</span>
            </button> -->

            <a
              *ngIf="page.instagram?.username"
              href="https://www.instagram.com/{{ page.instagram.username }}"
              target="_blank"
            >
              <img
                class="profile-picture"
                [src]="page.profilePic"
                [alt]="page.name"
              />
              <div class="bold">@{{ page.instagram.username }}</div>
            </a>
          </div>

          <div class="form__action" *ngIf="page.instagram">
            <button
              mat-button
              [matMenuTriggerFor]="actionMenu"
              [matMenuTriggerData]="{ page, channel: Channel.Instagram }"
              class="icon-button gray"
            >
              <i class="material-icons">more_vert</i>
            </button>
          </div>
        </div>

        <!-- <div class="form__row">
          <div class="form__label">
            <img src="assets/img/whatsapp-logo.svg" />
          </div>
          <div class="form__field">
            <button class="text-button" (click)="signUpWhatsapp()">
              <span>whatsapp</span>
            </button>
          </div>
        </div> -->
      </div>

      <!-- <div class="picture">
        <a
          href="https://www.facebook.com/{{ page.id }}"
          target="_blank"
          *ngIf="page.profilePic"
        >
          <img [src]="page.profilePic" width="200" height="200" />
        </a>
      </div> -->

      <!-- <div class="action" *ngIf="!adminView && isManager">
        <button class="text-button success" (click)="connectPage()">
          <i class="material-icons">refresh</i>
          <span translate>general.reconnect</span>
        </button>

        <button class="text-button error" (click)="removePage(page)">
          <i class="material-icons">delete_forever</i>
          <span translate>general.disconnect</span>
        </button>
      </div> -->
    </div>
  </div>
</div>

<mat-menu #actionMenu="matMenu" xPosition="before" yPosition="below">
  <ng-template matMenuContent let-page="page" let-channel="channel">
    <button mat-menu-item class="text-button success" (click)="connectPage()">
      <i class="material-icons">refresh</i>
      <span translate>general.reconnect</span>
    </button>

    <button
      mat-menu-item
      class="text-button error"
      (click)="
        channel == Channel.Instagram ? removeInstagram(page) : removePage(page)
      "
    >
      <i class="material-icons">delete_forever</i>
      <span translate>general.disconnect</span>
    </button>
  </ng-template>
</mat-menu>
