import { Component, Input, OnInit } from "@angular/core";
import { Channel, Conversation, Message } from "@models/messenger";
import { ConversationTag } from "@models/user";
import { animations } from "@shared";
import { unique } from "@utility";
import { AuthService } from "../../../../services/auth.service";
import { ConversationService } from "../../../../services/conversation.service";

@Component({
  selector: "app-contact-item",
  templateUrl: "./contact-item.component.html",
  styleUrls: ["./contact-item.component.scss"],
  animations: animations,
})
export class ContactItemComponent implements OnInit {
  @Input() conversation: Conversation;
  @Input() active = false;
  channelIcon: string;

  constructor(
    private conversationService: ConversationService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.channelIcon =
      this.conversation.channel == Channel.Web
        ? "assets/img/logo.png"
        : `assets/img/${this.conversation.channel}-logo.svg`;
  }

  get lastMessage(): Message {
    if (!this.conversation || this.conversation.messages.length == 0)
      return null;
    return this.conversation.messages[this.conversation.messages.length - 1];
  }

  get profilePicture() {
    return this.conversationService.formatCustomerPicture(
      this.conversation.id,
      this.conversation.customer.profilePic
    );
  }

  get tags(): ConversationTag[] {
    if (!this.authService.client) return [];
    return unique(
      this.conversation.customer.tags.map((tagId) =>
        this.authService.client.settings.conversation.tags.find(
          (tag) => tag.tagId == tagId
        )
      )
    );
  }
}
