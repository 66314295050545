import { Injectable } from "@angular/core";
import {
  arrayRemove,
  arrayUnion,
  collection,
  CollectionReference,
  doc,
  Firestore,
  getDoc,
  getDocs,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from "@angular/fire/firestore";
import { LivechatSetting } from "@models/livechat";
import { Channel } from "@models/messenger";
import { Client, ClientParams, ConversationTag } from "@models/user";
import { NotificationDevice } from "@models/user/notification";
import { FcmService, NotificationService } from "@shared";
import { to, toJSON } from "@utility";
import { environment } from "../../environments/environment";
import { ApiService } from "./api.service";
import { AuthService, CLIENT_COLLECTION } from "./auth.service";

export const LIVECHAT_COLLECTION = "livechats";
@Injectable({
  providedIn: "root",
})
export class ClientService {
  private _collection: CollectionReference;
  public client: Client;
  public statPeriod = 7;

  constructor(
    private firestore: Firestore,
    private authService: AuthService,
    private apiService: ApiService,
    private notificationService: NotificationService,
    private fcmService: FcmService
  ) {
    // console.log('[ClientService] Constructor');
    this._collection = collection(this.firestore, CLIENT_COLLECTION);
    this.authService.clientChanges.subscribe((client) => {
      if (client) this.client = client;
    });
    // this.test();
  }

  async initializeNotification() {
    try {
      this.fcmService.initialize(environment.fcmKey, environment.mobile);
      const notificationSetting = await this.fcmService.getNotificationToken();
      if (!notificationSetting?.token) return true;

      const i = this.authService.currentUser.notification.findIndex(
        (notification) => notification.token == notificationSetting.token
      );
      if (i >= 0) return true;

      const device: NotificationDevice = {
        deviceName: notificationSetting.deviceName,
        token: notificationSetting.token,
        active: true,
      };

      i == -1
        ? this.authService.currentUser.notification.push(device)
        : (this.authService.currentUser.notification[i] = device);

      await this.updateClient({
        users: this.client.users,
      });
    } catch (e) {}
  }

  async getClientStat() {
    return this.apiService.getClientStat(this.statPeriod);
  }

  async listActiveClients(): Promise<Client[]> {
    const [listErr, snapshots] = await to(
      getDocs(query(this._collection, orderBy("updatedAt")))
    );
    if (listErr) return this.notificationService.error("error.data.get", []);
    if (snapshots.empty) return [];
    return snapshots.docs.map((doc) => new Client(doc.data() as ClientParams));
  }

  async updateClient(update: any = {}, isArray = false): Promise<boolean> {
    update = { ...update, ...{ updatedAt: new Date().valueOf() } };
    console.log(update);
    const [updateErr] = await to(
      updateDoc(
        doc(this._collection, this.client.id),
        isArray ? update : toJSON(update)
      )
    );
    if (updateErr)
      return this.notificationService.error("error.user.update", false);
    return true;
  }

  addTag(tag: ConversationTag): Promise<boolean> {
    return this.updateClient(
      {
        "settings.conversation.tags": arrayUnion(toJSON(tag)),
      },
      true
    );
  }

  deleteTag(tag: ConversationTag): Promise<boolean> {
    return this.updateClient(
      {
        "settings.conversation.tags": arrayRemove(toJSON(tag)),
      },
      true
    );
  }

  async checkPage(pageId: string): Promise<boolean> {
    const [checkErr, doc] = await to(
      getDocs(
        query(
          this._collection,
          where("channels.facebook.pages", "array-contains", pageId)
        )
      )
    );
    // console.log(checkErr);
    if (checkErr)
      return this.notificationService.error("error.facebook.check-page", false);
    // console.log(doc);
    if (doc.empty) return true;
    const data = doc.docs.map((d) => d.data());
    if (data[0].id == this.client.id) return true;
    return this.notificationService.error(
      "error.facebook.existing-page",
      false
    );
  }

  async removeChannel(channel: Channel) {
    this.client.settings.conversation.channels =
      this.client.settings.conversation.channels.filter((c) => c != channel);
    let update = {};

    switch (channel) {
      case Channel.Facebook: {
        this.client.settings.conversation.channels =
          this.client.settings.conversation.channels.filter(
            (c) => c != Channel.Instagram
          );
        break;
      }
      case Channel.WhatsApp: {
        update["channels.fridayWhatsapp.info"] = [];
        update["channels.fridayWhatsapp.instances"] = [];
        update["channels.twilio.numbers"] = [];

        if (
          this.client.channels.fridayWhatsapp &&
          this.client.channels.fridayWhatsapp.instances.length > 0
        ) {
          await to(
            Promise.all(
              this.client.channels.fridayWhatsapp.instances.map((instance) =>
                this.apiService.closeWhatsappInstance(instance.id)
              )
            )
          );
        }
        break;
      }
    }
    update["settings.conversation.channels"] =
      this.client.settings.conversation.channels;
    return this.updateClient(update, true);
  }

  async getLivechatSetting(): Promise<LivechatSetting> {
    const [err, docData] = await to(
      getDoc(
        doc(collection(this.firestore, LIVECHAT_COLLECTION), this.client.id)
      )
    );
    if (err) return this.notificationService.error("error.livechat.get", null);
    if (docData.exists()) return new LivechatSetting(docData.data() as any);
    return null;
  }

  async saveLivechatSetting(config: LivechatSetting): Promise<boolean> {
    const [err] = await to(
      setDoc(
        doc(collection(this.firestore, LIVECHAT_COLLECTION), this.client.id),
        config.json,
        { merge: true }
      )
    );
    if (err)
      return this.notificationService.error("error.livechat.save", false);

    if (!this.client.settings.conversation.channels.includes(Channel.Web)) {
      await this.updateClient(
        {
          "settings.conversation.channels": arrayUnion(Channel.Web),
        },
        true
      );
      window.location.href = "/inbox";
    }
    return true;
  }
}
