import { isEmpty } from "lodash";
import { toJSON } from "../../../helper";
import { Language, TextTranslation } from "../../../misc";
import { ChatflowInputValidation } from "./validation";

export enum ChatflowInputType {
  Text = "text",
  Number = "number",
  Email = "email",
  Phone = "phone",
  Boolean = "boolean",
  MultipleChoice = "multiple_choice",
  MultipleSelect = "multiple_select",
  File = "file",
}

export const ChatflowInputTypesWithOptions = [
  ChatflowInputType.Boolean,
  ChatflowInputType.MultipleChoice,
  ChatflowInputType.MultipleSelect,
];

export interface ChatflowInputOption {
  value: string | number | boolean;
  text?: TextTranslation;
  next?: string;
}

export interface ChatflowInputParams {
  type?: ChatflowInputType;
  variableName?: string;
  options?: ChatflowInputOption[];
  errMsg?: TextTranslation;
  validation?: ChatflowInputValidation;
}

export class ChatflowInput {
  type: ChatflowInputType;
  options?: ChatflowInputOption[];
  errMsg: TextTranslation;
  validation?: ChatflowInputValidation;

  constructor(params: ChatflowInputParams = {}) {
    this.type = params.type || ChatflowInputType.Text;
    this.errMsg = params.errMsg || {};
    if (ChatflowInputTypesWithOptions.includes(this.type))
      this.options = params.options || [];
    if (params.validation) this.validation = params.validation;
  }

  get json() {
    return toJSON(this);
  }

  optionTexts(language: Language): string[] {
    if (isEmpty(this.options)) return [];
    return this.options!.map((option) => {
      if (isEmpty(option.text)) return String(option.value);
      return (
        option.text![language] ||
        option.text![
          Object.keys(option.text!).find((l) => l != language) || Language.en
        ] ||
        option.text![Language.zh] ||
        String(option.value)
      );
    });
  }

  errorMessage(language: Language): string {
    if (isEmpty(this.errMsg)) return "";
    return (
      this.errMsg[language] ||
      this.errMsg[
        Object.keys(this.errMsg).find((l) => l != language) || Language.en
      ] ||
      this.errMsg[Language.zh] ||
      ""
    );
  }
}
