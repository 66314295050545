<div class="page__container" *ngIf="client" [@smooth]>
  <div class="account__info">
    <div
      class="account__info__photo"
      (mouseenter)="showEditPhoto = true"
      (mouseleave)="showEditPhoto = false"
    >
      <div class="user-icon gradient-theme" *ngIf="!user.info.photoURL">
        {{
          user.info.displayName ? user.info.displayName[0] : user.info.email[0]
        }}
      </div>
      <div
        class="user-icon"
        *ngIf="user.info.photoURL"
        [style.background-image]="userPhoto()"
      ></div>
      <button
        class="icon-button"
        [class.invisible]="!showEditPhoto"
        (click)="uploadUserPhoto()"
      >
        <i class="material-icons">edit</i>
      </button>
    </div>

    <input
      #upload
      hidden
      type="file"
      (change)="onFileChange($event)"
      accept="image/*"
    />

    <div class="account__info__info">
      <div class="account__info__info__name">
        <input
          type="text"
          class="name"
          [(ngModel)]="user.info.displayName"
          placeholder="display name"
          [readonly]="!editName"
        />

        <button
          class="icon-button"
          (click)="editName = !editName"
          *ngIf="!editName"
        >
          <i class="material-icons">edit</i>
        </button>

        <button
          class="icon-button warn"
          (click)="
            user.info.displayName = userCopy.info.displayName; editName = false
          "
          *ngIf="editName"
        >
          <i class="material-icons">cancel</i>
        </button>
        <button
          class="icon-button success"
          (click)="save()"
          *ngIf="user.info.displayName != userCopy.info.displayName"
        >
          <i class="material-icons">check_circle</i>
        </button>
      </div>
      <input
        type="email"
        class="account__info__info__email"
        [value]="user.info.email"
        readonly
      />
    </div>
  </div>

  <div class="account__settings form">
    <div class="form__section" translate>setting.basics</div>
    <div class="form__row">
      <div class="form__label">
        <i class="material-icons">language</i>
        <span translate>setting.primary-language</span>
      </div>
      <div class="form__field">
        <input
          type="text"
          [value]="client.primaryLanguage.toUpperCase()"
          readonly
        />
      </div>
    </div>

    <div class="form__row">
      <div class="form__label">
        <i class="material-icons">business</i>
        <span translate>setting.organization</span>
      </div>
      <div class="form__field">
        <input
          type="text"
          [(ngModel)]="client.organization"
          placeholder="{{ 'placeholder.optional' | translate }}"
          [readonly]="!editOrganization || isSubAccount"
        />
      </div>
      <div class="form__action" *ngIf="!isSubAccount">
        <button
          class="icon-button"
          (click)="editOrganization = !editOrganization"
          *ngIf="!editOrganization"
        >
          <i class="material-icons">edit</i>
        </button>
        <button
          class="icon-button warn"
          (click)="
            client.organization = clientCopy.organization;
            editOrganization = false
          "
          *ngIf="editOrganization"
        >
          <i class="material-icons">cancel</i>
        </button>
        <button
          class="icon-button success"
          (click)="save()"
          *ngIf="client.organization != clientCopy.organization"
        >
          <i class="material-icons">check_circle</i>
        </button>
      </div>
    </div>

    <ng-container *ngIf="!adminView && !mobile">
      <div class="form__section" translate>setting.sign-in-methods</div>

      <div class="form__row">
        <div class="form__label">
          <img src="assets/img/google-logo.svg" />google
        </div>
        <div class="form__field">
          <div
            class="row success capitalize"
            *ngIf="isLinked(AuthProvider.Google)"
          >
            <i class="material-icons">link</i>
            <span translate>general.linked</span>
          </div>
          <button
            class="text-button bg-primary capitalize"
            (click)="linkProvider(AuthProvider.Google)"
            *ngIf="!isLinked(AuthProvider.Google)"
          >
            <i class="material-icons">link</i>
            <span translate>general.link</span>
          </button>
        </div>
        <div class="form__action">
          <button
            class="icon-button error capitalize"
            (click)="unlinkProvider(AuthProvider.Google)"
            *ngIf="isLinked(AuthProvider.Google) && providerIds.length > 1"
          >
            <i class="material-icons">delete</i>
          </button>
        </div>
      </div>

      <div class="form__row">
        <div class="form__label">
          <img src="assets/img/facebook-logo.svg" />facebook
        </div>
        <div class="form__field">
          <div
            class="row success capitalize"
            *ngIf="isLinked(AuthProvider.Facebook)"
          >
            <i class="material-icons">link</i>
            <span translate>general.linked</span>
          </div>
          <button
            class="text-button bg-primary capitalize"
            (click)="linkProvider(AuthProvider.Facebook)"
            *ngIf="!isLinked(AuthProvider.Facebook)"
          >
            <i class="material-icons">link</i>
            <span translate>general.link</span>
          </button>
        </div>
        <div class="form__action">
          <button
            class="icon-button error capitalize"
            (click)="unlinkProvider(AuthProvider.Facebook)"
            *ngIf="isLinked(AuthProvider.Facebook)"
          >
            <i class="material-icons">delete</i>
          </button>
        </div>
      </div>

      <div class="form__row" *ngIf="!isLinked(AuthProvider.Email)">
        <div class="form__label">
          <i class="material-icons">lock</i>
          <span translate>auth.password</span>
        </div>
        <div class="form__field">
          <button
            class="text-button bg-primary capitalize"
            (click)="setPassword = true; editPassword = true"
          >
            <i class="material-icons">edit</i>
            <span translate>setting.set-password</span>
          </button>
        </div>
      </div>

      <ng-container *ngIf="isLinked(AuthProvider.Email) || setPassword">
        <div class="form__section">
          <span translate *ngIf="isLinked(AuthProvider.Email)"
            >setting.change-password</span
          >
          <span translate *ngIf="!isLinked(AuthProvider.Email)"
            >setting.set-password</span
          >
        </div>
        <div class="form__row">
          <div class="form__label">
            <i class="material-icons">lock</i>
            <span translate>auth.new-password</span>
          </div>
          <div class="form__field">
            <input
              type="password"
              [(ngModel)]="password"
              autocomplete="off"
              [readonly]="!editPassword"
            />
          </div>
          <div class="form__action">
            <button
              class="icon-button"
              (click)="editPassword = !editPassword"
              *ngIf="!editPassword"
            >
              <i class="material-icons">edit</i>
            </button>
          </div>
        </div>
        <div class="form__row">
          <div class="form__label">
            <i class="material-icons">lock</i>
            <span translate>auth.confirm-password</span>
          </div>
          <div class="form__field">
            <input
              type="password"
              [(ngModel)]="confirmPassword"
              autocomplete="off"
              [readonly]="!editPassword"
              [class.invalid]="confirmPassword && password != confirmPassword"
              (keydown.enter)="changePassword()"
            />
          </div>
          <div class="form__action">
            <button
              class="icon-button"
              (click)="editPassword = !editPassword"
              *ngIf="!editPassword"
            >
              <i class="material-icons">edit</i>
            </button>
            <button
              class="icon-button warn"
              (click)="
                password = ''; confirmPassword = ''; editPassword = false
              "
              *ngIf="editPassword"
            >
              <i class="material-icons">cancel</i>
            </button>
            <button
              class="icon-button success"
              (click)="changePassword()"
              *ngIf="password && password == confirmPassword"
            >
              <i class="material-icons">check_circle</i>
            </button>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <div class="form__section" translate>setting.display</div>
    <div class="form__row">
      <div class="form__label">
        <i class="material-icons">brightness_4</i>
        <span translate>setting.darkmode</span>
      </div>
      <div class="form__field">
        <mat-slide-toggle
          [(ngModel)]="darkmode"
          (ngModelChange)="toggleDarkmode()"
        >
        </mat-slide-toggle>
      </div>
    </div>

    <div class="form__section" translate>setting.notification</div>

    <ng-container *ngFor="let device of user.notification; let i = index">
      <div class="form__row">
        <div class="form__label">
          <i class="material-icons">devices</i>
          <span>{{ device.deviceName }}</span>
        </div>
        <div class="form__field">
          <mat-slide-toggle
            [(ngModel)]="device.active"
            (ngModelChange)="save()"
          >
          </mat-slide-toggle>
        </div>
        <div class="form__action">
          <button
            class="icon-button error"
            (click)="removeNotificationDevice(i)"
          >
            <i class="material-icons">delete</i>
          </button>
        </div>
      </div>
    </ng-container>

    <!-- End of form -->
  </div>
</div>
<app-loader *ngIf="!client"></app-loader>
