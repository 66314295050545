import {
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { ACTIVE_CHANNELS, Channel, Conversation } from "@models/messenger";
import { Task } from "@models/task";
import { Client } from "@models/user";
import { animations, SettingService } from "@shared";
import { Observable, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { AuthService } from "../../../services/auth.service";
import { ConversationService } from "../../../services/conversation.service";
import { AddConversationComponent } from "../../dialogs/add-conversation/add-conversation.component";

export interface ChannelSource {
  id: string;
  name: string;
  picture: string;
}
@Component({
  selector: "app-inbox",
  templateUrl: "./inbox.component.html",
  styleUrls: ["./inbox.component.scss"],
  animations: animations,
})
export class InboxComponent implements OnInit, OnDestroy {
  @ViewChild("inbox") inboxElement: ElementRef;
  @ViewChild("contacts") contactsElement: ElementRef;

  client: Client;
  clientSub: Subscription;
  conversationSub: Subscription;
  conversations: Conversation[] = null;
  currentConversationId = "";
  hasUnconnected: boolean;
  currentChannel: Channel = Channel.All;
  channels: Channel[] = [];
  Channel: typeof Channel = Channel;
  loading = true;
  tasks$: Observable<Task[]>;
  showArchive = false;

  activeChannels = ACTIVE_CHANNELS;
  unreadCount = 0;
  adminView = false;
  mobile = !!environment.mobile;
  initialized = false;
  contactsResizing = false;
  reloading = false;
  searchText = "";
  filterTagIds: string[] = [];
  currentSourceId: string;

  fridayWhatsappSources: ChannelSource[] = [];
  twilioWhatsappSources: ChannelSource[] = [];
  whatsappSources: ChannelSource[] = [];
  facebookSources: ChannelSource[] = [];
  instagramSources: ChannelSource[] = [];
  channelSources: ChannelSource[] = [];

  constructor(
    private authService: AuthService,
    private settingService: SettingService,
    public conversationService: ConversationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private ngZone: NgZone,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.adminView = this.authService.isSuperAdmin;

    this.activatedRoute.params.subscribe((params) => {
      this.currentChannel = (params.channel as Channel) || Channel.All;
      this.currentConversationId = null;
      this.currentSourceId = "all";
      this.channelSources = this.getChannelSources();
    });

    this.clientSub = this.authService.clientChanges.subscribe((client) => {
      if (!client) return;
      this.client = client;
      this.channels = ACTIVE_CHANNELS.filter((c) =>
        this.client.settings.conversation.channels.includes(c)
      );
      if (!this.channels.includes(this.currentChannel))
        this.ngZone.run(() => this.router.navigate(["/inbox/all"]));

      this.facebookSources = this.client.channels.facebook.pages.map(
        (page) => ({
          id: page.id,
          name: page.name,
          picture: page.profilePic || "assets/img/facebook-logo.svg",
        })
      );

      this.instagramSources = this.client.channels.facebook.pages
        .filter((page) => page.instagram)
        .map((page) => page.instagram)
        .map((instagram) => ({
          id: instagram.id,
          name: instagram.username || instagram.name,
          picture:
            instagram.profilePictureUrl || "assets/img/instagram-logo.svg",
        }));

      this.fridayWhatsappSources = this.client.channels.fridayWhatsapp.instances
        .filter((instance) => instance.whatsappId)
        .map((instance) => ({
          id: instance.whatsappId,
          name: `+${instance.number}`,
          picture: instance.profilePic || "assets/img/whatsapp-logo.svg",
        }));

      this.twilioWhatsappSources =
        this.client.channels.twilio.whatsappNumbers.map((number) => ({
          id: number,
          name: number.replace("whatsapp:", ""),
          picture: "assets/img/twilio-logo.svg",
        }));

      this.whatsappSources = [
        ...this.fridayWhatsappSources,
        ...this.twilioWhatsappSources,
      ];
      this.channelSources = this.getChannelSources();
      this.loading = false;
    });

    this.conversationSub = this.conversationService.conversationsChanges
      .pipe(debounceTime(1000))
      .subscribe((conversations) => {
        if (!conversations) return;
        const unreadConversations = conversations
          .filter((c) => c.unread)
          .sort((a, b) => b.updatedAt - a.updatedAt);
        const readConversations = conversations
          .filter((c) => !c.unread)
          .sort((a, b) => b.updatedAt - a.updatedAt);

        this.conversations = [...unreadConversations, ...readConversations];
        // console.log(this.conversations);

        if (!this.currentConversationId) {
          if (this.conversations.length > 0)
            this.currentConversationId = this.conversations[0].id;
        } else {
          const currentConversation = this.conversations.find(
            (c) => c.id == this.currentConversationId
          );
          if (
            !currentConversation ||
            (this.currentChannel == Channel.Archived
              ? !currentConversation.archived
              : currentConversation.archived)
          )
            this.currentConversationId = null;
        }
        // this.unreadCount = this.conversations.filter(
        //   (c) => !c.archived && c.unread
        // ).length;
        this.showArchive = this.conversations.some((c) => c.archived);
        this.initialized = true;
      });
  }

  ngOnDestroy() {
    if (this.clientSub) this.clientSub.unsubscribe();
    if (this.conversationSub) this.conversationSub.unsubscribe();
    this.conversationService.allTypingOff();
    this.conversations = null;
    this.currentConversationId = null;
  }

  conversationTracker(index: number, conversation: Conversation) {
    return `${index}-${conversation.id}`;
  }

  getChannelSources() {
    switch (this.currentChannel) {
      case Channel.WhatsApp: {
        return this.whatsappSources;
      }
      case Channel.Facebook: {
        return this.facebookSources;
      }
      case Channel.Instagram: {
        return this.instagramSources;
      }
      default:
        return [];
    }
  }

  get showAddConversation() {
    if (this.currentChannel != Channel.WhatsApp) return false;
    if (this.client.channels.fridayWhatsapp.instances.length == 0) return false;
    return true;
  }

  currentSourceById(id: string, sources: any) {
    const all = {
      id: "all",
      name: "",
      picture: "",
    };
    if (sources?.length > 0) {
      if (!id || id == "all") return sources.length > 1 ? all : sources[0];
      return sources.find((source) => source.id == id) || all;
    }
    return all;
  }

  async addChannel(channel?: Channel) {
    return this.conversationService.addChannel(channel);
  }

  showChatlist() {
    return this.conversationService.showChatlist();
  }

  hideChatlist() {
    return this.conversationService.hideChatlist();
  }

  startResizeChatlist() {
    this.contactsResizing = true;
  }

  resizeChatlist(e) {
    if (!this.contactsResizing) return false;
    e.preventDefault();
    e.stopPropagation();
    const containerOffsetLeft =
      this.inboxElement.nativeElement.getBoundingClientRect().left;
    const width = e.clientX - containerOffsetLeft;
    // console.log(width);
    this.contactsElement.nativeElement.style.width = width + "px";
  }

  stopResizeChatlist() {
    if (!this.contactsResizing) return false;
    this.contactsResizing = false;
    const width = this.contactsElement.nativeElement.offsetWidth;
    // console.log("stop", width);
    this.settingService.resizeChatlist(width);
  }

  clearSearch() {
    this.searchText = "";
  }

  showTag(tagId: string) {
    return !this.filterTagIds.includes(tagId);
  }

  tagIdToTag(tagId: string) {
    return (
      this.client.settings.conversation.tags.find((t) => t.tagId == tagId) ||
      null
    );
  }

  filterTag(tagId: string) {
    this.filterTagIds = [...this.filterTagIds, ...[tagId]];
  }

  removeFilterTag(tagId: string) {
    this.filterTagIds = this.filterTagIds.filter((t) => t != tagId);
  }

  clearFilter() {
    this.filterTagIds = [];
  }

  startConversation() {
    this.dialog.open(AddConversationComponent, {
      data: this.fridayWhatsappSources,
    });
  }
}
