import { toJSON } from "../../helper";

export const END_OF_CHATFLOW = "END_OF_CHATFLOW";

export const INITIAL = "INITIAL";
export interface ChatflowStateValues {
  [key: string]: string | number | boolean | any;
}
export interface ChatflowStateParams {
  previousId?: string;
  currentId?: string;
  values?: ChatflowStateValues;
  timestamp?: number;
  approval?: {
    [key: string]: boolean;
  };
}
export class ChatflowState {
  previousId: string;
  currentId: string;
  values: ChatflowStateValues;
  timestamp: number;
  approval: {
    [key: string]: boolean;
  };

  constructor(params: ChatflowStateParams = {}) {
    this.previousId = params.previousId || INITIAL;
    this.currentId = params.currentId || "";
    this.values = params.values || {};
    this.timestamp = params.timestamp || new Date().valueOf();
    this.approval = params.approval || {};
  }

  updateTimestamp(offset: number = 0) {
    this.timestamp = new Date().valueOf() + offset;
  }

  get json() {
    return toJSON(this);
  }
}
