import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { arrayUnion } from "@angular/fire/firestore";
import { MatDialog } from "@angular/material/dialog";
import { Channel } from "@models/messenger";
import { Client, Instagram } from "@models/user";
import { NotificationService } from "@shared";
import { to, unique } from "@utility";
import { isEmpty } from "lodash";
import { environment } from "../../environments/environment";
import { FbPageSelectorComponent } from "../ui/dialogs/fb-page-selector/fb-page-selector.component";
import { ApiService } from "./api.service";
import { AuthService } from "./auth.service";
import { ClientService } from "./client.service";
declare var FB: any;

enum FBApiMethod {
  POST = "post",
  GET = "get",
  DELETE = "delete",
}

const ENDPOINT = `https://graph.facebook.com/${environment.graphAPI.API_VERSION}`;
@Injectable({
  providedIn: "root",
})
export class FacebookService {
  timeout = 15000;
  igFollowerLimit = 0;
  private _client: Client;
  private _pagePosts: any;
  // private _publishedPostNext: string;
  // private _scheduledPostNext: string;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private notificationService: NotificationService,
    private clientService: ClientService,
    private apiService: ApiService,
    public dialog: MatDialog
  ) {
    this.authService.clientChanges.subscribe(
      (client) => (this._client = client)
    );
  }

  async handleFbError(error: any) {
    console.log(error);
    // App not subscribed
    if (
      (error.code == 190 && error.error_subcode == 458) ||
      (error.code == 100 && error.error_subcode == 33)
    ) {
      await this.clientService.removeChannel(Channel.Facebook);
      this.notificationService.error("error.facebook.require_app_reconnect");
    }
  }

  api(path: string, params?: any, method = FBApiMethod.GET): Promise<any> {
    return new Promise((resolve, reject) => {
      const timerId = setTimeout((_) => {
        console.error("FB API timeout: ", method, path);
        reject(false);
      }, this.timeout);

      switch (method) {
        case FBApiMethod.POST:
          this.http.post(`${ENDPOINT}${path}`, params).subscribe({
            next: (res) => {
              clearTimeout(timerId);
              resolve(res);
            },
            error: async (err) => {
              clearTimeout(timerId);
              await this.handleFbError(err.error.error);
              reject(err);
            },
          });
          break;
        case FBApiMethod.DELETE:
          const deleteParams = new HttpParams({ fromObject: params });
          this.http
            .delete(`${ENDPOINT}${path}`, { params: deleteParams })
            .subscribe({
              next: (res) => {
                clearTimeout(timerId);
                resolve(res);
              },
              error: async (err) => {
                clearTimeout(timerId);
                await this.handleFbError(err.error.error);
                reject(err);
              },
            });
          break;
        default:
          const getParams = new HttpParams({ fromObject: params });
          this.http.get(`${ENDPOINT}${path}`, { params: getParams }).subscribe({
            next: (res) => {
              clearTimeout(timerId);
              resolve(res);
            },
            error: async (err) => {
              clearTimeout(timerId);
              await this.handleFbError(err.error.error);
              reject(err);
            },
          });
      }
    });
  }

  directGet(url: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(url).subscribe({
        next: (res) => resolve(res),
        error: (err) => reject(err),
      });
    });
  }

  async apiLoop(
    path: string,
    params?: any,
    method = FBApiMethod.GET
  ): Promise<any> {
    const [err, res] = await to(this.api(path, params, method));
    if (err || !res) return [];
    let results = [res];
    console.log(res);
    if (!res.paging || !res.paging.next) return results;
    let next = res.paging.next;
    while (next) {
      const [_err, _res] = await to(this.directGet(next));
      console.log(_res);
      if (_err || !_res) next = null;
      results.push(_res);
      next = _res.paging && _res.paging.next ? _res.paging.next : null;
    }
    return results;
  }

  getLoginStatus() {
    return new Promise((resolve) => {
      FB.getLoginStatus((status) => {
        // console.log('FB status', status);
        if (status.status == "connected") {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  async logout() {
    return new Promise((resolve) => {
      FB.logout((_) => resolve(true));
    });
  }

  async login(
    extraScopes: string[] = [],
    extraConfigs: any = {}
  ): Promise<{ userID: string; userAccessToken: string }> {
    const [statusErr, connected] = await to(this.getLoginStatus());
    if (statusErr)
      return this.notificationService.error("error.facebook.connect", null);
    if (connected) await to(this.logout());
    return new Promise((resolve) => {
      // console.log(environment.graphAPI.APP_SCOPE);
      FB.login(
        (loginRes) => {
          // console.log("FB login", loginRes);
          if (loginRes.status == "connected") {
            const userID = loginRes.authResponse.userID;
            const userAccessToken = loginRes.authResponse.accessToken;
            // console.log("FB signed in");
            resolve({ userID, userAccessToken });
          } else {
            resolve(
              this.notificationService.error("error.facebook.connect", null)
            );
          }
        },
        {
          ...{
            scope:
              extraScopes.length > 0
                ? [...[environment.graphAPI.APP_SCOPE], ...extraScopes].join(
                    ","
                  )
                : environment.graphAPI.APP_SCOPE,
          },
          ...extraConfigs,
        }
      );
    });
  }

  async selectPage(auth: { userID: string; userAccessToken: string }) {
    const getPageData = async (userID: string, userAccessToken: string) => {
      const [dataErr, dataRes] = await to(
        this.api(`/${userID}/accounts?access_token=${userAccessToken}`)
      );
      // console.log(dataRes);
      if (dataErr) return null;
      const pageData = dataRes.data;
      if (!pageData || pageData.length == 0) return null;
      return pageData;
    };

    const userID = auth.userID;
    const userAccessToken = auth.userAccessToken;
    if (!userAccessToken || userAccessToken.length == 0)
      return this.notificationService.error("error.facebook.connect", null);
    // console.log(auth);
    let pageData = await getPageData(userID, userAccessToken);
    // console.log(pageData);
    if (!pageData || pageData.length == 0)
      return this.notificationService.error("error.facebook.connect", null);
    console.log("selectPage", pageData);

    for (let page of pageData) {
      const picture = await this.getPageProfilePicture(
        page.id,
        page.access_token
      );
      if (picture) page.picture = picture;
    }
    // console.log("selectPage", pageData);
    if (pageData.length == 1) return pageData[0];
    // console.log("Open dialog");
    const dialogRef = this.dialog.open(FbPageSelectorComponent, {
      data: pageData,
    });

    return new Promise((resolve) => {
      dialogRef.afterClosed().subscribe((pageId) => {
        if (!pageId)
          resolve(
            this.notificationService.error("error.facebook.connect", null)
          );
        const i = pageData.findIndex((d) => d.id == pageId);
        if (i == -1) resolve(null);
        resolve(pageData[i]);
      });
    });
  }

  async connectFacebook() {
    let extraScopes: string[] = [];
    if (this._client.channels.facebook.pages.length > 0) {
      if (this._client.channels.facebook.instagramIds.length > 0)
        extraScopes.push(environment.graphAPI.INSTAGRAM_SCOPE);
      // if (this._client.channels.facebook.whatsappIds.length > 0) extraScopes.push(environment.graphAPI.WHATSAPP_SCOPE);
    }
    const auth = await this.login(extraScopes);
    // console.log(auth);
    if (!auth) return false;
    // console.log("Getting page data");
    const page = await this.selectPage(auth);
    console.log(page);
    if (!page)
      return this.notificationService.warn("error.facebook.no-page", null);
    // console.log(page);
    // console.log("Checking existing page");
    const available = await this.clientService.checkPage(page.id);
    // console.log(available);
    if (!available) return false;
    // console.log("subscribeApp");
    const subscribed = await this.subscribeApp(page.id, page.access_token);
    if (!subscribed) return false;
    // console.log(subscribed);
    // console.log(subscribed);

    const saved = await this.apiService.saveFBToken(
      auth.userID,
      auth.userAccessToken,
      page.id,
      page.name,
      page.picture
    );
    if (!saved) return false;

    if (
      !this._client.settings.conversation.channels.includes(Channel.Facebook)
    ) {
      const succeed = await this.clientService.updateClient(
        {
          "settings.conversation.channels": arrayUnion(Channel.Facebook),
        },
        true
      );
      if (!succeed)
        return this.notificationService.error("error.data.update", false);
    }
    return this.notificationService.success("notification.data.saved", true);
  }

  async connectInstagram(pageId: string) {
    const getInstagramData = async (userAccessToken: string) => {
      const [dataErr, dataRes] = await to(
        this.api(
          `/${pageId}?fields=instagram_business_account&access_token=${userAccessToken}`
        )
      );
      console.log(dataRes);
      if (dataErr || !dataRes?.instagram_business_account?.id)
        return this.notificationService.warn(
          "error.facebook.no-instagram",
          null
        );
      const instagramId = dataRes.instagram_business_account.id;

      const [igErr, igRes] = await to(
        this.api(
          `/${instagramId}?fields=id,followers_count&access_token=${userAccessToken}`
        )
      );
      console.log(igRes);
      if (igErr || !igRes?.id) return null;
      const instagram: Instagram = {
        id: igRes.id,
        igId: igRes.ig_id,
        name: igRes.name,
        username: igRes.username,
        profilePictureUrl: igRes.profile_picture_url,
        followersCount: igRes.followers_count,
      };
      // console.log(instagram);

      if (instagram.followersCount < this.igFollowerLimit)
        return this.notificationService.warn(
          "error.instagram.follower-limit",
          null
        );
      return instagram.id;
    };

    let extraScopes = [environment.graphAPI.INSTAGRAM_SCOPE];
    // if (this._client.channels.facebook.whatsappIds.length > 0) extraScopes.push(environment.graphAPI.WHATSAPP_SCOPE);
    const auth = await this.login(extraScopes);
    // console.log(auth);
    if (!auth) return false;
    // console.log("Getting page data");
    const instagramId = await getInstagramData(auth.userAccessToken);
    if (!instagramId) return false;

    const pageAccessToken =
      this._client.channels.facebook.page(pageId)?.accessToken;
    if (!pageAccessToken)
      return this.notificationService.warn("error.instagram.connect", false);

    return this.apiService.connectInstagram(
      pageId,
      instagramId,
      pageAccessToken
    );
  }

  async subscribeApp(
    pageId: string,
    pageAccessToken: string
  ): Promise<boolean> {
    const params = {
      access_token: pageAccessToken,
      subscribed_fields: unique(
        environment.graphAPI.APP_SUBSCRIBED_FIELDS.split(",")
      ),
    };
    const [subErr, subRes] = await to(
      this.api(`/${pageId}/subscribed_apps`, params, FBApiMethod.POST)
    );
    if (subErr)
      return this.notificationService.error("error.facebook.subscribe", false);
    // console.log(subRes);

    const [checkErr, checkRes] = await to(
      this.api(`/${pageId}/subscribed_apps`, {
        access_token: pageAccessToken,
        fields: "subscribed_fields",
      })
    );
    if (checkErr || !checkRes.data)
      return this.notificationService.error("error.facebook.subscribe", false);
    const apps = checkRes.data;
    const friday = apps.find((app) => app.id == environment.facebook.APP_ID);
    if (friday && friday.subscribed_fields) {
      const requiredFields = unique(
        environment.graphAPI.APP_SUBSCRIBED_FIELDS.split(",")
      );
      console.log("Required fields:", requiredFields);
      console.log("Subscribed fields:", friday.subscribed_fields);
      const allSubscribed = requiredFields.every(
        (f) => friday.subscribed_fields.indexOf(f) != -1
      );
      if (!environment.production) return true;
      if (allSubscribed) return true;
    }
    return this.notificationService.error("error.facebook.subscribe", false);
  }

  async unsubscribeApp(pageId: string): Promise<boolean> {
    return this.apiService.unsubscribeFB(pageId);
  }

  async getPageProfilePicture(
    pageId: string,
    pageAccessToken: string
  ): Promise<string> {
    const params = {
      access_token: pageAccessToken,
      fields: "picture",
    };
    const [err, data] = await to(this.api(`/${pageId}`, params));
    if (err) console.error(err);
    // console.log(data);
    if (data.picture) return data.picture.data.url;
    return null;
  }

  async getPostById(postId: string) {
    if (!this._client.channels.facebook.pages || !postId) return null;
    const [pageId, _] = postId.split("_");
    // console.log(id, pageId, postId);
    if (this._pagePosts && this._pagePosts[pageId]) {
      const published =
        this._pagePosts[pageId].published.find((p) => p.id == postId) || null;
      if (published) return published;

      const scheduled =
        this._pagePosts[pageId].scheduled.find((p) => p.id == postId) || null;
      if (scheduled) return scheduled;
    }

    const { post } = await this.apiService.getFacebookPostById(pageId, postId);
    return post;
  }

  async getPosts(reload = false) {
    if (!this._client.channels.facebook.pages) return null;
    if (!reload && this._pagePosts) return this._pagePosts;
    const { posts: pagePosts } = await this.apiService.getFacebookPosts();
    if (isEmpty(pagePosts)) return null;
    this._pagePosts = pagePosts;
    console.log(this._pagePosts);
    return this._pagePosts;
  }

  async signUpWhatsapp() {
    let extraScopes = [environment.graphAPI.WHATSAPP_SCOPE];
    if (this._client.channels.facebook.instagramIds.length > 0)
      extraScopes.push(environment.graphAPI.INSTAGRAM_SCOPE);
    const auth = await this.login(extraScopes, {
      extras: {
        feature: "whatsapp_embedded_signup",
        setup: {},
      },
    });
    // console.log(auth);
    if (!auth) return false;

    const { userID, userAccessToken } = auth;
    const accountIds = await this.apiService.getWhatsappAccounts(
      userAccessToken
    );
    console.log(accountIds);
  }
}
