import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-empty",
  templateUrl: "./empty.component.html",
  styleUrls: ["./empty.component.scss"],
})
export class EmptyComponent {
  @Input() cover: boolean = false;
  @Input() message: string;
  @Input() icon: string;
  @Input() btnIcon: string;
  @Input() button: string;
  @Output() onaction = new EventEmitter();

  constructor() {}

  click() {
    this.onaction.emit(true);
  }
}
