import { Component, NgZone, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { wait } from "@utility";
import { AuthService } from "../../../../services/auth.service";

@Component({
  selector: "app-reset",
  templateUrl: "./reset.component.html",
  styleUrls: ["./reset.component.scss"],
})
export class ResetComponent implements OnInit {
  redirect: string;
  loading = false;
  email: string;

  constructor(
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private ngZone: NgZone
  ) {}

  ngOnInit() {
    this.redirect = this.activatedRoute.snapshot.queryParams.redirect || "/";
    this.email = this.activatedRoute.snapshot.queryParams.email || "";
  }

  reset() {
    this.email = "";
  }

  async resetPassword() {
    if (this.email == "") return false;
    const succeed = await this.authService.sendPasswordResetEmail(this.email);
    this.reset();
    if (succeed) {
      await wait(2);
      this.ngZone.run(() => this.router.navigate(["/auth/login"]));
    }
  }
}
