import { compact, isEqual, isObject, pullAt, uniq, uniqWith } from "lodash";
import moment from "moment-timezone";
import shorthash from "shorthash";
import { Timezone } from "../misc";

export const to = <T>(
  promise: Promise<T>,
  timeout = 60
): Promise<[any, T | null]> => {
  return new Promise((resolve) => {
    setTimeout((_) => {
      resolve([`timeout after ${timeout}s`, null]);
    }, timeout * 1000);

    promise
      .then((data) => {
        resolve([null, data]);
      })
      .catch((err) => {
        resolve([err, null]);
      });
  });
};

export const toAll = async <T>(
  promiseArr: Promise<T | any>[],
  timeout = 60
): Promise<{
  results: (T | any)[];
  succeed: (T | any)[];
  failed: (T | any)[];
}> => {
  return new Promise((resolve) => {
    setTimeout((_) => {
      resolve({
        results: [],
        succeed: [],
        failed: [`timeout after ${timeout}s`],
      });
    }, timeout * 1000);

    Promise.allSettled(promiseArr)
      .then((values) => {
        const results: any[] = values.map((v: any) =>
          v.status == "fulfilled" ? v.value : null
        );
        const failed: any[] = values
          .filter((v) => v.status != "fulfilled")
          .map((v: any) => v.reason || v.value || "unknown error");
        const succeed = compact(results);
        resolve({ results, succeed, failed });
      })
      .catch((err) => {
        resolve({ results: [], succeed: [], failed: [err] });
      });
  });
};

export const unique = (arr: any[]): any[] => {
  arr = compact(arr);
  if (arr.length > 0 && isObject(arr[0])) return uniqWith(arr, isEqual);
  return uniq(arr);
};

export const duplicates = (arr: any[]) => {
  const sorted = arr.slice().sort();
  let result: any = [];
  for (let i = 0; i < sorted.length - 1; i++) {
    if (sorted[i + 1] == sorted[i]) {
      result.push(sorted[i]);
    }
  }
  return unique(result);
};

export const wait = (second: number): Promise<boolean> => {
  return new Promise((resolve) => {
    // console.log("waiting...");
    setTimeout((_) => {
      // log.debug("waited", second, "s");
      resolve(true);
    }, second * 1000);
  });
};

export const pullAtIndex = (arr: any[], index: number) => {
  if (index == -1) return arr;
  pullAt(arr, index);
  return arr;
};

export const toJSON = (obj: any): object => {
  try {
    return JSON.parse(JSON.stringify(obj));
  } catch (e) {
    console.error(e);
    return {};
  }
};

export const stringToDate = (str: string): Date => {
  const timezone = parseInt(str.slice(-5, -2));
  const arr = str.split(/[^0-9]/).map((n) => parseInt(n) || 0);
  const d =
    Date.UTC(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]).valueOf() -
    timezone * 3600000;
  return new Date(d);
};

export const formatDate = (
  datetime: Date | number,
  timezone = Timezone["Asia/Hong_Kong"]
): string => {
  try {
    const today = moment().tz(timezone);
    const date = moment.tz(datetime, timezone);
    // console.log(today, date);
    return today.year() == date.year() && today.dayOfYear() == date.dayOfYear()
      ? date.format("HH:mm")
      : today.year() == date.year()
      ? date.format("MM/DD HH:mm")
      : date.format("YYYY/MM/DD HH:mm");
  } catch (error) {
    return "";
  }
};

export const formatFullDate = (
  datetime: Date | number,
  timezone = Timezone["Asia/Hong_Kong"]
): string => {
  try {
    const date = moment.tz(datetime, timezone);
    return date.format("YYYY/MM/DD");
  } catch (error) {
    return "";
  }
};

export const formatTime = (
  datetime: Date | number,
  timezone = Timezone["Asia/Hong_Kong"]
): string => {
  if (!datetime) return "";
  try {
    const date = moment.tz(datetime, timezone);
    return date.format("HH:mm");
  } catch (error) {
    return "";
  }
};

export const formatSize = (size: number) => {
  if (!size) return "";
  for (const unit of ["B", "KB", "MB", "GB", "TB"]) {
    if (size < 1024) return `${size.toFixed(2)} ${unit}`;
    size = size / 1024;
  }
  return String(size);
};

export const filenameFromURL = (url: string) => {
  if (!url) return "";
  url = url.replace(/\?.*/, "");
  return url.split("/").slice(-1);
};

export const duplicateArray = (arr: any[]) => {
  let duplicate = null;
  for (let i = 0; i < arr.length - 1; i++) {
    for (let n = i + 1; n < arr.length; n++) {
      if (isEqual(arr[i], arr[n])) {
        duplicate = arr[i];
        break;
      }
    }
  }
  return duplicate;
};

export const keys = (obj: any) => {
  if (!obj) return [];
  try {
    return Object.keys(obj).sort();
  } catch (e) {
    return [];
  }
};

export const hashShort = (text: string): string => {
  return shorthash.unique(text);
};

export const equal = (a: any, b: any): boolean => {
  try {
    if (!a || !b) return false;
    return JSON.stringify(a) == JSON.stringify(b);
  } catch (e) {
    return false;
  }
};

export const splitFilename = (filename: string) => {
  if (!filename) return null;
  let _filename = filename.split(".");
  if (_filename.length < 2) return null;
  const [ext] = _filename.splice(-1);
  const name = _filename.join(".");
  if (!name || !ext) return null;
  return { name, ext };
};

export const cleanFilename = (filename: string): string => {
  const reservedRegex = /[;,\/\?:@&=\+\$#]/g;
  return filename.replace(reservedRegex, "").replace(/\s/g, "_").trim();
};

export const roundTo = (num: number, dp = 2): number => {
  const p = Math.pow(10, dp);
  return Math.round((num + Number.EPSILON) * p) / p;
};

export const regexMatchOnly = (text: string, regex: RegExp): string => {
  const match = text.match(regex) || [];
  if (match.length != 1) return "";
  return match[0];
};

export const exactMatchRegex = (text: string, regex: RegExp): boolean => {
  const match = text.match(regex);
  if (!match) return false;
  if (match[0] != text) return false;
  return true;
};
