import { Language } from "../../misc";

export enum ChatflowControl {
  Undo = "undo",
  Exit = "exit",
  Restart = "restart",
  // Skip = "skip",
}

export const ChatflowControlValues = {
  [ChatflowControl.Undo]: ["undo", "back", "回上題", "上題", "上一題", "返回"],
  [ChatflowControl.Exit]: [
    "exit",
    "stop",
    "end",
    "cancel",
    "停止",
    "離開",
    "取消",
  ],
  [ChatflowControl.Restart]: ["restart", "重新開始", "重新", "重來"],
  // [ChatflowControl.Skip]: ["skip", "next", "略過", "下題", "下一題"],
};

export const CheckControl = (text: string): ChatflowControl | null => {
  if (!text) return null;
  for (let [control, values] of Object.entries(ChatflowControlValues)) {
    if (values.includes(text)) return control as ChatflowControl;
    for (let v of values) {
      if (text.includes(v)) return control as ChatflowControl;
    }
  }
  return null;
};

export const TRUE_VALUE = "true";
export const FALSE_VALUE = "false";

export const TRUTHY = [
  "true",
  "yes",
  "confirm",
  "ok",
  "okay",
  "agree",
  "correct",
  "是",
  "好",
  "確認",
  "確定",
  "同意",
  "對",
  "正確",
];

export const FALSY = [
  ...[
    "false",
    "no",
    "not",
    "wrong",
    "disagree",
    "abort",
    "不是",
    "否",
    "不",
    "不對",
    "不正確",
  ],
  ...ChatflowControlValues[ChatflowControl.Exit],
];

export const ChatflowControlText = {
  [Language.en]: {
    [ChatflowControl.Undo]: "Undo",
    // [ChatflowControl.Skip]: "Skip",
    [ChatflowControl.Exit]: "Exit",
    [ChatflowControl.Restart]: "Restart",

    controls:
      "ℹ️ Enter: \n'Undo' ➡️ Go back to previous question; \n'Skip' ➡️ Skip current question;\n'Restart' ➡️ Back to the first question; \n'Leave' ➡️ Skip all questions;",
    verify: "Did you enter it wrong?",
    required: "This question is required.",
    confirmation: "Confirmation is required.",
    CONFIRM: "Confirm",
    YES: "Yes",
    NO: "No",
    option_instruction: "enter answer no.",
    checkbox_instruction: "enter answer no., seperate by ','",
    number_instruction: "numbers only",
  },
  [Language.zh]: {
    [ChatflowControl.Undo]: "回上題",
    // [ChatflowControl.Skip]: "略過",
    [ChatflowControl.Exit]: "離開",
    [ChatflowControl.Restart]: "重新開始",

    controls:
      "ℹ️ 輸入: \n'回上題' ➡️ 回到上題; \n'略過' ➡️ 略過此題; \n'重新開始' ➡️ 回到第一題; \n'離開' ➡️ 略過所有;",
    verify: "是不是輸入錯了呢?",
    required: "需要回答此問題才能繼續。",
    confirmation: "需要確認才能繼續。",
    CANCEL: "離開",
    CONFIRM: "確定",
    YES: "是",
    NO: "否",
    option_instruction: "輸入答案編號即可",
    checkbox_instruction: "輸入答案編號, 以','分隔",
    number_instruction: "請輸入數字",
  },
};
