import { Component, NgZone, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../../../../services/auth.service";

@Component({
  selector: "app-signup",
  templateUrl: "./signup.component.html",
  styleUrls: ["./signup.component.scss"],
})
export class SignupComponent implements OnInit {
  email: string;
  password: string;
  confirmPassword: string;
  loading = false;
  redirect: string;

  constructor(
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private ngZone: NgZone
  ) {}

  ngOnInit() {
    this.redirect = this.activatedRoute.snapshot.queryParams.redirect || "/";
    this.email = this.activatedRoute.snapshot.queryParams.email || "";
  }

  reset() {
    this.email = "";
    this.password = "";
    this.confirmPassword = "";
  }

  async signUp() {
    this.loading = true;
    const success = await this.authService.signUp(
      this.email,
      this.password,
      this.confirmPassword
    );
    if (!success) return (this.loading = false);
    this.ngZone.run(() => this.router.navigate([this.redirect]));
  }
}
