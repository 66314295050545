import { Component, OnInit, NgZone } from "@angular/core";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";

enum WhatsappApiProvider {
  friday = "friday",
  twilio = "twilio",
}

@Component({
  selector: "app-whatsapp-api-selector",
  templateUrl: "./whatsapp-api-selector.component.html",
  styleUrls: ["./whatsapp-api-selector.component.scss"],
})
export class WhatsappApiSelectorComponent {
  WhatsappApiProvider: typeof WhatsappApiProvider = WhatsappApiProvider;
  selectedMode: WhatsappApiProvider = WhatsappApiProvider.friday;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<WhatsappApiSelectorComponent>,
    private router: Router,
    private ngZone: NgZone
  ) {}

  close() {
    this.dialogRef.close();
  }

  submit() {
    console.log(this.selectedMode);
    this.close();
    switch (this.selectedMode) {
      case WhatsappApiProvider.friday:
        this.ngZone.run(() =>
          this.router.navigate(["/setting/channel/whatsapp"])
        );
        break;
      case WhatsappApiProvider.twilio:
        this.ngZone.run(() =>
          this.router.navigate(["/setting/channel/twilio"])
        );
        break;
    }
  }
}
