<div class="page__container" *ngIf="client" [@smooth]>
  <div class="page__content__title">
    <h1>WhatsApp API Providers</h1>
  </div>

  <div class="page__nav">
    <!-- <a
      [routerLink]="['whatsapp']"
      [class.selected]="isActiveRoute('/channel/whatsapp/whatsapp')"
    >
      <img src="assets/img/whatsapp-logo.svg" />
      <span>WhatsApp</span>
    </a> -->

    <a
      [routerLink]="['twilio']"
      [class.selected]="isActiveRoute('/channel/whatsapp/twilio')"
    >
      <img src="assets/img/twilio-logo.svg" />
      <span>Twilio</span>
    </a>

    <a
      [routerLink]="['friday']"
      [class.selected]="isActiveRoute('/channel/whatsapp/friday')"
    >
      <img src="assets/img/friday-logo.png" />
      <span>Friday</span>
    </a>
  </div>

  <router-outlet></router-outlet>
</div>
