import { Injectable } from "@angular/core";
import {
  getToken,
  isSupported,
  Messaging,
  onMessage,
} from "@angular/fire/messaging";
import { TranslateService } from "@ngx-translate/core";
import { to } from "@utility";
import DeviceDetector from "device-detector-js";
import { NotificationService } from "./notification.service";

@Injectable({
  providedIn: "root",
})
export class FcmService {
  token: string;
  active = true;
  intervals: any[] = [];
  title: string;
  hidden: string;
  visibilityChange: string;
  mobile: boolean;
  fcmKey: string;

  constructor(
    private fcm: Messaging,
    private translateService: TranslateService,
    private notificationService: NotificationService
  ) {}

  async initialize(fcmKey: string, mobile: boolean = false) {
    try {
      this.mobile = !!mobile;
      this.fcmKey = fcmKey;

      if (this.mobile) return true;
      if (!isSupported()) return true;
      if (Notification.permission != "granted") {
        await Notification.requestPermission();
      }
      this.initializePageTitleNotification();
    } catch (error) {
      console.log("Error initializing FCM", error);
    }
    return true;
  }

  initializePageTitleNotification() {
    try {
      this.title = document.title;
      if (typeof document.hidden != "undefined") {
        this.hidden = "hidden";
        this.visibilityChange = "visibilitychange";
      } else if (typeof (document as any).msHidden != "undefined") {
        this.hidden = "msHidden";
        this.visibilityChange = "msvisibilitychange";
      } else if (typeof (document as any).webkitHidden != "undefined") {
        this.hidden = "webkitHidden";
        this.visibilityChange = "webkitvisibilitychange";
      }

      if (
        typeof document.addEventListener == "undefined" ||
        this.hidden == undefined
      ) {
        console.log("This browser doesn't support the Page Visibility API.");
      } else {
        document.addEventListener(
          this.visibilityChange,
          () => {
            if (document[this.hidden]) {
              this.active = false;
            } else {
              this.active = true;
              this.intervals.forEach((id) => clearInterval(id));
              this.intervals = [];
              document.title = this.title;
            }
          },
          false
        );
      }
    } catch (error) {
      console.log(error);
      console.log("This browser doesn't support the title notification");
    }
  }

  async getNotificationToken(): Promise<{ deviceName: string; token: string }> {
    try {
      if (this.mobile) {
        const [permissionErr, granted] = await to(
          this.mobileRequestPermission()
        );
        if (permissionErr) throw permissionErr;
        if (granted) {
          if (!this.token) {
            const [tokenErr, deviceToken] = await to(this.mobileGetToken());
            if (tokenErr) throw tokenErr;
            if (!deviceToken) return null;
            this.token = deviceToken;
          }
        }
      } else {
        if (!isSupported()) return null;
        if (Notification.permission != "granted") return null;
        if (!this.token) {
          const [tokenErr, deviceToken] = await to(
            getToken(this.fcm, { vapidKey: this.fcmKey })
          );
          if (tokenErr) throw tokenErr;
          if (!deviceToken) return null;
          this.token = deviceToken;
        }

        onMessage(this.fcm, (payload) => {
          // console.log("Message received. ", payload);
          if (!this.active && payload.notification)
            this.alertPageTitle(payload.notification.title);
          this.notificationService.notify(
            payload.notification.title,
            payload.notification.body
          );
        });
      }
      // console.log("Notification token", this.token);
      const deviceDetector = new DeviceDetector();
      const device = deviceDetector.parse(navigator.userAgent);
      let deviceName = `${device.device.brand.trim() || ""} ${
        device.device.model.trim() || ""
      } ${device.os.name.trim() || ""} ${device.os.version.trim() || ""}`;
      deviceName = deviceName.replace(/\s+/g, " ");
      return { deviceName: deviceName || "", token: this.token || null };
    } catch (e) {
      console.dir(e, { depth: null });
    }
    return null;
  }

  alertPageTitle(m: string) {
    if (this.intervals.length > 0) return false;
    const message = this.translateService.instant(m);
    document.title = message;
    const id = setInterval(() => {
      document.title = document.title == this.title ? message : this.title;
    }, 1500);
    this.intervals.push(id);
  }

  async mobileGetToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      (window as any).FirebasePlugin.getToken(
        (deviceToken) => resolve(deviceToken),
        (err) => reject(err)
      );
    });
  }

  async mobileRequestPermission() {
    return new Promise((resolve, reject) => {
      (window as any).FirebasePlugin.hasPermission((hasPermission) => {
        hasPermission
          ? resolve(true)
          : (window as any).FirebasePlugin.grantPermission((granted) =>
              resolve(granted)
            );
      });
    });
  }
}
