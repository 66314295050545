<div
  class="sidebar"
  [class.expand]="expanded"
  (mouseenter)="expand()"
  (mouseleave)="collapse()"
>
  <div class="sidebar__logo">
    <img src="assets/img/logo.png" alt="friday logo" />
  </div>

  <div class="sidebar__menu column">
    <a
      *ngFor="let route of routes"
      class="sidebar__menu__item"
      [class.active]="isActiveRoute(route.url)"
      [routerLink]="[route.url]"
    >
      <div class="icon">
        <i class="material-icons">{{ route.icon }}</i>
      </div>
      <span>{{ "nav." + route.name | translate }}</span>
    </a>
  </div>

  <div class="sidebar__quick-actions">
    <button class="icon-button hide-in-compact" (click)="toggleDarkmode()">
      <i class="material-icons">brightness_4</i>
    </button>

    <button
      class="icon-button hide-in-compact"
      mat-button
      [matMenuTriggerFor]="languageMenu"
    >
      <i class="material-icons">language</i>
    </button>

    <button class="icon-button" (click)="signOut()">
      <i class="material-icons">exit_to_app</i>
    </button>
  </div>

  <div class="sidebar__user">
    <a class="sidebar__user__info" [routerLink]="['/setting/account']">
      <div class="user-icon gradient-theme" *ngIf="!user.info.photoURL">
        {{
          user.info.displayName ? user.info.displayName[0] : user.info.email[0]
        }}
      </div>

      <div
        class="user-icon"
        *ngIf="user.info.photoURL"
        [style.background-image]="userPhoto()"
      ></div>

      <div class="user-name hide-in-compact">
        <span class="name" *ngIf="user.info.displayName">
          {{ user.info.displayName }}
        </span>
        <span class="email">{{ user.info.email }}</span>
      </div>
    </a>
  </div>
</div>

<mat-menu #languageMenu="matMenu" xPosition="before" yPosition="above">
  <button
    class="center language"
    *ngFor="let language of languages"
    mat-menu-item
    (click)="setLanguage(language)"
  >
    {{ "general." + language | translate }}
  </button>
</mat-menu>
