import { Injectable, NgZone } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { NotificationComponent } from "../components/notification/notification.component";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  constructor(
    private snackBar: MatSnackBar,
    private ngZone: NgZone,
    private translateService: TranslateService
  ) {}

  notify(title: string, content: string, icon?: string, returnValue?: any) {
    this.ngZone.run((_) => {
      this.snackBar.openFromComponent(NotificationComponent, {
        data: {
          icon,
          title,
          content,
        },
        horizontalPosition: "right",
        verticalPosition: "bottom",
      });
    });
    if (returnValue != undefined) return returnValue;
  }

  feedback(m: string, state: string, returnValue?: any) {
    const message = this.translateService.instant(m) || m;
    let icon, title;
    switch (state) {
      case "error":
        icon = "error";
        title = this.translateService.instant("general.error");
        break;
      case "warn":
        icon = "warning";
        title = this.translateService.instant("general.warning");
        break;
      default:
        icon = "check_circle";
        title = this.translateService.instant("general.success");
        break;
    }

    this.ngZone.run((_) => {
      this.snackBar.openFromComponent(NotificationComponent, {
        data: {
          state,
          icon,
          title,
          content: message,
        },
        horizontalPosition: "center",
        verticalPosition: "bottom",
      });
    });
    if (returnValue != undefined) return returnValue;
  }

  error(m: string, returnValue?: any) {
    return this.feedback(m, "error", returnValue);
  }

  success(m: string, returnValue?: any) {
    return this.feedback(m, "success", returnValue);
  }

  warn(m: string, returnValue?: any) {
    return this.feedback(m, "warn", returnValue);
  }
}
