import { compact, escapeRegExp, isEmpty } from "lodash";
import YAML from "yaml";
import { unique } from "../../../../helper";
import { REGEX_PARAMETER } from "../../../../misc";
import { ChatflowStateValues } from "../../state";

export const API_RESULT = "API_RESULT";
export const CALCULATION_RESULT = "CALCULATION_RESULT";

const ROUND_TO = 5;

export const getVariableNames = (text: string): string[] => {
  if (!text) return [];
  const match = text.match(REGEX_PARAMETER);
  if (!match) return [];
  return unique(match.map((m) => m.replace(/[\{\}]/g, "").trim()));
};

export const toYamlString = (obj: any) => {
  if (isEmpty(obj)) return "";
  let yamlStr = JSON.stringify(obj);
  try {
    const yamlDoc = new YAML.Document();
    yamlDoc.contents = obj;
    yamlStr = yamlDoc.toString();
  } catch (error) {}
  return "\n" + yamlStr;
};

export const getObjectValueFromStringRepresentation = (
  obj: any,
  representation: string,
  defaultValue = ""
): string | number | boolean => {
  if (typeof obj != "object") return obj;
  defaultValue = defaultValue || toYamlString(obj);
  if (isEmpty(obj) || !representation) return defaultValue;
  // console.log(obj);
  try {
    const levels = compact(representation.split(".")).map((l) => l.trim());
    // console.log(levels);
    const len = levels.length;
    if (len == 1) return defaultValue;

    let value: any = obj;
    for (let i = 0; i < len; i++) {
      let _field = levels[i];
      // console.log(_field);
      const arrayMatch = _field.match(/\[\d+\]/g) || [];
      if (isEmpty(arrayMatch)) {
        // console.log(_field, value);
        if (!(_field in value)) return defaultValue;
        value = value[_field];
        // console.log(_field, value);
      } else {
        // TODO support nested array
        if (arrayMatch.length > 1) return defaultValue;
        _field = _field.replace(arrayMatch[0], "");
        const arrayIdx = arrayMatch[0].match(/\d+/g) || [];
        if (arrayIdx.length != 1) return defaultValue;
        const idx = parseInt(arrayIdx[0]) || 0;
        // console.log("array", _field, value[_field], idx);
        value = value[_field][idx];
      }
    }
    // console.log(value);
    return value;
  } catch (error) {}
  return defaultValue;
};

export const substituteVariables = (
  text: string,
  values: ChatflowStateValues,
  roundTo: number = ROUND_TO
) => {
  try {
    if (isEmpty(values)) return text;
    const variables = getVariableNames(text);
    if (!variables) return text;
    // console.log(variables);
    variables.forEach((variable) => {
      let sub = "";
      const _value = variable.match(/[\.\[\]]/g)
        ? getObjectValueFromStringRepresentation(values, variable)
        : values[variable] || "";
      // console.log(_value);
      if (_value)
        sub = typeof _value == "object" ? toYamlString(_value) : String(_value);
      const regex = new RegExp(`\{\{ *${escapeRegExp(variable)} *\}\}`, "g");
      if (sub.includes(".")) {
        const n = parseFloat(sub);
        if (!isNaN(n) && roundTo >= 0) sub = n.toFixed(roundTo);
      }
      text = text.replace(regex, sub);
    });

    text = text
      .replace(/[\{\}]/g, "")
      .replace(/[\t ]+/, " ")
      .trim();

    return text;
  } catch (error) {}
  return text;
};
