import { unique } from "../../helper";
import { Language, Timezone } from "../../misc";
import { ClientPermission } from "../permission";
import {
  User,
  UserBase,
  UserBaseParams,
  UserInfo,
  UserInfoParams,
  UserParams,
} from "../user";
import {
  ChannelSettingFacebook,
  ChannelSettingFacebookParams,
  ChannelSettingFridayWhatsapp,
  ChannelSettingFridayWhatsappParams,
  ChannelSettingTwilio,
  ChannelSettingTwilioParams,
} from "./channel";
import { ServiceCredential } from "./credential";
import { ClientQuota, ClientQuotaParams } from "./quota";
import { AutopilotSetting, ConversationSetting } from "./setting";

interface ClientInfoParams extends UserInfoParams {
  organization?: string;
}

export class ClientInfo extends UserInfo {
  organization?: string;

  constructor(params: ClientInfoParams) {
    super(params);
    if (params.organization) this.organization = params.organization;
  }
}

export interface ClientParams extends UserBaseParams {
  organization?: string;
  primaryLanguage?: Language;
  defaultTimezone?: Timezone;
  settings?: {
    conversation?: ConversationSetting;
    autopilot?: AutopilotSetting;
    // knowledge?: KnowledgeSetting;
    // taskforce?: TaskforceSetting;
    // chatform?: ChatformSetting;
  };
  channels?: {
    facebook?: ChannelSettingFacebookParams;
    fridayWhatsapp?: ChannelSettingFridayWhatsappParams;
    twilio?: ChannelSettingTwilioParams;
  };
  quotas?: ClientQuotaParams;
  users?: UserParams[];
  credentials?: ServiceCredential[];
  suspended?: boolean;
}

export class Client extends UserBase {
  organization: string;
  primaryLanguage?: Language;
  defaultTimezone?: Timezone;
  settings: {
    conversation: ConversationSetting;
    autopilot: AutopilotSetting;
    // knowledge: KnowledgeSetting;
    // taskforce: TaskforceSetting;
    // chatform: ChatformSetting;
  };
  channels: {
    facebook: ChannelSettingFacebook;
    fridayWhatsapp: ChannelSettingFridayWhatsapp;
    twilio: ChannelSettingTwilio;
  };
  quotas: ClientQuota;
  users: User[];
  credentials: ServiceCredential[];
  suspended: boolean;

  constructor(params: ClientParams) {
    super(params);
    this.organization = params.organization || "";
    this.primaryLanguage = params.primaryLanguage || Language.en;
    this.defaultTimezone = params.defaultTimezone || Timezone["Asia/Hong_Kong"];
    this.suspended = !!params.suspended;

    this.channels = {
      facebook: new ChannelSettingFacebook(params.channels?.facebook),
      fridayWhatsapp: new ChannelSettingFridayWhatsapp(
        params.channels?.fridayWhatsapp
      ),
      twilio: new ChannelSettingTwilio(params.channels?.twilio),
    };

    this.settings = {
      conversation: new ConversationSetting(params.settings?.conversation),
      autopilot: new AutopilotSetting(params.settings?.autopilot),
    };

    this.quotas = new ClientQuota(params.quotas);

    this.users = params.users?.map((u) => new User(u)) || [];

    if (!this.users.find((u) => u.id == this.id))
      this.users = [
        ...[
          new User({
            id: this.id,
            permission: ClientPermission.Admin,
            createdAt: params.createdAt,
            updatedAt: params.updatedAt,
          }),
        ],
        ...this.users,
      ];

    this.credentials = params.credentials || [];
  }

  get rootUser(): User {
    return this.users.find((u) => u.id == this.id) || new User({ id: this.id });
  }

  get userEmails(): string[] {
    return unique(this.users.map((u) => u.info.email || ""));
  }

  get userNotificationTokens(): string[] {
    return unique(this.users.map((u) => u.notificationTokens || []).flat());
  }
}
