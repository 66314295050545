<div class="page__container" *ngIf="client" [@smooth]>
  <!-- <div class="page__content__title">
    <h1 translate>twilio.title</h1>
  </div> -->

  <div class="channel-header">
    <div class="row">
      <div class="channel-description">
        <ul>
          <li translate>twilio.instruction.1</li>
          <li translate>twilio.instruction.2</li>
          <li translate>
            twilio.instruction.3
            <a href="https://www.twilio.com/whatsapp/pricing" target="_blank"
              >https://www.twilio.com/whatsapp/pricing</a
            >
          </li>
          <li translate>
            twilio.instruction.4
            <a href="https://www.twilio.com/whatsapp" target="_blank"
              >https://www.twilio.com/whatsapp</a
            >
          </li>
        </ul>
      </div>

      <div class="channel-image">
        <img src="assets/img/twilio-logo.svg" />
      </div>
    </div>
  </div>

  <div class="channel-details">
    <div class="form">
      <div class="form__section" translate>general.webhook</div>
      <div class="form__section__description" translate>
        twilio.webhook-instruction
      </div>
      <div class="form__row">
        <div class="form__label wrap">
          <i class="material-icons">call_received</i>
          <span>{{
            ("twilio.received" | translate) + ("general.webhook" | translate)
          }}</span>
        </div>
        <div class="form__field">
          <input
            id="friday-twilio-webhook-messages"
            type="text"
            [value]="webhook + '/messages'"
            readonly
          />
        </div>

        <div class="form__action">
          <button
            class="icon-button primary"
            (click)="copyCode('friday-twilio-webhook-messages')"
          >
            <i class="material-icons">file_copy</i>
          </button>
        </div>
      </div>

      <div class="form__row">
        <div class="form__label wrap">
          <i class="material-icons">call_made</i>
          <span>{{
            ("twilio.sent" | translate) + ("general.webhook" | translate)
          }}</span>
        </div>
        <div class="form__field">
          <input
            id="friday-twilio-webhook-sent"
            type="text"
            [value]="webhook + '/sent'"
            readonly
          />
        </div>
        <div class="form__action">
          <button
            class="icon-button primary"
            (click)="copyCode('friday-twilio-webhook-sent')"
          >
            <i class="material-icons">file_copy</i>
          </button>
        </div>
      </div>

      <div class="form__section" translate>twilio.credentials</div>
      <div class="form__section__description" translate>
        twilio.credential-instruction
      </div>
      <div class="form__row">
        <div class="form__label wrap">
          <i class="material-icons">account_box</i>
          <span translate>twilio.account</span>
        </div>
        <div class="form__field">
          <input
            type="text"
            name="accountSid"
            autocomplete="off"
            [(ngModel)]="accountSid"
            [readonly]="!editCredential"
          />
        </div>
        <div class="form__action">
          <button
            class="icon-button"
            (click)="editCredential = true"
            *ngIf="!editCredential && isManager"
          >
            <i class="material-icons">edit</i>
          </button>
        </div>
      </div>

      <div class="form__row">
        <div class="form__label wrap">
          <i class="material-icons">lock</i>
          <span translate>twilio.token</span>
        </div>
        <div class="form__field">
          <input
            type="password"
            name="authToken"
            autocomplete="off"
            [(ngModel)]="authToken"
            [readonly]="!editCredential"
          />
        </div>

        <div class="form__action">
          <button
            class="icon-button"
            (click)="editCredential = true"
            *ngIf="!editCredential && isManager"
          >
            <i class="material-icons">edit</i>
          </button>
          <button
            class="icon-button warn"
            (click)="
              accountSid = credentialCopy.accountSid;
              authToken = credentialCopy.authToken;
              editCredential = false
            "
            *ngIf="(accountSid || authToken) && editCredential"
          >
            <i class="material-icons">cancel</i>
          </button>
          <button
            class="icon-button success"
            (click)="saveCredential(); editCredential = false"
            *ngIf="
              editCredential &&
              ((authToken && authToken != credentialCopy.authToken) ||
                (accountSid && accountSid != credentialCopy.accountSid))
            "
          >
            <i class="material-icons">check_circle</i>
          </button>
        </div>
      </div>

      <div class="form__section" translate>whatsapp.connected-numbers</div>
      <div class="form__row" *ngIf="isManager">
        <div class="form__label wrap">
          <i class="material-icons">dialpad</i>
          <span translate>twilio.add-number</span>
        </div>
        <div class="form__field">
          <div>whatsapp:+</div>
          <input
            type="text"
            [(ngModel)]="newNumber"
            (keydown.enter)="addNumber()"
            [disabled]="!isManager"
          />
        </div>

        <div class="form__action">
          <button
            class="text-button bg-success"
            (click)="addNumber()"
            [disabled]="newNumber == ''"
            *ngIf="isManager"
          >
            <i class="material-icons">add</i>
            <span translate>whatsapp.number</span>
          </button>
        </div>
      </div>

      <div class="samples">
        <div class="samples__item bg-success" *ngFor="let number of numbers">
          {{ number }}
          <a class="error" (click)="removeNumber(number)" *ngIf="isManager">
            <i class="material-icons">close</i>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
