import { evaluate as evalMath } from "mathjs";
import { REGEX_PARAMETER } from "../../../../misc";
import { ChatflowStateValues } from "../../state";
import { getVariableNames, substituteVariables } from "./variable";

export interface ChatflowCalculationResponseParams {
  formula?: string;
}

export class ChatflowCalculationResponse {
  formula: string;

  constructor(params: ChatflowCalculationResponseParams = {}) {
    this.formula = params.formula || "";
  }

  valid(definedVariables: string[] = []): boolean {
    if (!this.formula) return false;
    let _formula = this.formula.trim();
    const variables = getVariableNames(_formula);
    if (variables) {
      if (!variables.every((p) => definedVariables.includes(p.split(".")[0])))
        return false;
      _formula = _formula.replace(REGEX_PARAMETER, "1");
    }
    try {
      const result = evalMath(_formula);
      if (isNaN(result)) return false;
    } catch (e) {
      return false;
    }
    return true;
  }

  result(values: ChatflowStateValues): number | null {
    if (!this.formula) return null;
    const formula = substituteVariables(this.formula, values, -1);
    try {
      const result = evalMath(formula);
      if (!isNaN(result)) return result;
    } catch (e) {}
    return null;
  }
}
